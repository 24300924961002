
@font-face {
    font-family: 'billy_ohioregular';
    src: url('../fonts/billy_ohio.woff2') format('woff2'),
    url('../fonts/billy_ohio.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'billy_ohioregular';
    src: url('../fonts/billy_ohio.woff2') format('woff2'),
    url('../fonts/billy_ohio.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsextrabold';
    src: url('../fonts/poppins-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/poppins-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsbold';
    src: url('../fonts/poppins-bold-webfont.woff2') format('woff2'),
         url('../fonts/poppins-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinssemibold';
    src: url('../fonts/poppins-semibold-webfont.woff2') format('woff2'),
         url('../fonts/poppins-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsmedium';
    src: url('../fonts/poppins-medium-webfont.woff2') format('woff2'),
         url('../fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsregular';
    src: url('../fonts/poppins-regular-webfont.woff2') format('woff2'),
         url('../fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

body {
    color: #000;
    word-wrap: break-word;
    font-size: 16px;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden!important;
    overflow-y: auto!important;
    font-family: 'poppinsregular'; 
    background: #fff;
}

body::-webkit-scrollbar { width: 10px;}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: #000000;
    outline: 0px solid slategrey;
}

html { -webkit-text-size-adjust: none;}
:focus { outline: 0;}
ol,ul { margin: 0; padding: 0; }
ul{list-style: none; }
table { border-collapse: separate; border-spacing: 0;}

embed,iframe,object { max-width: 100%;}
cite,em,i { font-style: italic;}
input,textarea { border-radius: 0; -webkit-border-radius: 0px; }

input[type="text"],input[type="email"],input[type="search"],input[type="password"],textarea {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],input[type="email"],input[type="password"],input[type="tel"],textarea,select {
    padding: 12px 25px;
    border: 1px solid #d0cfcb;
    width: 100%;
    margin: 0 0 10px;
    background-color: #fff;
    border-radius: 6px;
    height: 48px;
    font-size: 16px;
    color: #555555;
    font-family: 'poppinsregular';
}

input[type="reset"],input[type="button"],input[type="submit"],.button,button {
    color: #fff;
    display: inline-block;
    border-radius: 0px;
    margin: 15px 0px 10px 0px;
    padding: 14px 28px 14px;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    background: #e2b014;
    border: 0px;
    font-size: 16px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 2; 
    border-radius: 5px;
}

input[type="reset"]:hover,input[type="button"]:hover,input[type="submit"]:hover, button:hover {
    color: #fff;
    /* background: #000; */
}

input[type="reset"]:active,input[type="button"]:active,input[type="submit"]:active {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

/**********************   Custom Radio Check Box ********************/

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type=checkbox], input[type=radio] {
        --active: #e2b014;
        --active-inner: #fff;
        --focus: 2px rgba(0, 0, 0, .3);
        --border: #757575;
        --border-hover: #000;
        --background: #fff;
        --disabled: #F6F8FF;
        --disabled-inner: #999;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 18px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }
    input[type=checkbox]:after, input[type=radio]:after {
        content: "";
        display: block;
        left: -1px;
        top: -1px;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    }
    input[type=checkbox]:checked, input[type=radio]:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    input[type=checkbox]:disabled, input[type=radio]:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
    }
    input[type=checkbox]:disabled:checked, input[type=radio]:disabled:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
    }
    input[type=checkbox]:disabled + label, input[type=radio]:disabled + label {
        cursor: not-allowed;
    }
    input[type=checkbox]:hover:not(:checked):not(:disabled),input[type=radio]:hover:not(:checked):not(:disabled) {
        --bc: var(--border-hover);
    }
    input[type=checkbox]:focus, input[type=radio]:focus {
        box-shadow: 0 0 0 var(--focus);
    }
    input[type=checkbox]:not(.switch), input[type=radio]:not(.switch) {
        width: 18px;
    }
    input[type=checkbox]:not(.switch):after, input[type=radio]:not(.switch):after {
        opacity: var(--o, 0);
    }
    input[type=checkbox]:not(.switch):checked, input[type=radio]:not(.switch):checked {
        --o: 1;
    }
    input[type=checkbox] + label, input[type=radio] + label {
        line-height: 21px;
        display: inline-block;
        cursor: pointer;
        margin-left: 5px !important;
    }
  
    input[type=checkbox]:not(.switch) {
        border-radius: 3px;
    }
    input[type=checkbox]:not(.switch):after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 6px;
        top: 3px;
        transform: rotate(var(--r, 20deg));
    }
    input[type=checkbox]:not(.switch):checked {
        --r: 43deg;
    }
    input[type=checkbox].switch {
        width: 38px;
        border-radius: 11px;
    }
    input[type=checkbox].switch:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
    }
    input[type=checkbox].switch:checked {
        --ab: var(--active-inner);
        --x: 17px;
    }
    input[type=checkbox].switch:disabled:not(:checked):after {
        opacity: 0.6;
    }
  
    input[type=radio] {
        border-radius: 50%;
    }
    input[type=radio]:after {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: var(--active-inner);
        opacity: 0;
        transform: scale(var(--s, 0.7));
    }
    input[type=radio]:checked {
        --s: .5;
    }

    .components_selctbox_cls input[type=checkbox]:not(.switch) {
        border-radius: 50%;
    }

    .components_selctbox_cls input[type=checkbox]:not(.switch):after {
        width: 6px;
        height: 14px;
        left: 10px;
        top: 5px;
    }
  }

a {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
}

a:focus,a:active,a:hover { text-decoration: none;}
a:hover { color: #000}
figure { margin: 0;  text-align: center;}
img { max-width: 100%; height: auto; vertical-align: top;}

h1,h2,h3,h4,h5,h6 {
    margin: 0px 0px 25px 0px;
    padding: 0px;
    font-weight: normal;
    line-height: 1.1;
    color: #000;
    text-transform: capitalize;
    font-family: 'poppinsbold'; 
}

h1 { font-size: 50px;}
h2 { font-size: 40px;}
h3 { font-size: 28px;}
h4 { font-size: 24px;}
h5 { font-size: 18px;}
h6 { font-size: 16px; }

.show { display: block;}
.hidden { display: none;}
.invisible { visibility: hidden;}
.fl { float: left}
.fr { float: right}
.rel { position: relative}
.textcenter { text-align: center}
.textleft { text-align: left}
.textright { text-align: right}

*, *:before, *:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.flex { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.clear, .clearfix {  clear: both;}
.clearfix:after { clear: both; display: table; content: ""}
strong, b { font-weight: bold;}
.required_star {  color: #f00;}

.container-full {
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
}

.slick-slider, .slick-slider * { outline: none;}
p { margin: 0 0 15px; }
.title_sec { margin: 0 0 45px; color: #040507;}
.title_sec h2 { font-size: 44px; line-height: 1; margin: 0 0 5px;}
.title_sec small { font-size: 16px; display: block; font-weight: normal; margin: 0 0 13px;}
.title1 { font-size: 32px; color: #040507; line-height: 1; margin: 0 0 24px;}
.title3 { font-size: 20px; color: #040507; line-height: 1;}
.title4 { font-size: 18px; color: #010101;}

.icon-cap {
    background: url(../images/icon-cap.png) no-repeat;
    width: 34px;
    height: 31px;
    display: block;
}

.breadcrum-title {
    font-family: 'poppinsregular';
    font-size: 130px;
    font-weight: 400;
    color: #e2b014;
    margin: 0;
    padding-left: 50px;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn:active:focus, .btn:focus {
    outline: none;
    color: #fff;
}

.btn {
    display: block;
    padding: 10px 20px;
    color: #fff;
    background: #e2b014;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.5px;
    font-size: 16px;
    margin: 0;
    font-family: 'poppinsmedium';
    border-radius: 6px; 
    text-align: center;
}

.btn:hover {
    color: #fff;
    background: #000;
}

.btn_minwid {
    min-width: 170px;
    padding-left: 25px;
    padding-right: 25px;
}

.btn-sm {
    height: 42px;
    padding: 8px 15px;
    font-size: 16px;
}

table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table_overflow {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 20px;
}

.table_overflow .table {
    margin-bottom: 0;
}

.dropdown-menu>li>a {
    padding: 7px 20px;
    border-top: 1px solid #e9ebf5;
}

.dropdown-menu>li:first-child>a {
    border-top: 0;
}

.form_grouptt {
    font-size: 16px;
    margin: 0 0 20px 2px;
    color: #000;
    text-transform: capitalize;
    font-family: 'poppinssemibold';
}

.form-group {
    margin: 0 0 8px;
}

.form_sec .row {
    margin-left: -4px;
    margin-right: -4px;
}

.form_sec [class*="col-"] {
    padding: 0 4px;
}

label {
    font-weight: 500;
    font-size: 15px;
    color: #615f5f;
}

label span {
    color: #f00;
    margin-left: 3px;
}

.manitatory-select .Select-placeholder:after {
    content: '*';
    color: red;
}

.form-control {
    height: 48px;
    border: 1px solid #e2e2e2;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding: 6px 18px;
    background: #fff;
    font-size: 15px;
    border-radius: 6px;
    color: #615f5f;
    width: 100%;
    display: block
}

textarea.form-control {
    height: 160px;
}

textarea.form-control.sm {
    height: 90px;
}

.form-control.error,
.Select-control.error {
    border-color: #FFBABA;
    color: #686a78;
}

.form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #e2b014;
}

.focus-out {
    position: relative;
}

.focus-out label {
    position: absolute;
    left: 18px;
    top: 14px;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    height: 24px;
    overflow: hidden;
    color: #595656;
}

.focused label {
    padding: 0 6px;
    font-size: 11px;
    background: #fff;
    top: -7px;
    height: auto;
}
.clock_icon, .ileft_ico {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 100%;
}

.clock_icon img, .ileft_ico img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
}

.tab_sec.filter_tabsec.reward_sec { padding: 40px;}
.acc-inform .form-group .row:after,
.filter_tabsec .nav-tabs:after { display: table; clear: both; content: ""}

.re_select input[type="text"] {
    margin: 0;
    height: 34px;
}

.acc-inform .form-group .row .button {
    width: 100%;
    display: block;
    margin: 0;
    font-family: 'poppinsmedium';
}

.re_select .Select-control {
    border: 1px solid #e2e2e2;
    border-radius: 0;
    height: 47px;
    outline: 0;
}

.re_select .Select-placeholder, .re_select .Select--single > .Select-control .Select-value {
    padding: 0 22px;
    line-height: 45px;
    text-align: left;
    color: #72624f;
}

.re_select .Select-input {
    padding: 0 20px;
    height: 45px;
}

.re_select .Select-input > input {
    line-height: 27px;
    padding: 10px 0;
}

.re_select .Select.is-open > .Select-control {
    border-color: #d5d7e0;
}

.re_select .Select-arrow {
    background: url(../images/arrow-down.png) no-repeat center;
    border: 0;
    width: 18px;
    height: 10px;
}

.re_select .is-open .Select-arrow {
    background-image: url(../images/arrow-up.png);
}

.re_select .Select-menu-outer {
    z-index: 4;
    text-align: left;
}

.re_select .Select-menu-outer {
    background: #ecdfcc;
}

.re_select .Select-arrow-zone {
    padding: 0;
    width: 42px;
}

.re_select .Select--multi .Select-value {
    background-color: #ecdfcc;
    border-color: #dcd8d8;
    color: #181818;
    position: relative;
    padding-right: 19px;
    border-radius: 10px;
    overflow: hidden;
}

.re_select .Select--multi .Select-value-icon {
    border-color: #dcd8d8;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.re_select .Select--multi .Select-value-icon:hover,
.re_select .Select--multi .Select-value-icon:focus {
    background-color: #1c1c1c;
    border-color: #1c1c1c;
    color: #fff;
}

.re_select .Select--multi .Select-value-icon:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
    font-size: 0;
}

.re_select .Select--multi .Select-value-label {
    padding: 2px 5px 2px 9px;
}

.custom_select {
    position: relative;
}

.custom_select:after {
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 45px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    pointer-events: none;
    height: 46px
}

.react-datepicker {
    font-family: 'poppinsregular' !important;
}

.react_datepicker .react-datepicker-wrapper,
.react_datepicker .react-datepicker__input-container {
    display: block;
}

.react_datepicker .react-datepicker {
    font-size: 1rem;
}

.react_datepicker .react-datepicker__current-month,
.react_datepicker .react-datepicker-time__header {
    font-size: 1.2rem;
    font-family: 'poppinsregular';
    font-weight: normal
}

.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
    width: 2.7rem;
}

.react_datepicker .react-datepicker__time-container {
    width: 120px;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding: 0;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 120px;
}
/* .react-datepicker-popper .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {width: 95px;} */
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    background-color: #000000 !important
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
    background-color: #e2b014 !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range{
    background-color: #e2b014 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
    background-color: #e2b014 !important;
}

.react-datepicker__header {
    background-color: #e2b014 !important;
}

.react-datepicker__navigation {
    margin: 0
}

.react-datepicker__navigation--previous {
    border-right-color: #000 !important;
}

.react-datepicker__navigation--next {
    border-left-color: #000 !important;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
    background: none
}

.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
    height: 36px;
    padding: 5px 10px;
}

.custom_checkbox {
    position: relative;
    display: inline-block;
    line-height: 25px;
}

.error {
    color: #f00;
    font-weight: normal;
}

span.error {
    background: #FFBABA;
    color: #D8000C;
    padding: 5px 25px;
    font-size: 13px;
    position: relative;
    display: inline-block;
    font-weight: normal;
    z-index: 4;
}

span.error:before {
    content: "";
    border-bottom: 5px solid #ffbaba;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -5px;
    width: 0;
}

.alert {
    padding: 10px 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    text-align: left;
    display: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.alert_fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    max-width: 1260px;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.alert_success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert_danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.alert_fixed p {
    margin: 0;
}

.alert_fixed a {
    font-size: 24px;
    right: 20px;
    top: 5px;
    position: absolute
}

.display_inblock {
    display: inline-block;
}

.display_block {
    display: block;
}

.relative {
    position: relative;
}

.vtop {
    vertical-align: top;
}

.vmiddle {
    vertical-align: middle;
}

.vbottom {
    vertical-align: bottom;
}

.vmid_sec:before {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}

.vmid_in {
    display: inline-block;
    vertical-align: middle;
    width: 98%;
}

.main_tabsec > .nav-tabs {
    border: 0;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-radius: 50px;
    margin: 0 0 20px;
}

.main_tabsec > .nav-tabs>li {
    margin: 0;
    display: table-cell;
    float: none;
    text-align: center;
    width: 50%;
    vertical-align: middle;
    border-left: 2px solid transparent;
}

.main_tabsec > .nav-tabs>li:first-child {
    border-left: 0;
}

.main_tabsec > .nav-tabs>li>a {
    background: #b69a6b;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 15px;
}

.main_tabsec > .nav>li>a:focus {
    background: none;
}

.main_tabsec > .nav-tabs>li>a:after {
    content: "";
    border: 18px solid #0b0405;
    border-color: #0b0405 transparent transparent transparent;
    position: absolute;
    left: 50%;
    bottom: -36px;
    margin-left: -18px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.main_tabsec .nav-tabs>li.active>a:after {
    opacity: 1;
}

.modal-dialog {
    margin: .5rem;
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (.5rem * 2));
    min-height: -webkit-calc(100% - (.5rem * 2));
}

.modal-content {
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 14px;
}

.poup_wrap {
    width: 660px;
}

.popup_in {
    padding: 28px 60px 54px;
}

.close_popup {
    font-size: 38px;
    font-weight: normal;
    line-height: 32px;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 32px;
    width: 32px;
    color: #231f20;
    text-align: center;
}

.chosen-container .chosen-results .highlighted {
    background: #ecdfcc !important;
}

.tgl {
    display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
    background: none;
}

.tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 45px;
    height: 22px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}

.tgl + .tgl-btn:after {
    left: 1px;
}

.tgl + .tgl-btn:before {
    display: none;
}

.tgl:checked + .tgl-btn:after {
    left: 50%;
}

ul.tg-list {
    float: left;
    padding-top: 7px;
    list-style: none;
    padding-left: 0;
}

.tgl-light + .tgl-btn {
    background: #f9f9f9;
    padding: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border-radius: 2em;
}

.tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #b4b4b4;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    width: 22px;
    height: 22px;
}

.tgl-light:checked + .tgl-btn:after {
    background: #e2b014;
}

.desktop_hmenu_list .submenu-arow {
    display: none
}

.mobile_hmenu_list .submenu-arow {
    display: block;
    width: 40px;
    background: #e6e6e6;
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
}

.submenu-arow:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: 50%;
    line-height: 1;
    margin-top: -11px;
    font-size: 22px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    display: block !important;
}

.open-submenu .submenu-arow:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: block !important;
}

.hsearch_sec, .hsearch_sec_prolist {
    width: 480px;
    position: absolute;
    right: 38px;
    top: 100%;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
}

.hsearch_sec_prolist{
    right: 0;
}

.hsearch_sec.open, .hsearch_sec_prolist.open {
    opacity: 1;
    visibility: visible;
    top: 54px;
    pointer-events: auto;
}

.hsearch_sec_prolist.open{
    top: 34px;
}

.hsearch_bx {
    position: relative;
}

.hsearch_bx .form-control {
    height: 48px;
    border: 0;
}

.hsearch_bx .btn {
    background: #ecdfcc url(../images/search.png) no-repeat center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 65px;
    border-radius: 0;
    margin: 0;
    height: auto;
    padding: 0;
}

.hsearch_bx .btn:hover {
    background-color: #1c1c1c;
}

.hsearch_bx .re_select .Select-control {
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    overflow: visible;
    height: 54px;
}

.hsearch_bx .re_select .Select-arrow {
    opacity: 0;
}

.hsearch_bx .re_select .Select-placeholder {
    line-height: 52px;
    padding: 0 26px;
    color: #abaaa8;
}

.hsearch_bx .re_select .Select-input {
    height: 54px;
    padding: 0 26px;
    float: left;
}

.hsearch_bx .re_select .Select-input > input {
    line-height: 34px;
}

.hsearch_trigger_img img {
    width: 26px
}

.hsearch_trigger_img {
    margin: 5px 0 0 10px;
}

.hmenu_sec ul {
    padding: 0 0 0 20px;
    list-style: none;
    text-align: initial;
}

.hmenu_list > li {
    display: inline-flex;
    margin-right: 20px;
    position: relative;
}

.hmenu_list > li:hover > a,
.hmenu_list > li.active > a {
    color: #e2b014;
}

.hmenu_list > li:hover > a:after,
.hmenu_list > li.active > a:after {
    width: 100%;
    left: 0;
}

.hmenu_list > li > a > span {
    position: relative;
}

.hordertype_sec a{
    position: relative;
    display: block;
    padding: 10px 20px;
    margin: 0 20px 0 10px;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    text-transform: uppercase; 
    font-size: 15px;
    font-family: 'poppinsmedium'; 
    border-radius: 5px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
}

.btn:hover, .btn_sec .delivery_submit_cls .delivery_submit_div button:hover, .myorder_btns .view_recipt:hover, 
.con_first .delivery_submit_cls button:hover, input[type=button]:hover, input[type=reset]:hover, 
input[type=submit]:hover, .hordertype_sec a:hover, .voacher_btn-one:hover, .vouvherredmd_btn .button:hover,
.order-btn:hover, .load_more_div .load_more_data:hover, .prd_chosen_sub_item_right button:hover,
.chk-payment-btn-row .button:hover, .redeem_apply .button:hover, .self_outlet .button:hover, .alt_btns a:hover,
.two-button-row .delivery_submit:hover, .delevery_popup_datetime .button:hover, .change_pswddiv .chg_pswd:hover,
.takelist-btn-part .button:hover, .acc-inform .form-group .button:hover, .login_pop_sub .btn:hover, 
.signup_pop_btn .button:hover, .promotion-top-form .applyBtn:hover, .promation_btn .button:hover,
.redeem_popsec .button:hover, .refer-btn .button:hover, .change-pass-sbmt .button:hover, 
.voucher-contentmain .btn:hover, .gift-group .button:hover, .cartaction_bottom .btn:hover,
.tnk-btnp-div .button:hover, .page404-main-div .gohome:hover, .vouchar_btn .button:hover{
    background: #222222;
    color: #fff;
}

.rgt-sec-prt a:hover, .banner-info a:hover, .mid-sec-prt a:hover{
    background-position: 100% 0;
    transition: all .4s ease-in-out;
    color: #000000;
}

.two-button-row .delivery_submit:hover{
    border: 1px solid #000;
}

.products-single-li:hover .products-ordernow-action .button, .contact-btn .button:hover{
    background: #222222;
    color: #ffffff;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list {
    margin: 0;
    padding: 0px;
    background: #e2b014;
    z-index: 99;
    width: 220px;
    position: absolute;
    left: 0px;
    top: 42px;
    display: none;
}

.hmenu_list.desktop_hmenu_list > li:hover > ul,
.hmenu_list.desktop_hmenu_list > li ul li:hover>ul {
    display: block;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a {
    font-family: 'poppinsregular';
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    padding: 10px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.078)
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a:hover {
    color: #fff;
    background: #e2b014;
}

.hmenu_sec .mobile_hmenu_list ul.submenu_list {
    display: none;
    background: #f7f7f7
}

.mobile_hmenu_list ul.submenu_list li a {
    display: block;
    padding: 10px 15px;
    font-family: 'poppinsregular';
    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #e4e4e4;
}

.mobile_hmenu_list ul.submenu_list li a:hover {
    color: #000000;
}

.hsearch_sec .search_result {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #f7f2ea;
    max-height: 300px;
    overflow-y: auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    display: none
}

.search_result li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.090);
}

.search_result li a,
.no-pro-found {
    color: #333;
    display: block;
    padding: 7px 15px
}

.search_result li a:hover {
    background: #e2b014;
    color: #fff;
}

.hsearch_sec.open .input-sec, .hsearch_sec_prolist.open .input-sec {
    position: relative
}

.hsearch_sec.open .input-sec input, .hsearch_sec_prolist.open .input-sec input {
    padding-right: 40px;
    margin-bottom: 0
}

.hsearch_sec.open .input-sec:after,
.hsearch_sec.open .input-sec:before,
.hsearch_sec_prolist.open .input-sec:after,
.hsearch_sec_prolist.open .input-sec:before {
    bottom: 98%;
    right: 50px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.hsearch_sec.open .input-sec:after, .hsearch_sec_prolist.open .input-sec:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -8px;
}

.hsearch_sec.open .input-sec:before, .hsearch_sec_prolist.open .input-sec:before {
    border-color: rgba(208, 207, 203, 0);
    border-bottom-color: #d0cfcb;
    border-width: 9px;
    margin-left: -9px;
}

.search_text_clear {
    position: absolute;
    right: 10px;
    top: 11px;
    display: block;
}

.htico_cart > a {
    position: relative;
    display: block;
}

li.hcart_like.active a img:first-child, li.hcart_like a img:last-child {
    display: none;
}

li.hcart_like.active a img:last-child{ display: block;}

.hcartdd_trigger.active:before {
    opacity: 1;
    visibility: visible;
}

/************** CART SIDEBAR START ***************/

.hcart_dropdown {
    background: #fff;
    position: fixed;
    top: 0;
    right: 0;
    width: 720px;
    height: 100vh;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out,
    -webkit-transform .3s ease-in-out;
    z-index: 11;
    text-align: left;
}

.hcart_dropdown.open {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

body.cart-items-open {
    overflow: hidden!important;
}

.hcart_tt {
    background: #2f2f2e;
    padding: 8px 12px;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
}

.hcart_tt h3 {
    margin: 0 auto;
    font-size: 18px;
    color: #fff;
    font-family: 'poppinssemibold';
}

.close_product_orders {
    display: block;
    position: relative;
    order: 2;
    width: 24px;
    height: 24px;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    background: #2f2f2e;
}

.close_product_orders:after, .close_product_orders:before {
    position: absolute;
    left: 12px;
    content: " ";
    height: 16px;
    width: 2px;
    background-color: #fff;
}

.close_product_orders:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.close_product_orders:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.hcart_scrollarea {
    overflow-y: auto;
    height: calc(100% - 94px);
}

.cart_body {
    padding: 20px 20px;
}

.hcart_scrollarea .delivery-cart-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.hcart_scrollarea .cart_left, .hcart_scrollarea .cart_right {
    width: 48%;
}

.delivery-cart-div h4 {
    color: #0d0b06;
    font-size: 15px;
    text-transform: uppercase;
    margin: 0 0 5px;
    font-family: 'poppinssemibold';
}

.delivery-cart-div p {
    color: #000;
    margin: 0;
    font-size: 14px;
}

.hcart_add_innr_loca {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0 5px;
}

.hcart_add_innr_loca .input_field {
    width: 100px;
    margin-left: 8px;
}

.hcart_add_innr_loca input.form-control {
    margin: 0;
    height: 38px;
    padding: 5px;
    border-radius: 6px;
}

.cart-loc-change {
    font-size: 14px;
    border-bottom: 1px solid #c5c5c5;
    line-height: 1;
    display: inline-flex;
    font-family: 'poppinsmedium';
}

.cart-loc-change:hover{
    color: #e2b014;
}

.hcart_scrollarea .syd_time {
    text-align: right;
}

.product_orders_top {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #000000;
    padding: 10px 15px;
    line-height: 1;
}

.product_orders_top h4 {
    font-size: 15px;
    color: #fff;
    margin: 0;
    font-family: 'poppinsmedium';
    text-transform: uppercase;
}

.product_clear_cart a {
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-size: 14px;
    font-family: 'poppinsmedium';
}

.product_clear_cart a:hover {
    color: #e2b014;
}

.cartsidebar_row.product-details{
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #e6e6e6;
    padding: 15px 0;
}

.cartsidebar_row.product-details:last-child{
    border-bottom: 0;
}

.cartsidebar_row .cart_lhs{
    width: calc(100% - 180px);
    display: flex;
    align-items: flex-start;
}

.cartsidebar_row .cart_rhs{
    width: 175px;
}

.cart_img {
    width: 98px;
    height: 77px;
    margin: 0 15px 5px 0;
}

.cart_img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.cartsidebar_row .cart_info {
    max-width: calc(100% - 105px);
    max-width: -webkit-calc(100% - 105px);
}

.cart_info {
    display: inline-block;
    vertical-align: top;
    color: #3d3d3d;
}

.cart_info h4 {
    font-size: 15px;
    margin: 0 0 6px;
    font-family: 'poppinsmedium';
}

.cart_price {
    position: relative;
    margin: 0 0 10px;
    padding-right: 22px;
    line-height: 1;
}

.cart_price p {
    margin: 0;
    font-size: 16px;
    font-family: 'poppinssemibold';
}

.cart_remove {
    background: #b0b0b0;
    position: absolute;
    top: 14px;
    right: 0;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart_remove:after, .cart_remove:before {
    position: absolute;
    top: 3px;
    right: 7px;
    content: "";
    height: 10px;
    width: 1.9px;
    background: #fff;
}

.cart_remove:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.cart_remove:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.cart_remove:hover {
    background: #000000;
}

.hcart_dropdown .cart_footer {
    padding: 20px;
    background: #f7f7f7;
    margin: 0 20px;
}

.cart_footer .cart_row {
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -webkit-box-pack: justify;
    margin-bottom: 5px;
    font-family: 'poppinssemibold';
}

.cart_footer .cart_row.promo_add{
    position: relative;
}

.cart_footer span, .cart_footer p {
    font-size: 16px;
    font-family: 'poppinssemibold';
    color: #000;
    margin: 0;
}

.cart_row.grant-total-cls {
    padding-top: 10px;
    margin-bottom: 20px;
}

.cart_row.grant-total-cls p, .cart_row.grant-total-cls span {
    font-size: 22px;
    line-height: 1.2;
    font-family: 'poppinssemibold';
}

.progress_bar_div {
    margin-top: 5px;
    padding: 5px 20px 10px;
}

.indication_progress {
    height: 15px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    background: #1c1c1c;
}

.progress_bar {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 15px;
    width: 0;
    background: #f0ce46;
    border-radius: 10px;
    transition: all .4s linear;
}

.progress_bar_low {
    background: #29d492;
}

.progress_bar_div .help-block {
    display: block;
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'poppinssemibold';
    color: #000;
    padding-top: 0;
    margin: 7px 0 0;
}

.cart_action {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #ffffff;
}

.cartaction_bottom .btn {
    display: block;
    margin: 0 20px;
    border-radius: 0;
    font-size: 16px;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
}

.cart_left .help-block {
    font-style: italic;
    color: #a7a4a4;
    margin: 5px 0 0;
    font-size: 13px;
}

.cart_extrainfo {
    color: #3d3d3d;
    margin: 0 0 5px;
}

.cart_extrainfo p b {
    color: #3d3d3d;
    font-style: normal;
    font-family: 'poppinsregular';
}

.cart_extrainfo p {
    margin: 0;
    color: #3d3d3d;
    font-style: italic;
    font-size: 16px;
}


.cart_extrainfo:empty {display: none;}

.qty_bx {
    width: 123px;
    height: 40px;
    position: relative;
    padding: 0 38px;
    display: inline-block;
    border: 0;
    border-radius: 6px;
}

.qty_bx span {
    position: absolute;
    width: 40px;
    height: 100%;
    display: block;
    top: 0;
    bottom: 0;
    text-indent: 9999px;
    overflow: hidden;
    cursor: pointer;
    margin: auto;
    background: #fff;
    border: 2px solid #000;
    border-radius: 6px;
    transition: all .3s linear;
    -webkit-transition: 0.3s linear all;
}

.qty_bx input {
    height: 100%;
    border: none;
    color: #000;
    font-size: 16px;
    font-family: 'poppinssemibold';
    text-align: center;
    margin: 0;
    padding: 2px;
    background: #fff;
    border-radius: 5px;
}

.discount_info {
    margin-left: 15px;
}

.discount_info p {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #3d3d3d;
}

.promo_delico_parent {
    position: relative;
}

.promo_delico {
    position: absolute;
    top: 0;
    left: -21px;
    width: 21px;
    height: 21px;
    line-height: 21px;
    text-align: center;
    color: #3d3d3d;
}

.promo_delico:hover {
    background-color: #b69a6b;
    color: #fff;
}

.cart_footer_totrow p {
    font-size: 25px;
}

.cart_footer_totrow p.help-block {
    font-size: 12px;
    font-size: 14px;
    font-weight: 300;
}

.cart_footer_totrow span {
    font-size: 25px;
}

.delivery_info {
    background: #f5f5f5;
    padding: 24px 35px;
}

.delivery_address {
    margin: 0 0 20px;
}

.delivery_info h4 {
    color: #838181;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
}

.delivery_info p {
    color: #3d3d3d;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
}

.delivery_link {
    color: #3d3d3d;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 10px 0 0 0;
    display: inline-block;
    vertical-align: top;
}

.delivery_link:hover {
    border-color: #b69a6b;
}

.delivery_time {
    margin: 0 0 16px;
}

.delivery_handle {
    border-top: 1px solid #d3d1d1;
    padding: 20px 0 0 0
}

.cart_action .btn_grey {
    margin: 0;
    border-color: #e6e3e3;
}

.text-left {
    text-align: left
}

.header_top {
    background: #181818;
    position: relative;
}

.htopinfo {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    padding: 10px 0;
}

.htopinfo p {
    margin: 0;
}

.pop-alert-icon {
    padding: 0 0 15px;
}

.sticky .alert_fixed {
    top: 95px;
}

.scrollToTop {
    background: rgba(0, 0, 0, 0.7);
    width: 40px;
    height: 40px;
    line-height: 36px;
    position: fixed;
    bottom: 40px;
    right: 20px;
    font-size: 24px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    z-index: 1;
}

.scrollToTop:hover,
.scrollToTop:focus {
    background: rgba(0, 0, 0, 1);
    color: #fff;
}

.outlet {
    position: relative;
}

.outlet_inner {
    padding: 63px 35px 76px 35px;
    background: #fff;
    margin: 25px 0 39px 0;
}

.innerproduct .products-list-ulmain{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.products-list-ulmain>li, .products-single-li {
    position: relative;
    z-index: 2;
    text-align: center;
    width: 23.828125%;
    margin: 0 1.5625% 20px 0;
    background: #fff;
    transition: all .4s linear;
    border: 2px solid #f3f3f3;
    overflow: hidden;
    
}

/*.products-single-li{
    height: 100%;
}

 .product-list-main-div{
    height: 100%;
} */

.products-single-li:hover {
    border: 2px solid #fdc855;
}

.products-list-ulmain>li:nth-child(4n) {
    margin-right: 0;
}

.sale-flag-sash {
    background: #e91954;
    font-size: 15px;
    height: 35px;
    left: -40px;
    line-height: 2;
    padding: 5px 0;
    display: block;
    padding-right: 27px;
    top: 5px;
    transition: .8s ease;
    width: 10.93333rem;
    z-index: 20;
}

.sale-flag-sash {
    position: absolute;
    color: #fff;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.sale-text {
    padding-left: 0;
    line-height: 20px;
    font-family: 'poppinssemibold';
}

.products-single-li .like {
    position: absolute;
    top: 8px;
    right: 8px;
}

.like img {
    width: 23px;
    height: 19px;
}

.products-single-li .like .mou_active {
    display: none;
}

.products-single-li .like.active .mou_active, .products-single-li .like:hover .mou_active{
    display: block;
}

.products-single-li .like.active .no_mou, .products-single-li .like:hover .no_mou{
    display: none;
}

.products-image-div {
    height: 285px;
}

.products-image-div img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.product-info-div {
    padding: 10px 20px 135px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: calc(100% - 285px);
    background: #fff;
    transition: all .3s linear;
}

.special-price{
    margin: 0 0 5px;
}

.special_product {
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    display: inline-block;
    width: 26px;
    height: 23px;
}

.special_product::after {
    position: absolute;
    content: "";
    top: 0;
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -458px -19px;
    z-index: 1;
    width: 20px;
    height: 20px;
    text-align: center;
    margin: auto;
    left: 0;
    right: 0;
}

.innerproduct-item {
    position: relative;
}

.innerproduct-item-image {
    background: #fff;
    text-align: center;
}

.innerproduct-item-image img {
    height: auto;
    width: auto;
}

.innerproduct-item-hei {
    height: 255px;
    line-height: 255px;
    text-align: center;
}

.innerproduct-item::after {
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.86);
    width: 100%;
    position: absolute;
    z-index: 1;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after, .innerproduct-col.open::after {
    opacity: 1;
}

.innerproduct-button {
    position: inherit;
    top: 0%;
    transform: inherit;
    -webkit-transform: inherit;
    z-index: 2;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    opacity: 1;
    visibility: visible;
    transition: 0.5s all ease-in-out;
    -webkit-transition: 0.5s all ease-in-out;
}

.innerproduct-item-text {
    text-align: center; position: relative; padding: 0;
}

.innerproduct-item-text h4 {
    font-size: 16px;
    color: #363636;
    padding: 10px 0 8px 0;
    margin-bottom: 0;
}

.innerproduct-item-text h4 a {
    color: #363636;
}

.innerproduct-item-text p {
    font-weight: 300;
}

.innerproduct-item-text p a {
    color: #5b5b5b;
}

.innerproduct-item-text h5 {
    font-size: 20px;
    color: #363636;
    margin-bottom: 0;
}

.innerproduct-item-text h5 a {
    color: #363636;
}

.price_none {
    display: inline-block;
    font-size: 13px;
    font-weight: 300;
    color: #999;
    padding-right: 10px;
}

.price_offer .price_none {
    width: 50%;
    text-align: left;
}

.price_offer .price_nor {
    width: 50%;
    text-align: right;
    display: inline-block;
    margin-left: -4px;
}

.prodetailinner-main-div{
    padding: 60px 0 0;
}

.inn-product-popup {
    width: 100%;
    background: #fff;
}

.product-go-back {
    margin-bottom: 25px;
}

.product-go-back a {
    font-family: 'poppinssemibold';
    text-transform: uppercase;
    font-size: 15px;
    padding-left: 34px;
    position: relative;
    letter-spacing: 1px;
}

.product-go-back a:before {
    content: "";
    position: absolute;
    top: 2.5px;
    left: 0;
    width: 24px;
    height: 11px;
    background: url(../images/download.png) no-repeat;
}

.product-go-back a:hover{
    color: #e2b014;
}

.product-details-top-info {
    padding: 0 0 40px;
}

.product-details-top-info .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.product-details-lhs {
    width: 50%;
    padding-right: 15px;
    position: relative;
    overflow: hidden;
}

.product-details-lhs .like {
    position: absolute;
    top: 15px;
    right: 30px;
    z-index: 1;
}

.product-details-lhs .like img:first-child, .product-detail-page .products-single-li .like.active img.active-hart {
    display: block;
}

.product-details-lhs .like img:last-child {
    display: none;
}

.product-details-lhs>.slick-slider>.slick-list {
    border: 2px solid #e2b014;
}

.product-details-lhs .slick-track .slick-slide > div{
    width: 100%;
}

.product-details-lhs .slick-track .slick-slide > div div {
    height: 100%;
}

.product-details-lhs .slick-slide img{
    height: 100%;
    width: 100%;
}

.product-details-rhs {
    width: 50%;
    padding-left: 15px;
}

.prodet_baninfo {
    position: relative;
}

.inn_product_hea>h3 {
    font-size: 14px;
    font-family: 'poppinsbold';
    margin-bottom: 15px;
}

.inn_product_hea_left h3 {
    font-size: 32px;
    margin: 0 0 20px;
}

.product-description-tab {
    padding: 25px 0 15px;
}

.product-description-tab ul {
    padding: 0;
    display: flex;
    border-bottom: 3px solid #eaeaea;
}

.product-description-tab li {
    width: 50%;
    position: relative;
}

.product-description-tab li a {
    display: block;
    text-align: center;
    font-family: 'poppinsbold';
    padding: 0 5px 10px;
}

.product-description-tab li a:hover {
    color: #000;
}

.product-description-tab li:after {
    bottom: -3px;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: #000;
    transition: width .3s ease 0s,left .3s ease 0s;
    width: 0;
}

.product-description-tab li.active:after, .product-description-tab li:hover:after {
    width: 100%;
    left: 0;
}

.inn_product_row {
    margin: 0 auto;
}

.product-item-img {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.product-item-img img {
    margin-right: 10px;
    height: 21px;
}

.compo_top_div {
    background: #fff;
    padding: 20px 0;
}

.product_chosen_inner {
    display: flex;
    flex-wrap: wrap;
}

.product_chosen_col {
    width: 100%;
}

.product_chosen_col_inner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 30px;
}

.product_chosen_item_left_full {
    width: 100%;
    margin-bottom: 10px;
}

.product_chosen_item_left:nth-of-type(2n+2) {
    margin-right: 0;
}

.compo_pro_acc {
    background: #f6f6f6;
    width: 100%;
}

.compopro_acc_head {
    position: relative;
    padding: 12px 20px;
    cursor: pointer;
}

.compopro_acc_head:after, .compopro_acc_head:before {
    background: #5e5e5e;
    content: "";
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s,-webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 14px;
    z-index: 2;
}

.compopro_acc_head:before {
    content: "";
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}

.compo_acc_active .compopro_acc_head:before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.product_chosen_hea h6 {
    position: relative;
    font-size: 15px;
    color: #000;
    text-transform: uppercase;
    font-family: 'poppinssemibold';
    margin: 0;
    padding: 0 20px 0 0;
    z-index: 1;
}

.compo_mdfselect_maindiv .error_combo_div {
    color: red;
    display: none;
}

.common_compo_div .custom_radio {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-direction: row-reverse;
    padding: 10px 0;
}

.common_compo_div .custom_radio input {
    width: 28px;
    height: 28px;
}

.common_compo_div .custom_radio span {
    width: calc(100% - 30px);
}

.text-box textarea {
    width: 100%;
    height: 120px;
    padding: 15px;
}

.text-box em {
    text-transform: uppercase;
    font-size: 13px;
    color: #181818;
    text-align: right;
    float: right;
}

.prd_chosen_sub_row {
    text-align: center;
    padding: 15px 0 10px;
    border-top: 1px solid #eaeaea;
    margin: 30px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.total_price.product_details_price {
    font-size: 34px;
    line-height: 1;
    color: #000;
    margin: 0;
    width: 150px;
    text-align: left;
}

.prd_chosen_sub_col {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    max-width: 100%;
    width: calc(100% - 150px);
    justify-content: space-between;
}

.prd_chosen_sub_item_left {
    display: flex;
    width: 124px;
    margin: 0;
    flex-flow: column;
}

.prd_chosen_sub_item_left p {
    margin: 0 0 5px;
    font-size: 14px;
}

.inn_product_row .addcart_row .qty_bx {
    width: 100%;
    height: 42px;
    padding: 0 38px;
    border-radius: 8px;
}

.inn_product_row .addcart_row .qty_bx span {
    width: 38px;
    height: 42px;
}

.qty_bx span.qty_plus:after, .qty_bx span.qty_plus:before {
    background: #fff;
}

.qty_bx span.qty_plus:before {
    width: 14px;
    height: 2px;
}

.qty_bx span.qty_plus:after {
    width: 2px;
    height: 14px;
}

.prd_chosen_sub_item_right.cart_update_div {
    width: calc(100% - 134px);
}

.prd_chosen_sub_item_right button {
    width: 100%;
    margin: 0;
    font-size: 16px;
    padding: 15px 10px;
    min-width: inherit;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    color: #000;
    font-family: 'poppinsmedium';
}

.category-products-main {
    margin-bottom: 75px;
}

.category-products-main .owl-stage {
    display: flex;
}

.category-products-main .products-single-li {
    height: 100%;
    width: 100%;
    margin: 0;
}

.category-products-main .products-single-li {
    position: relative;
    z-index: 2;
    text-align: center;
    border: 2px solid #f3f3f3;
}

.category-products-main .products-single-li:hover{
    border: 2px solid #fdc855;
}


.order-detail-inner {
    padding: 82px 0 0 0
}

.order-detail-inner-icon {
    position: relative;
    max-width: 604px;
}

.order-detail-inner-icon-left {
    position: absolute;
    left: 0;
    top: 2px;
}

.delivery_datetime_div {
    padding: 10px 0 10px 0 !important;
    margin: 10px 0 0px 0 !important;
}

.orderitem_body_div {
    background: #f6f6f6;
    padding: 10px;
}

.orderitem_body_div .cart_row .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0
}

.mfp-bg {
    z-index: 10042;
}

.mfp-wrap {
    z-index: 10043;
}

.white-popup {
    max-width: 1026px;
    padding: 0;
    margin: 30px auto;
}

.alert_popup, .warining_popup, .warning_popup, .pay-conf-popup {
    max-width: 520px;
}

.warning_popup .warning-popup-img {
    width: 75px;
    margin: 0 0 10px 0
}

button.mfp-close,
button.mfp-arrow {
    margin: 0;
    padding: 0
}

.alert_popup .mfp-close {
    display: none;
}

.alert_header {
    background: #181818;
    color: #fff;
    font-size: 18px;
    font-family: 'poppinsregular';
    padding: 12px 14px;
    position: relative;
    text-align: center;
}

.alert_body {
    text-align: center;
    padding: 20px;
}

.alt_btns a {
    margin: 0 5px;
}

.alt_btns a.button-left {
    background: #000;
    color: #fff;
}

.alt_btns .button-right:hover, .alt_btns a.button-left:hover{
    background: #000;
    color: #fff;
}

.poptt_icontop {
    padding-left: 0;
}

.poptt_icontop i {
    position: static;
    margin: 0 auto 17px;
}

.address-list-main{
    text-align: left;
}

.address_linfo{
    margin-bottom: 10px;
}

/*order_now popup*/
.order_popup, .delevery_popup_datetime, .self_popup, .vouchergift-popup, .membenifits_popup{
    position: relative;
    background: #f6f6f6;
    max-width: 680px;
    padding: 0;
    margin: 30px auto;
}

.full-login-new-header {
    background: #e2b014;
    padding: 20px 35px;
    text-align: center;
    box-shadow: 0 4px 18px 0 rgb(2 2 2 / 4%);
    color: #fff;
}

.full-login-new{
    position: relative;
    background: #f6f6f6;
}

.full-login-new-header h2 {
    font-size: 32px; 
    margin-bottom: 0px;  
    text-transform: uppercase; 
    color: #000;
}
.full-login-new-header p {
    color: #000;
    margin: 0; 
    text-transform: uppercase
}
.full-login-new-body {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    padding: 70px 0;
    text-align: center;
}

.order_delivery_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 20px;
}

.order_delivery_item li {
    width: 31%;
    position: relative;
    margin: 0 20px 0;
}

.order_delivery_item li:last-child{
    margin: 0;
}

.order_delivery_item li a {
    display: block;
    padding: 15px 10px 20px;
    background: #fff;
    position: relative;
    border-radius: 5px;
    transition: all .3s linear;
    box-shadow: 0 5px 16px 0 rgb(0 0 0 / 5%);
    height: 100%;
}

.ordernow-single-img {
    height: 81px;
    display: flex;
    align-items: flex-end;
}

.ordernow-single-img img {
    display: none;
    margin: 0 auto;
    max-width: 84px;
    max-height: 75px;
}

.ordernow-single-img img.order_type_img {
    display: block;
}

.order_delivery_item h3 {
    color: #000;
    margin: 15px 0 0;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.order_delivery_item li a.active, .order_delivery_item li a:hover {
    background: #241e21;
}

.order_delivery_item li a.active .order_type_img, .order_delivery_item li a:hover .order_type_img {
    display: none;
}

.order_delivery_item li a.active .order_type_imgwt, .order_delivery_item li a:hover .order_type_imgwt {
    display: block;
}

.order_delivery_item li a.active h3, .order_delivery_item li a:hover h3 {
    color: #fff;
}

/*** Deivery Popup *****/

.delevery_popup, .error_postal_popup{
    position: relative;
    max-width: 730px;
    background: #f6f6f6;
    padding: 0;
    margin: 30px auto;
}

img.pop-scooter-img {
    max-width: 90px;
    margin-bottom: 15px;
}

.innervmid_in {
    max-width: 380px;
    margin: 0 auto;
}

.datetime_selt_lbl {
    font-size: 14px;
    font-family: 'poppinsbold';
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 10px;
    color: #000; 
}

.popup_equalrw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.popup_ttsec {
    width: 42%;
    min-height: 310px;
    padding: 20px 20px;
    min-height: 310px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pop_title {
    position: relative;
    color: #fff;
}

.pop_title h2 {
    font-size: 36px;
    margin: 0;
    line-height: 1;
    color: #fff;
    text-transform: inherit
}

.pop_title small {
    font-size: 19px;
    font-weight: normal;
}

.popup_right {
    width: 58%;
    padding: 20px 60px 20px 68px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.delevery_popup .popup_ttsec {
    background: #e2b014;
}

.delevery_popup .popup_ttsec {
    padding-left: 38px;
    padding-right: 28px;
}

.delevery_popup .popup_right .form-group .form-control {
    border: 1px solid #dadada;
}

.two-button-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.two-button-row > div .button {
    display: block;
    margin: 0;
    width: 100%;
    font-family: 'poppinsmedium';
}

.two-button-row > div {
    width: 49%
}

.two-button-row > div.active .button {
    background: #e2b014;
}

.two-button-row .delivery_submit {
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
    border: 1px solid #e2b014;
}

.go_second a {
    background: #e2b014
}

.go_second a:hover {
    background: #000;
    color: #fff;
}
.go_second a.button.button-left {
    background: #000;
}
.error_postal_popup .popup_right .button {
    margin: 0
}

.con_first a{
    background:#fff;
    color: #000000;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
    border: 1px solid #000;
}

.con_first a:hover{
    background:#000000;
    color: #ffffff;
    border: 1px solid #000;
}

.delevery_popup_datetime .button:first-child:hover,
 .takelist-btn-part .button:first-child:hover{
    background: #fff;
    color: #000;
    border: 1px solid #000;
}

.pdpa_trmcont_link{ font-size: 14px;}
.pdpa_trmcont_link .sign_reg_uline, .sign_reg .sign_reg_uline{ 
    /* font-family: 'poppinssemibold';  */
    text-decoration: underline;
}
.pdpa_trmcont_link .sign_reg_uline:hover, .sign_reg .sign_reg_uline:hover{ color: #e2b014;}
.pdpa_trmcont_link span{ display: inline-block; }

.delivery_submit_div .button {
    width: 100%;
    background: #000;
    color: #fff;
}

.delivery_submit_div .button:hover{
    background: #e2b014;
    color: #000;
}

.delevery_popup_datetime .full-login-new-body>h2 {
    font-family: 'poppinsbold';
    margin-bottom: 5px;
    color: #000;
}

.awesome_del h5 {
    font-size: 16px;
    font-family: 'poppinssemibold';
    text-transform: capitalize;
    color: #000;
}

.awesome_del h2 {
    font-size: 14px;
    text-transform: inherit;
    line-height: 1.5;
    display: inline-flex;
    padding-left: 30px;
    position: relative;
    color: #000;
}

.awesome_del h2:before{ 
    position: absolute; 
    content: "";
    top: -2px;
    left: 0; 
    width: 22px; 
    height: 22px; 
    background: url(../images/checked.png) no-repeat;
}

.awesome_del {
    text-align: center;
}

.datetime_selt_lbl {
    font-weight: 600;
    padding-bottom: 10px;
    color: #000;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'poppinsmedium';
}

.delevery_popup_datetime .btn_sec {
    margin: 10px auto 0;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
}

.delevery_popup_datetime .button, .takelist-btn-part .button {
    width: 48%;
    margin: 0;
    display: block;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
    padding: 15px 10px;
    border-radius: 5px;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
    border: 1px solid #e2b014;
    font-family: 'poppinsmedium';
}

.delevery_popup_datetime .button:hover{
    border: 1px solid #000000;
}

.delevery_popup_datetime .button:first-child, .takelist-btn-part .button:first-child {
    background: #000;
    color: #fff;
    border: 1px solid #000;
}

.delevery_popup_datetime .full-login-new-body {
    max-width: 440px;
    padding: 50px 0;
}

.delevery_popup_datetime .syd_date .react-datepicker__input-container:after{
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 40px;
    top: 3px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    pointer-events: none;
    height: 42px;
    border-radius: 5px;
}

.syd_date .react-datepicker__input-container:after, .syd_time .react-datepicker__input-container:after,
.left-input-div .react-datepicker__input-container:after, .right-input-div:after {
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 40px;
    top: 4px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    pointer-events: none;
    height: 42px;
    border-radius: 5px;
}

.self_popup .full-login-new-body {
    max-width: 100%;
    padding: 30px 50px;
}

.self_pop_row {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
}

.self_pop_col.self_pop_col_right {
    width: 100%;
    margin: 0 auto;
}

.self_pop_locbx {
    margin: 0 0 20px;
}

.self_pop_locbx .form-group .form-control {
    width: 100%;
    padding-right: 40px;
}

.self_pop_locbx .focus-out:after {
    content: "\f002";
    font-family: FontAwesome;
    position: absolute;
    right: 20px;
    top: 13px;
    font-size: 22px;
    line-height: 1;
    color: #333;
}

.self_outlet {
    margin-top: 15px;
    height: calc(100% - 105px);
}

.self_outlet h2 {
    color: #000;
    font-size: 17px;
    text-align: left;
    margin: 0 0 10px 5px;
    font-weight: 700;
}

.self_outlet_inner {
    padding-left: 0;
    text-align: left;
    overflow-y: auto;
    margin: 0 0 14px;
    max-height: 500px;
}

.self_outlet_inner li {
    margin-bottom: 12px;
}

.self_outlet_inner li.active a, .self_outlet_inner li:hover a {
    background: #e2b014;
    color: #fff;
}

.self_outlet_inner li a {
    padding: 10px 15px;
    display: block;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    background: #000000;
    color: #fff;
}

.self_outlet_inner li:last-child {
    margin-bottom: 0;
}

.self_outlet .button {
    margin: 0;
    padding: 15px 10px;
    width: 100%;
    display: block;
    font-family: 'poppinsmedium';
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
}

.outlet-list-lhs {
    width: 130px;
}

.outlet-list-lhs>img {
    width: 130px;
    max-height: 85px;
    object-fit: cover;
}

.outlet-list-rhs {
    color: #181818;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: calc(100% - 145px);
}

.self_outlet .self_outlet_inner>li.active .outlet-list-name, .self_outlet .self_outlet_inner>li.active .outlet-list-rhs, .self_outlet .self_outlet_inner>li:hover .outlet-list-name, .self_outlet .self_outlet_inner>li:hover .outlet-list-rhs {
    color: #fff;
}

.outlet-list-name {
    font-size: 14px;
    text-transform: inherit;
    font-weight: 600;
    color: #181818;
    font-family: "Poppins",sans-serif;
    margin: 0 0 5px;
}

.outlet-list-address {
    font-size: 13px;
    margin-bottom: 5px;
}

.outlet-list-rhs ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
}

.outlet-list-rhs ul li {
    margin: 5px 15px 0 0;
    font-size: 13px;
}

.outlet-list-rhs ul li.otulet-waiting span {
    position: relative;
    padding: 0 0 0 24px;
}

.outlet-list-rhs ul li:last-child {
    margin: 5px 0 0 30px;
}

.outlet-list-rhs ul li.otulet-waiting span:before{ 
    width: 16px; height: 16px; content: ""; position: absolute; left: 0; 
    top: 0; background: url(../images/waiting-clock.png) no-repeat;
}

.outlet-list-rhs ul li.otulet-direction span {
    position: relative;
    padding: 0 0 0 25px;
}

.otulet-direction a {
    border-bottom: 1px solid #8c8c8c;
}

.self_outlet .self_outlet_inner>li.active .otulet-direction a, .self_outlet .self_outlet_inner>li:hover .otulet-direction a {
    color: #fff;
    border-color: #fff;
}

.mfp-close-btn-in .order_popup .mfp-close, .mfp-close-btn-in .awesome_popup .mfp-close,
.mfp-close-btn-in .self_popup .mfp-close, .mfp-close-btn-in .delevery_popup .mfp-close,
.mfp-close-btn-in .error_postal_popup .mfp-close, .mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close, .changepw_popup .mfp-close,
.redeem_popsec .mfp-close, .pay-conf-popup .mfp-close, .warning_popup .mfp-close, .vouchergift-popup .mfp-close,
.delevery_popup_datetime .mfp-close, .login-popup .mfp-close, .signup-popup .mfp-close,
.guest-checkout-popup .mfp-close, .forgot-password-popup .mfp-close, .recip_popup .mfp-close,
.membenifits_popup .mfp-close{
    background: #000000 ;
    color: #fff;
    font-size: 0;
    width: 26px;
    height: 26px;
    line-height: 30px;
    opacity: 1;
    top: 5px;
    right: 5px;
    padding: 0;
    margin: 0;
}

.mfp-close-btn-in .order_popup .mfp-close::after, .mfp-close-btn-in .awesome_popup .mfp-close::after,
.mfp-close-btn-in .self_popup .mfp-close::after, .mfp-close-btn-in .delevery_popup .mfp-close::after,
.mfp-close-btn-in .error_postal_popup .mfp-close::after, .mfp-close-btn-in .receipt_popup .mfp-close:after,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:after, .changepw_popup .mfp-close:after,
.redeem_popsec .mfp-close:after, .pay-conf-popup .mfp-close:after, .delevery_popup_datetime .mfp-close:after,
.warning_popup .mfp-close:after, .venue_popup .close-icon-but:after, .login-popup .mfp-close:after,
.signup-popup .mfp-close:after, .guest-checkout-popup .mfp-close:after, .forgot-password-popup .mfp-close:after,
.recip_popup .mfp-close:after, .vouchergift-popup .mfp-close:after, .membenifits_popup .mfp-close:after{
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 15px;
    height: 15px;
    background-position: -83px -190px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .order_popup .mfp-close:hover, .mfp-close-btn-in .awesome_popup .mfp-close:hover,
.mfp-close-btn-in .delevery_popup .mfp-close:hover, .mfp-close-btn-in .error_postal_popup .mfp-close:hover,
.changepw_popup .mfp-close:hover, .redeem_popsec .mfp-close:hover, .pay-conf-popup .mfp-close:hover,
.warning_popup .mfp-close:hover, .venue_popup .close-icon-but:hover, .membenifits_popup .mfp-close:hover{
    background: #000;
}

/* .mfp-close-btn-in .receipt_popup .mfp-close, .mfp-close-btn-in .receipt_cateringpopup .mfp-close {
    background: rgba(255, 255, 255, 0.2);
} 
.mfp-close-btn-in .receipt_popup .mfp-close:hover, .mfp-close-btn-in .receipt_cateringpopup .mfp-close:hover {
    background: rgba(255, 255, 255, 0.6);
}*/

.video_popup .order-body {
    padding: 60px 30px 40px 30px;
}

.or-seperator-row {
    position: absolute;
    right: -9px;
    z-index: 1;
    top: 6px;
}

.or-seperator-row .or-seperator {
    position: relative;
    padding: 25px 0px 7px 0;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator-row .or-seperator span {
    padding: 7px 0px;
}

.or-seperator-row .or-seperator:after {
    height: 82px;
    width: 1px;
    background: #e5cda6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.forgot-password-popup, .login-popup, .signup-popup, .guest-checkout-popup {
    max-width: 680px;
    margin: 20px auto;
    width: 100%;
    background: #fff;
    position: relative;
}

.popup-footer span {
    color: #868686;
    font-size: 14px;
    text-transform: uppercase;
}

.popup-footer .or-seperator span{
    font-family: 'poppinssemibold';
}

.full-login-new .kep-login-facebook.metro {
    display: block;
    margin: 0 auto;
    background: #3d5a98;
    text-transform: inherit;
    border-radius: 5px;
    width: 100%;
    padding: 8px 15px;
    font-size: 13px;
    letter-spacing: 0.5px;
    font-family: 'poppinsbold';
    font-weight: inherit;
    border: 1px solid #cbcbcb;
    box-shadow: 0 3px 9px 0 rgb(0 0 0 / 21%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-login-new .kep-login-facebook.metro:hover {
    background: #333;
    color: #fff;
}

.full-login-new .kep-login-facebook.metro i{
    font-size: 25px;
}

.guest_btn i{
    margin-right: calc(0.34435vw + 3.38843px);
    font-size: 25px;
}

.guest_btn a{
    padding: 8px 15px;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 1;
    font-family: 'poppinsbold';
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}

.guest_btn a:hover{
    background: #333;
    color: #fff;
}

.or-seperator {
    position: relative;
    padding: 25px 0 20px;
    line-height: 1;
    margin: 0 auto;
    text-align: center;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator span {
    padding: 0 10px;
    background: #f6f6f6;
    display: inline-block;
    z-index: 1;
    position: relative;
    font-family: 'poppinssemibold';
}

.or-seperator:after {
    height: 1.2px;
    width: 100%;
    content: "";
    display: block;
    background: #e1e1e1;
    position: absolute;
    top: 52%;
    left: 0;
}

/* .seperator-only:after {
    display: none;
} */

.login_pop_sub .btn, .signup_pop_btn .button {
    padding: 15px 10px;
    width: 100%;
    margin: 0;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    font-family: 'poppinsmedium';
}

.alt_btns a{
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
}

.login_pop_sub .guestcheckout_submit{
    min-width: 170px;
    margin: 15px 0 0;
}

.two-links {
    padding: 10px 0 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.controls.two-links {
    justify-content: center;
}

.two-links a {
    font-size: 14px;
    display: block;
    position: relative;
    color: #1d1d1d;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    font-family: "Poppins",sans-serif;
    text-transform: uppercase;
    text-align: center;
    padding-left: 3px;
}

.controls.two-links a {
    color: #1d1d1d;
    text-transform: uppercase;
    font-size: 12px;
    display: block;
    font-family: 'poppinssemibold';
}

.controls.two-links a:hover {
    color: #e2b014;
}

.spilter {
    width: 2px;
    height: 20px;
    background: #d4d4d4;
    display: inline-block;
    content: "";
    vertical-align: top;
    margin: 1px 2px 0;
    position: relative;
    left: 0;
}

span.spilter {
    width: 2px;
    height: 15px;
    display: block;
    margin: 0 9px;
    background: #d3d4d4;
}

.white-popup.processing {
    max-width: 768px;
}

.process_inner {
    position: relative;
}

.process_col {
    position: relative;
    margin-bottom: 15px;
}

.process_left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.process_right {
    padding-left: 60px;
}

.process_right h5 {
    color: #b0aeae;
    margin-bottom: 5px;
}

.process_right p {
    margin-bottom: 0;
    font-size: 18px;
}

.location-part-outer {
    padding: 0px !important;
    position: relative;
    overflow: hidden;
}

.location-part iframe {
    width: 100%;
    height: 680px;
}

.location-sidemenu {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 320px;
    height: 100%;
    z-index: 8;
    width: 100%;
}

.location-sidemenu .red-bar {
    background: #db4337;
    padding: 7px 40px;
}

.location-sidemenu .three-lines {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    width: 18px;
    height: 12px;
    background: url(../images/spirite_whole.png) no-repeat scroll -122px -191px transparent;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    left: 15px;
    top: 18px;
}

.location-sidemenu .three-lines:hover {
    background-position: -156px -191px;
}

.location-sidemenu .serch-box form {
    position: relative;
}

.location-sidemenu .serch-box input[type="text"] {
    margin: 0;
    height: 35px;
    border: none;
    padding: 7px 35px 7px 15px;
    background: transparent;
    color: #fff;
    outline: 0;
    font-weight: 700;
}

.location-sidemenu .serch-box input[type="text"]::-webkit-input-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]::-moz-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-ms-input-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-moz-placeholder {
    color: #fff;
}

.loc-cont-part {
    position: absolute;
    width: 100%;
    left: 0;
    background: #fff;
}

.location-sidemenu .serch-box input[type="submit"] {
    position: absolute;
    font-size: 0;
    right: 0;
    top: 0;
    margin: 0;
    width: 35px;
    height: 35px;
    padding: 5px;
    min-width: inherit;
    border: none;
    border-radius: 0;
    background: url(../images/search-icon.png) no-repeat scroll center center transparent;
}

.location-sidemenu .three-dots {
    width: 19px;
    height: 19px;
    background: url(../images/spirite_whole.png) no-repeat scroll -193px -191px transparent;
    display: inline-block;
    position: absolute;
    right: 13px;
    top: 16px;
    cursor: pointer;
}

.outlet-list {
    max-height: 551px;
    overflow-y: auto;
}

.outlet-list > ul {
    padding: 20px 15px;
}

.outlet-list > ul > li {
    margin-bottom: 20px;
}

.outlet-list li {
    list-style: none;
    padding-left: 38px;
}

.outlet-list li span {
    display: block;
    font-weight: 700;
}

.outlet-list li .submenu {
    padding: 0;
}

.outlet-list li .submenu li {
    margin-top: 10px;
    list-style: none;
    padding: 0;
    line-height: normal;
    padding-left: 3px;
}

.outlet-list li .submenu li a {
    font-weight: 700;
    display: block;
    color: #272626;
    position: relative;
    padding-left: 14px;
    font-size: 11px;
}

.outlet-list li .submenu li a:hover {
    color: #ecdfcc
}

.outlet-list li .submenu li a:before {
    position: absolute;
    left: 0;
    top: 0px;
    content: url(../images/location_small.png);
}

.outlet-list .custom_checkbox {
    position: absolute;
    left: 0;
    font-size: 0;
}

.outlet-list .custom_checkbox span:before {
    width: 16px;
    height: 17px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 1px solid #e6e6e6;
}

.outlet-list .custom_checkbox input[type=checkbox]:checked + span:before {
    background-position: -216px -189px;
}

.outlet-list li .submenu li .mapcanvas_close {
    position: absolute;
    padding: 0;
}

.outlet-list li .submenu li .mapcanvas_close:before {
    display: none;
}

.refer_popup{
    border-radius: 10px;
}

.refer_popup_image{
    height: 400px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}

.refer_popup_image img{
    width: 100%;
    height: 100%;
}

#refer-popup.refer_popup .mfp-close{
    background: #000000;
}

/******************** MYACCOUNT START ********************/

.innersection_wrap {
    padding: 40px 0;
}

.acc-detail-inner{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.accmenu_sidebar{
    position: -webkit-sticky;
    position: sticky;
    top: 95px;
    width: 220px;
    display: inline-block;
}

.accmenu_list ul{
    padding: 0;
}

.accmenu_list ul li {
    position: relative;
    width: 100%;
}

.accmenu_list ul li.active a, .accmenu_list ul li a:hover{
    background: #0d0b06;
    color: #fff;
}

.accmenu_list ul li a {
    text-transform: capitalize;
    padding: 12px 10px 12px 20px;
    display: block;
    font-family: 'poppinsmedium';
    color: #000;
    background: #f4f3f3;
}

.count_num_t {
    background: #0d0b06;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    margin-left: 5px;
    font-size: 11px;
    line-height: 21px;
    vertical-align: top;
    margin-top: 1px;
    padding: 0 4px;
    min-width: 20px;
    font-family: 'poppinsmedium';
}

.accmenu_list ul li.active .count_num_t, .accmenu_list ul li:hover .count_num_t {
    background: #fff;
    color: #000;
}

.myaccsec_right, .myordersec_right, .rewardsec_right, .promotionsec_right, .vouchersec_right, .refersec_right {
    width: calc(100% - 220px);
    width: -webkit-calc(100% - 220px);
    display: inline-block;
    box-shadow: 0 3px 28px 0 rgb(1 1 1 / 14%);
    padding: 0;
}

.myacc_right_inner{
    display: flex;
    flex-flow: column;
}

.myacc_right_header, .membership_rewards{
    background: url(../images/myaccount-bg.png) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 40px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 6%);
}

.welcome-title {
     width: calc(100% - 134px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.myacc_header_inner {
    width: 100%;
}

.myacc_right_h-lhs{
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.myacc_rt_header-rhs{
    width: 30%;
}

.maccont_memberimagediv{
    width: 124px;
}

.myacc-points{
    width: 50%;
    text-align: center;
}

.myacc-points p{
    font-size: 12px;
    font-family: 'poppinsbold';
    margin: 0px;
    text-transform: uppercase;
}

.myacc-points h3{
    background: #282728;
    color: #f0e750;
    margin: 0 0 5px;
    border-radius: 50px;
    border: 2px solid #f0e750;
    font-size: 28px;
    padding: 4px 40px;
    display: inline-block;
}

.myacc-points .points-expre{
    font-family: 'poppinssemibold';
    text-transform: inherit;
    font-size: 12px;
    font-style: italic;
}

.member-prog-bar {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
}

.member-progress-2 {
    margin: 15px auto 8px;
    padding: 0;
    width: 100%;
    height: 9px;
    overflow: hidden;
    background: #292927;
    border-radius: 15px;
}

.member-bar-2 {
    position: relative;
    float: left;
    min-width: 1%;
    height: 100%;
    background: #e36321;
    border-radius: 15px;
}

.member-bar-width-2 {
    width: 55%;
}

.member-help-block {
    text-align: center;
    font-size: 12px;
    color: #000;
    line-height: 1;
    font-family: 'poppinsmedium';
}

.member-help-block span{
    font-family: 'poppinssemibold';
}

.welcome-title h1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 26px;
    font-family: 'poppinssemibold';
    width: 50%;
    word-break: break-word;
}

.welcome-title h1>div {
    text-align: center;
    margin: 0 0 5px;
}

.welcome-title h1>div span {
    color: #000000;
    font-family: 'poppinsregular';
    font-size: 16px;
    text-transform: capitalize;
}

.welcome-title h1>img {
    max-height: 42px;
    margin: 0 0 0 15px;
}

.member-prog-bar a {
    font-family: 'poppinsmedium';
    border-bottom: 1px solid #000;
    font-size: 14px;
}

.member-prog-bar a:hover{
    border-bottom: 1px solid #e2b014;
    color: #e2b014;
}

.welcome-title a {
    font-family: 'poppinssemibold';
    border-bottom: 1px solid #000;
    font-size: 14px;
}

.myacc_rt_header-rhs a :hover {
    color: #e2b014;
    border-bottom: 1px solid #e2b014;
}

.accdetails-info{
    padding: 20px 20px 40px;
}

.acc-inform .form-group {
    margin-bottom: 11px;
}

.profile-info-div .row, .address-info-div .row {
    margin: 0;
}

.acc-inform .col-md-6 {
    width: 49%;
    float: left;
    margin: 0 2% 0 0;
    padding: 0;
}

.acc-inform .col-md-6:last-of-type {
    margin-right: 0;
}

.profile-info-div .form-group input, .address-info-div input, .popup-body input{
    border-radius: 0;
}

.css-1pcexqc-container {
    z-index: 4;
}

.css-1pcexqc-container div {
    border-radius: 0;
}

.acc-inform .form-group .button {
    width: 100%;
    display: block;
    margin: 0;
    height: 48px;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
}

.other-add {
    margin: 30px 0 0 0
}

.other-add-body {
    height: 175px;
    border: 1px solid #f1efef;
    padding: 16px 14px 0;
    background: #fff;
}

.other-add-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 0;
}

.other-add-row li {
    width: 32.1875%;
    margin-right: 1.6666666666666665%;
    position: relative;
    margin-bottom: 10px;
}

.other-add-row li:nth-of-type(3n+3) {
    margin-right: 0;
}

.other-add-row li a {
    background: #f9f9f9;
    padding: 15px;
    display: block;
    border: 1px solid #f1efef;
    color: #000000;
    font-size: 13px;
    border-radius: 7px;
    cursor: default;
}

.other-add-row span {
    background: #b0b0b0;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.other-add-row span:after, .other-add-row span:before {
    position: absolute;
    content: "";
    top: 3.5px;
    right: 6.5px;
    height: 10px;
    width: 1.9px;
    background: #fff;
}

.other-add-row span:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.other-add-row span:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.other-add-row span:hover{
    background: #000000;
}

.myorder_mainsec, .myorder_sec_inner, .reward_mainsec_div, .reward_sec_inner, .promotion_mainsec_div , 
.promotionsec_inner, .voucher_mainsec_div, .vouchersec_inner, .refer_mainsec_div, .refersec_inner {
    display: flex;
}

.myorder_sec_inner_right, .reward_sec_inner_right, .promotion_boxfull, .voucher_boxfull, .refer_boxfull{
    width: 100%;
    padding: 40px;
}

.filter_tabsec .nav-tabs {
    width: 100%;
    padding: 0; 
    border-bottom: 4px solid #f0f0f0;
}

.filter_tabsec .nav-tabs>li, .vouchergift_ul li {
    position: relative;
    float: left;
    width: 50%;
}

.filter_tabsec .nav-tabs>li>a span, .vouchergift_popup_body .vouchergift_ul li a span {
    display: inline;
}

.myacc_filter .nav-tabs>li>a, .vouchergift_popup_body .vouchergift_ul li a{
    font-family: 'poppinssemibold';
    text-transform: uppercase;
    background: #ffffff;
}

.myacc_filter .nav-tabs>li>a, .myacc_filter .nav-tabs>li.active>a, .myacc_filter .nav-tabs>li>a:hover{
    border: 0;
}

.vouchergift_popup_body .vouchergift_ul li a{
    background: #f6f6f6;
}

.myacc_filter .nav-tabs>li.active>a, .myacc_filter .nav-tabs>li>a:hover, .nav-tabs>li.active>a:focus, 
.nav-tabs>li.active>a:hover, .vouchergift_popup_body .vouchergift_ul li.active a,
.vouchergift_popup_body .vouchergift_ul li.active a:hover {
    background: #fff;
    border-bottom-color: transparent;
    text-transform: uppercase;
    color: #000;
    font-family: 'poppinssemibold';
}

.vouchergift_popup_body .vouchergift_ul li.active a, .vouchergift_popup_body .vouchergift_ul li a:hover,
.vouchergift_popup_body .vouchergift_ul li.active a:hover {
    background: transparent;
    border-color: transparent;
}

.myacc_filter .nav.nav-tabs.text-center>li:before, .vouchergift_popup_body .vouchergift_ul li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -3px;
    left: 0;
    background: #000;
    margin-bottom: -1px;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    transition: .25s linear;
}


.vouchergift_popup_body .vouchergift_ul{
    border-bottom: 5px solid #ddd;
    margin: 0 0 30px;
}

.myacc_filter .nav.nav-tabs.text-center>li.active:before, .nav.nav-tabs.text-center>li:hover:before,
.vouchergift_popup_body .vouchergift_ul li.active:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.myvouchar-forgift-div h2{
    font-size: 24px;
    font-family: 'poppinssemibold';
}

.howtouse-gift-vouchar{
    margin: 30px 0 0;
}

.howtouse-gift-vouchar h3{
    font-size: 22px;
    margin: 10px 0 20px;
}

.gift-group .button{
    display: block;
    width: 100%;
    margin: 30px 0 0;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    font-family: 'poppinsmedium';
}

.tab_mobtrigger{
    margin: 20px 0;
}

.myorder_alldetails, .myreward_order_details, .myvoacher_alldetails, .promotionsused-div{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.myorder-crnt_div{
    width: 48.5%;
    margin-bottom: 20px;
    background: #f9f9f9;
    padding: 0 0 150px;
    position: relative;
}

.myacc_head_sec {
    background: #f1f1f1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding: 15px;
    overflow: hidden;
}

.head_left h4 {
    color: #000;
    margin: 0;
    font-size: 14px;
}

.head_right:before {
    position: absolute;
    content: "";
    top: 0;
    right: -30px;
    background: #000;
    width: 40%;
    height: 100%;
    -webkit-transform: skew(-39deg);
    transform: skew(-39deg);
    z-index: 1;
    display: block;
}

.head_right .head-group {
    z-index: 2;
    position: relative;
}

.head_right h4 {
    color: #fff;
    margin: 0;
    text-transform: capitalize;
    font-size: 14px;
}

.order_details_body {
    padding: 15px;
}

.order_no_deatails {
    font-size: 14px;
    margin: 0 0 20px;
    text-align: center;
    font-family: 'poppinsmedium';
}

.order_no_deatails span {
    display: block;
    font-size: 17px;
}

.myorder_delivery_total, .myorder_delivery_date{
    position: relative;
    display: flex;
}

.myorder_delivery_total:before {
    position: absolute;
    left: 50px;
    right: 30px;
    background: #fff;
    top: 10px;
    content: "";
    margin: auto;
    border: 2px dashed #dfdad7;
}

.myorder_delivery_left, .myorder_delivery_date_left{
    width: 50%;
    text-align: left;
}

.myorder_delivery_left img {
    margin: 0 0 10px;
    max-height: 33px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.myorder_delivery_left h3, .myorder_delivery_right h3 {
    margin-bottom: 0;
    line-height: 22px;
    font-size: 15px;
    font-family: 'poppinssemibold';
}

.myorder_delivery_left span, .myorder_delivery_right span {
    display: block;
    color: #000;
    line-height: 17px;
    font-size: 13px;
}

.myorder_delivery_right, .myorder_delivery_date_right{
    width: 50%;
    text-align: right;
}

.myorder_delivery_right img {
    margin: 0 0 20px;
    max-height: 33px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.myorder_delivery_date{
    margin: 20px 0 30px;
}

.myorder_delivery_date_left h2, .myorder_delivery_date_right h2 {
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-family: 'poppinssemibold';
}

.myorder_delivery_date_left h4, .myorder_delivery_date_right h4{
    color: #000000;
    font-size: 15px;
    margin-block: 0;
    font-family: 'poppinssemibold';
}

.myorder_footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.myorder_amtop{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    background: #f6f6f6;
    padding: 16px 15px;
}

.myorder_amt-left h3, .myorder_amt_right h3 {
    margin: 0;
    color: #000;
    font-size: 20px;
    font-family: 'poppinssemibold';
    text-transform: uppercase;
}

.myorder_btns{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 16px 8px 8px;
}

.myorder_btns .print_invoice, .myorder_btns .view_recipt {
    margin: 0;
    width: 49%;
}

.myorder_btns .print_invoice {
    background: #fff;
    border: 1px solid #000;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
}

.myorder_btns .print_invoice:hover{
    background: #000;
    border: 1px solid #000;
    color: #fff;
}

.myorder_btns .view_recipt{
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
}

.myorder_btns .button {
    color: #000;
    display: block;
    padding: 15px 6px 12px;
    box-shadow: none;
    border-radius: 10px;
    font-family: 'poppinsmedium';
}

.no-recrds-found {
    margin: 0;
    min-height: 270px;
    width: 100%;
    font-size: 20px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'poppinsregular';
}

.reward_sec_inner{
    flex-flow: column;
}

/* .membership_rewards {
    justify-content: center;
} */

.rewards-title {
    width: calc(100% - 380px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.rewards-title h4 {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 4px;
    margin-bottom: 10px;
    font-size: 18px;
}

.rewards-title h3 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px;
    font-size: 28px;
    color: #fbd008;
}

.rewards-title a{
    font-family: 'poppinsmedium';
    border-bottom: 1px solid #000;
    font-size: 14px;
}

.expiry_on_lhs{
    display: flex;
    justify-content: space-between;
}

.reward_checkoutDate, .reward_checkoutTime{
    position: relative;
    font-size: 16px;
    color: #000000;
    margin: 0;
    padding: 0 0 0 25px;
}

.reward_checkoutDate:before, .reward_checkoutTime:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    content: "";
}

.reward_checkoutDate:before {
    background: url(../images/calenderorder.svg) no-repeat;
}

.reward_checkoutTime:before {
    background: url(../images/clock-round.svg) no-repeat;
}

.reward_expiry_date{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 10px 0 0;
}

.reward_amt_tdiv{
    display: flex;
    justify-content: space-between;
}

.reward_amt_tdiv h3, .reward_expiry_date h4 {
    text-transform: uppercase;
    font-size: 17px;
    margin: 15px 0 0;
}

.promotion_fullwid, .voucher_fullwid, .refer_fullwid{
    width: 100%;
}

.promo-form, .congrats-inner{
    text-align: center;
    padding: 30px 15px;
    background: #f6f6f6;
    margin: 0 0 30px;
}

.promo-form h3{
    color: #000;
    font-size: 34px;
    margin: 0 0 15px;
    text-transform: uppercase;
    font-family: 'poppinssemibold';
}

.promo-form h3 span {
    font-size: 30px;
    display: block;
    font-family: 'poppinsregular';
}

.promotion-top-form {
    position: relative;
    max-width: 640px;
    margin: 0 auto;
}

.promotion-top-form input[type=text] {
    margin: 0;
    height: 47px;
    padding: 5px 150px 5px 20px;
    background: #fff;
    font-size: 14px;
    color: #615f5f;
}

.promotion-top-form .applyBtn {
    position: absolute;
    top: 0;
    right: 0;
    height: 47px;
    min-width: 146px;
    margin: 0;
    padding: 13px 20px;
    font-size: 16px;
    font-family: 'poppinsmedium';
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    border: none;
    border-radius: 6px;
}

.promo-earned-col {
    width: 49%;
    background: #fff;
    margin: 0 0 20px;
}

.promo-earned-col-item {
    display: flex;
    background: #fff;
    box-shadow: 0 1px 9px 0 rgb(0 0 0 / 14%);
    height: 100%;
}

.myvoacher_alldetails .promo-earned-col-image{
    height: 202px;
}

.promo-earned-col-image {
    position: relative;
    height: 185px;
    width: 50%;
}

.promo-earned-col-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.promo-earned-info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    width: 50%;
    justify-content: center;
    text-align: center;
    padding: 20px 10px;
    position: relative;
    z-index: 1;
}

.promo-earned-info:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: -40px;
    bottom: 0;
    background: #fff;
    width: 80px;
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
}

.promo-earned-top {
    width: 100%;
}

.promo-earned-code {
    font-size: 14px;
    margin: 0 0 5px;
}

.promo-earned-code span {
    display: block;
    font-size: 22px;
    line-height: 1.4;
}

.promo-description, .vouchar-txt {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  -webkit-line-clamp: 1;
  font-size: 15px;
  margin: 0 0 10px;
}

.promo-valid {
    color: #a6a6a6;
    font-size: 17px;
    padding: 0 0 5px;
    display: block;
}

.promation_btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.promation_btn .button {
    margin: 0;
    box-shadow: none;
    width: 49%;
    display: block;
    padding: 10px 4px;
    font-size: 13px;
    font-family: 'poppinsmedium';
    border: 1px solid transparent;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
}

.promation_btn .promation_btn-one {
    background: #fff;
    border: 1px solid #000;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
}

.promation_btn .promation_btn-one:hover{
    background: #000;
    color: #fff;
}

.receipt_popup.redeem_popsec {
    max-width: 600px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 15px rgb(0 0 0 / 34%);
    background: #fff;
    margin: 0 auto;
    position: relative;
}

.redeem_bansec {
    position: relative;
    overflow: hidden;
}

.promo-popup-image {
    height: 400px;
}

.promo-popup-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.promo-earned-content, .voucher-contentmain {
    padding: 30px 20px;
    text-align: center;
}

.promo-earned-content h4 {
    font-size: 24px;
    margin: 0 0 10px;
}

.promo-earned-content p {
    margin-bottom: 10px;
}

.redeem_popsec .button {
    display: block;
    padding: 16px 28px;
    margin: 20px auto 20px;
    width: 50%;
    border-radius: 4px;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
}

#receipt-popup.redeem_popsec .mfp-close {
    background: rgba(1,1,1,.9);
    border-radius: 5px;
    transition: all .5s ease;
    top: 10px!important;
    right: 10px!important;
}

.congrats-inner{
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.congrats-inner h3 {
    color: #000;
    font-size: 24px;
    margin: 0 0 5px;
    text-transform: uppercase;
    font-family: 'poppinsmedium';
}

.congrats-inner h2{
    font-family: 'poppinssemibold';
    text-transform: uppercase;
    margin: 0;
}

.refersec_inner{
    width: 100%;
}

.refer-friend-bottom-content {
    text-align: center;
}

.refer-friend-reward-section {
    text-align: center;
    margin: 25px 40px;
    padding: 28px 15px;
}

.refer-friend-reward-section h2 {
    color: #000000;
    font-family: 'poppinssemibold';
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 34px;
}

.refer-friend-reward-section h4 {
    color: #000000;
    font-family: 'poppinsmedium';
    margin: 0 0 15px;
    text-transform: capitalize;
}

.refer-friend-reward-section p {
    color: #949292;
}

.refer-btn .button {
    display: block;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    font-family: 'poppinsmedium';
    letter-spacing: .5px;
    margin: 0 auto;
    max-width: 180px;
    white-space: nowrap;
}

.changepw_popup {
    max-width: 768px;
    padding: 0;
    margin: 30px auto;
    position: relative;
    background: #FFF;
    width: auto;
}

.pouup_in {
    padding: 60px 50px 40px;
}

.pouup_in h3{
    text-transform: uppercase;
}

.change_pswddiv .chg_pswd{
    width: 100%;
    padding: 16px 26px;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
}

.change-pass-sbmt .button{
    width: 100%;
    padding: 16px 28px 16px;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
}

.vouchar_btn {
    width: 100%;
    display: flex;
    justify-content: center;
}

.vouchar_btn .button, .vouvherredmd_btn .button {
    margin: 0;
    box-shadow: none;
    padding: 10px 12px;
    font-size: 13px;
    font-family: 'poppinsmedium';
    border: 1px solid transparent;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
}

.vouchar-popup{
    border-radius: 15px;
}

.voucher-popup-image{
    height: 350px;
}

.voucher-popup-image img{
    width: 100%;
    height: 100%;
    border-radius: 20px 20px 0 0;
}

.voucher-contentmain .addcart_row{
    margin: 0 0 20px;
}

.voucher-contentmain .btn {
    display: block;
    padding: 15px 6px 12px;
    box-shadow: none;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
}

.main_tabsec_inner> .nav-tabs>li {
    width: 33.17%;
    margin-right: .24154589371980675%;
    position: relative;
}

.main_tabsec_inner> .nav-tabs>li:last-child {
    margin-right: 0;
}

.main_tabsec_inner> .nav-tabs {
    text-align: center;
}

.main_tabsec_inner> .nav-tabs>li> a {
    background: #bfbebe;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 91px 17.5px 15px;
}

.main_tabsec_inner> .nav-tabs>li.active>a {
    border: 0;
    background: #0b0405;
    color: #fff;
}

.main_tabsec_inner> .nav-tabs>li> a > span > b {
    font-weight: normal;
}

.tab-pane {
    display: none
}

.tab-pane.active {
    display: block
}

.ord-body::after {
    display: block;
    clear: both;
    content: "";
}

.cur-order-body, .pst-order-body, .reward-cur-order-body, .mypromo-main-div, .voacher-main-div {
    width: 100%;
    clear: both;
}

.cur-order-body::after {
    display: block;
    clear: both;
    content: "";
}

.cur-order {
    padding: 10px 0;
    border-bottom: 1px solid #efefee;
    position: relative;
}

.click_view {
    position: absolute;
    right: 21px;
    color: #474747;
    padding: 0;
    font-weight: 700;
    font-size: 12px;
}

.cur-order-del {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 450px;
    list-style: none;
    padding-left: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.cur-order-del li {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    padding-left: 20px;
}

.cur-order-del li a.btn {
    min-width: 132px;
    height: 35px;
    border-radius: 10px;
    font-size: 16px;
    padding: 8px 5px;
    color: #0b0405;
    line-height: normal;
    margin-bottom: 0;
}

.cur-order-del li a.btn:hover {
    color: #fff;
}

.cur-order-del li span {
    padding-left: 5px;
}

.cur-order-del-icon {
    background: url(../images/spirite_whole.png) no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
}

.calender-icon {
    background-position: -215px -258px;
}

.time-icon {
    background-position: -249px -257px;
}

.cur-ord-rec {
    list-style: none;
    float: right;
    max-width: 400px;
    padding-right: 19px;
    margin-bottom: 0;
    padding: 8px 19px 0 0;
}

.cur-ord-rec li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.cur-ord-rec li a {
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: underline;
    color: #2e2e2c;
}

.cur-ord-rec li a:hover {
    color: #909090;
}

.cur-ord-rec li span {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: #2e2e2c;
    margin: 0 20px;
    position: relative;
}

.cur-ord-rec li span::after {
    content: "";
    background: #2e2e2c;
    height: 14px;
    width: 1px;
    display: block;
    position: absolute;
    top: 2px;
}

.order-cat-hea {
    background: #fff;
    padding: 8px 35px;
    max-width: 331px;
    margin-bottom: 10px;
}

.order-cat h2 {
    font-size: 20px;
    color: #0b0405;
    margin-bottom: 0;
}

.order-cat-table {
    padding: 0 35px;
}

.order-cat-table h4 {
    color: #474747;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 11px;
}

.order-cat-table table {
    border: 0;
    max-width: 364px;
    margin-bottom: 10px;
}

.order-cat-table table td {
    font-weight: 300;
    font-size: 16px;
    color: #3d3d3d;
    padding: 0 5px;
}

.order-cat-table table td.p_name {
    padding-left: 0;
}

.p_name {
    width: 247px;
}

.p_val {
    width: 80px;
}

.ord-amount {
    padding: 10px 0 0 35px;
    border-top: 1px solid #efefee;
}

.ord-amount .row {
    margin-right: -20px;
}

.ord-amount-item {
    max-width: 364px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ord-amount h2 {
    font-size: 27px;
    color: #202020;
}

.ord-rec-sec {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: right;
    float: right;
    width: 649px;
}

.ord-rec-sec li {
    color: #aba9a9;
    margin-bottom: 0;
    height: 39px;
    padding: 10px 15px;
    max-width: 184px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    width: 25%;
    line-height: 23px;
    overflow: hidden;
}

.ord-rec-sec li.completed {
    background: #ecdfcc;
    color: #3d3d3d;
}

.ord-number {
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    font-weight: 700;
    margin-right: 6px;
}

.ord-rec-sec li.completed .ord-number {
    color: #fff;
    background: #0b0405;
}

.rewards-tab-section .main_tabsec_inner {
    padding: 6px 72px 50px 72px;
}

.rewards-tab-section .ord-amount {
    border-top: inherit;
    padding-top: 0;
}

.rewards-tab-section .order-cat-hea {
    margin-bottom: 7px;
}

.cart_row.promo-cart-row {
    position: relative;
}

.combo-input-label {
    height: 100%;
    border: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    margin: 0;
    background: #060203;
    border-radius: 5px;
    font-family: 'poppinsbold';
    padding: 11px 2px;
    line-height: 12px;
}

.compo_acc_innerdiv {
    padding: 10px 10px 0px 20px;
}

.gloading_img {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 9;
}

.gloading_img:after {
    content: "";
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 1s linear infinite;
    animation: loader-rotate 1s linear infinite;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.load_warpper {
    position: relative;
}

.load_warpper #dvLoading {
    position: absolute;
}

#dvLoading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: #fff url(../images/loading.gif) no-repeat center;
    background-size: 60px;
    -webkit-background-size: 60px;
    bottom: 0;
    right: 0;
}

#inner_loading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.9) url(../images/loading_inner.gif) no-repeat center;
    bottom: 0;
    right: 0;
}

.popup_close {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #fff;
    opacity: 1;
    padding: 10px;
    border-radius: 5px;
    width: 40px;
    z-index: 1
}

.back_arrow {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    width: 43px;
    height: 49px;
    text-align: center;
    line-height: 49px;
    cursor: pointer;
    z-index: 4;
}

.back_arrow:after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 26px;
    height: 26px;
    background-position: -160px -16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.react-datepicker-popper {
    z-index: 11 !important;
}

.scrollup {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 50px;
    right: 15px;
    background: #523429;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    cursor: pointer;
    z-index: 111;
}

.scrollup:hover {
    background: #000;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.scrollup:hover .fa {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.scrollup .fa {
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
}

.contact_left p a {
    color: inherit;
}

.contact_left p a:hover {
    color: #b69a6b;
}

.contact_left p.open-hours {
    border-top: 1px solid #eaeaea;
    padding-top: 25px;
    margin-top: 26px;
}

.contactus_pg .contact_form h3 {
    margin: 0 0 5px;
}

.contact_form .help-block {
    margin: 0 0 24px;
}

.product_tag {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 5px 0 10px;
}

.product_tag li {
    padding-right: 10px;
}

.product_tag li b {
    margin-right: 10px;
}

.product_tag li a {
    display: inline-block;
    color: #5b5b5b;
    font-weight: 700;
}

.catogry_row_fixed .bakery_slider {
    position: inherit; 
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    margin: auto;
    text-align: center;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    max-width: 1260px;
    padding-top: 0 !important;
}

.tbars .bakery_row_fixed {
    top: 171px;
}

.bakery_row.bakery_row_fixed .slick-next {
    right: 0;
}

.chosen_file {
    position: relative;
    display: inline-block;
}

.chosen_file input[type="file"] {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.chosen_file label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
    margin: 0;
    pointer-events: none;
}

.receipt_popup, .recip_popup {
    max-width: 620px;
    padding: 0;
    margin: 30px auto;
    position: relative;
    background: #FFF;
    width: auto;
}

.receipt_header, .reward_header {
    text-align: center;
}

.receipt_header_box, .reward_header_box{
    background: #f5f5f5;
    padding: 30px 15px;
}

.receipt_header_box h3, .reward_header_box h3, .tnkyou-header h2 {
    color: #000000;
    font-family: 'poppinssemibold';
    margin-bottom: 10px;
    text-transform: uppercase;
}

.receipt_header_box p, .reward_header_box p {
    color: #292929;
    font-family: 'poppinssemibold';
    margin: 0;
}

.receipt_header h5, .reward_header h5 {
    color: #fff;
    background: #060203;
    font-size: 17px;
    font-family: 'poppinssemibold';
    text-transform: uppercase;
    padding: 13px 10px;
    margin: 0;
}

.order-dely-body-recpt, .reward-dely-body-recpt{
    padding: 30px 15px;
}

.order-dely-top-div, .thank-detailsinner-top {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 6px 0 20px;
}

.order-dely-top-div:before {
    position: absolute;
    left: 50px;
    right: 30px;
    background: #fff;
    top: 10px;
    content: "";
    margin: auto;
    border: 2px dashed #dfdad7;
}

.order-dely-top-left, .order-dely-middle-left, .thank-detailin-top-left, .thank-details-middle-left{
    text-align: left;
    width: 50%;
}

.order-dely-top-left img, .order-dely-top-right img {
    margin: 0 0 10px;
    max-height: 33px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.order-dely-top-left h3, .order-dely-top-right h3, .thank-detailin-top-left h3, .thank-detailin-top-right h3 {
    margin: 0;
    line-height: 1.5;
    font-size: 15px;
    font-family: 'poppinssemibold';
    text-transform: uppercase;
}

.order-dely-top-left span, .order-dely-top-right span, .thank-detailin-top-left p, .thank-detailin-top-right p{
    display: block;
    color: #000;
    line-height: 1.2;
    font-size: 13px;
    margin: 0;
}

.order-dely-top-right, .order-dely-middle-right, .thank-detailin-top-right, .thank-details-middle-right {
    text-align: right;
    width: 50%;
}

.order-dely-top-right img{
    margin: 0 0 20px;
}

.order-dely-middle-div, .thank-details-middle {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e4e4e4;
    padding: 10px 0 15px;
    margin: 20px 0;
}

.order-dely-middle-left h2, .order-dely-middle-right h2, .thank-details-middle-left h2, 
.thank-details-middle-right h2 {
    font-size: 15px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-family: 'poppinssemibold';
}

.order-dely-middle-left h4, .order-dely-middle-right h4, .thank-details-middle-left h4, 
.thank-details-middle-right h4 {
    color: #000000;
    font-size: 14px;
    margin: 0;
    font-family: 'poppinssemibold';
}

.order-dely-bottom-innerdiv {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 10px 0;
}

.order-dely-bottom-left {
    width: calc(100% - 150px);
}

.order-dely-bottom-product h4{
    font-size: 14px;
    margin: 0 0 5px;
    text-transform: uppercase;
    font-family: 'poppinssemibold';
}

.order-dely-bottom-right {
    width: 150px;
    text-align: right;
}

.order-dely-bottom-product-price{
    position: relative;
    margin: 0 0 10px;
    line-height: 1;
}

.order-dely-bottom-product-price p{
    font-size: 14px;
    font-family: 'poppinssemibold';
}

.order-dely-footer{
    padding: 20px 0 0;
}

.order-dely-footer .cart_row, .tnk_cart_footer .cart_row{
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -webkit-box-pack: justify;
    margin-bottom: 5px;
    font-family: 'poppinssemibold';
}

.order-dely-footer .cart_row p, .tnk_cart_footer .cart_row p{
    margin: 0;
}

.order-dely-footer .grant-total-div{
    font-family: 'poppinsbold';
    font-size: 24px;
    padding: 10px 0;
    border-bottom: 3px solid #f4f4f5;
}

.order_nav {
    position: relative;
}

.order_nav > .nav-tabs:before {
    content: '';
    background: #dddcdc;
    width: 100%;
    height: 50px;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.order_nav > .nav-tabs {
    position: relative;
    border: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1
}

.order_nav > .nav-tabs>li {
    margin: 0;
    text-align: center;
    border-left: 2px solid transparent;
}

.order_nav > .nav-tabs>li.active>a, .order_nav > .nav-tabs>li.active>a:focus,
.order_nav > .nav-tabs>li.active>a:hover {
    border: 0;
    background: #e2b014;
    color: #fff;
}

.order_nav > .nav-tabs>li>a {
    background: #fff;
    margin: 0px 1px;
    border-radius: 0;
    border: 0;
    color: #181818;
    font-size: 18px;
    text-transform: uppercase;
    padding: 15px 23px;
    font-family: 'poppinsregular';
    display: block
}

.nos {
    background: #473f3c;
    border-radius: 100%;
    padding: 2px 2px;
    margin-right: 7px;
    color: #fff;
    border: 2px solid #0b0405;
    width: 32px;
    height: 32px;
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
    line-height: 24px;
}

.order_nav .tb-padding {
    padding: 60px 0;
}

.container_720 {
    margin: 0 auto;
    max-width: 720px;
}

.choose_location {
    position: relative;
    border: 2px solid #cfbfaa;
    border-radius: 5px;
    padding: 50px 40px 30px;
    margin-bottom: 50px;
    background: #d2be9b;
    background: rgba(210, 190, 155, 0.38)
}

.chosen-alert-cnt {
    position: absolute;
    bottom: -27px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}

.chosen-alert-cnt span {
    background: #fb0a37;
    padding: 6px 15px;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    box-shadow: 0px 3px 4px rgba(1, 1, 1, 0.17);
    -webkit-box-shadow: 0px 3px 4px rgba(1, 1, 1, 0.17);
}

.choose_location h4 {
    color: #3d3d3d;
    font-size: 19px;
    font-family: 'poppinsbold';
    margin: 0 0 5px 0
}

.choose_location .custom_radio span {
    color: #3d3d3d;
    font-size: 18px;
    line-height: 35px;
    padding: 0 0 0 42px;
    font-family: 'poppinsbold';
}

.choose_location .custom_radio span:before {
    width: 32px;
    height: 32px;
}

.divide {
    width: 90%;
    background: #c7c3c3;
    height: 2px;
    margin: 10px 0px 10px 40px;
}

.date_list {
    margin-bottom: 30px;
    position: relative;
    padding: 40px 120px 30px;
    border: 2px solid #cfbfaa;
    background: #d2be9b;
    background: rgba(210, 190, 155, 0.38);
    border-radius: 6px;
}

.date_list:after {
    content: "";
    display: table;
    clear: both;
}

.datelist_bracktime h3, h3.choose-eventtype {
    text-align: center;
    font-size: 26px;
    margin: 0 0 20px 0;
}

.ordering-more {
    text-align: center
}

.ordering-more .button.no {
    background: #626161;
    width: 140px;
    margin: 0
}

.ordering-more .button.yes {
    background: #000000;
    width: 140px;
    margin: 0
}

#nobtn_id {
    margin-right: 5px
}

.ordering-more .button.yes:hover,
.ordering-more .button.no:hover {
    background: #000
}

.ordering-more h3 {
    color: #3d3d3d;
    font-size: 20px;
    font-family: 'poppinsbold';
    margin: 0 0 10px 0;
}

.date_list h4 {
    color: #3d3d3d;
    font-size: 20px;
    ;
    font-family: 'poppinsbold';
    margin: 0 0 10px 0
}

.venue-details-inner h3 {
    display: block;
    font-size: 14px;
    color: #3c3a3a;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
    padding-left: 5px;
}

.res-datetime-picker p {
    margin: 15px 0px 40px;
    font-style: italic;
    font-size: 12px;
    font-weight: bold;
}

.res-datetime-picker .tag span {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    text-transform: uppercase;
}

.time_slot {
    margin-bottom: 10px;
    position: relative;
    padding: 30px 120px 30px;
    border: 2px solid #ecdfcc;
    background: #ffffff;
    text-align: center;
    border-radius: 6px;
}

.time_slot .order-btn {
    padding: 20px 0px 30px;
}

.time_slot .order-btn .no {
    background: #626161;
    height: 55px;
    font-size: 22px;
    padding: 10px 80px;
    border-radius: 4px;
}

.time_slot .order-btn .yes {
    background: #72624f;
    height: 55px;
    font-size: 22px;
    padding: 10px 80px;
    border-radius: 4px;
}

.time_slot h4 {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 17px;
    margin: 0px;
}

.time_slot .add_time .label {
    color: #3d3d3d;
    font-size: 18px;
    margin: 0px;
    padding: 0px 0px;
    width: 240px;
    float: left;
    text-align: right;
    line-height: 38px;
    height: 38px;
}

.add_time {
    margin: 10px 30px 45px 0px;
}

.time_slot .time {
    padding: 0px 0px 20px 0px;
}

.time_slot .time:after {
    content: "";
    display: table;
    clear: both;
}

.time_slot .time label {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 17px;
    padding: 0px 0px 0px;
    text-transform: uppercase;
    line-height: 40px;
    margin: 0px;
}

.time_slot .time .form-control,
.order-select-brktime ul .time-cls .breaktime_listcls {
    height: 38px;
    padding: 6px 0px;
    border: 0
}

.order-select-brktime ul .custom-select-inner {
    padding: 0 10px 0 40px;
    background: #fff;
    width: 180px;
    height: 38px;
}

.order-select-brktime ul .time-cls .form-group:after {
    height: 38px;
    top: 0;
    right: 0
}

.order-break {
    padding: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.order-break .label {
    font-size: 19px;
    font-family: 'poppinsbold';
    margin: 0 10px 0 0
}

.show {
    display: block;
}

.cedele-location label {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 16px;
    line-height: 35px;
}

.location-list {
    height: 270px;
    margin: 0px -15px 0px 0px;
}

.location-list ul {
    list-style: none;
    margin: 0px 15px 0px 0px;
    padding: 0px;
}

.location-list ul li {
    font-weight: bold;
    color: #595656;
    font-size: 16px;
    background: #f8f8f8;
    border-radius: 7px;
    margin-bottom: 5px;
    height: 50px;
    padding: 0px 20px;
}

.location-list ul li:hover {
    color: #ffffff;
    background: #72624f;
}

.location-list .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000000;
}

.no-padding {
    padding: 0px;
}

.continue a {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 20px
}

.ownvenue-inner-div {
    max-width: 350px;
    margin: 40px auto 0;
}

.errorInfo-div {
    display: none;
    background: #ef1616;
    padding: 5px;
    color: #fff;
}


.break-time .title4, .change-location a {
    text-decoration: underline;
    font-size: 17px;
    color: #e2b014;
}

.change-location a:hover {
    color: #fff;
}

.event-time {
    width: 33%;
    padding: 5px 0px 5px 20px;
}

.event-time .title4 {
    margin-bottom: 3px;
}

.event-time .row {
    margin: 0 -4px;
}

.event-time [class*="col-"] {
    padding: 0 4px;
}

.event-time .form-group {
    margin: 0;
}

.event-time .form-control {
    height: 57px;
}

.order-select-brktime ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.order-select-brktime ul .time-cls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.order-select-brktime ul .time-cls .form-group {
    margin: 0;
    position: relative
}

.order-select-brktime ul .time-cls label {
    width: calc( 100% - 200px);
    font-size: 18px;
    font-family: 'poppinsbold';
    color: #020202;
    text-transform: uppercase
}

.viewmenu-top-div {
    margin: 10px 0 0 0;
}

.viewmenu-top-div .back_bx {
    padding: 36px 10px 12px;
}

.viewmenu-top-div .back_bx:after {
    font-size: 30px;
    top: 5px;
}


#our-signature-dish span {
    position: relative;
    display: inline-block;
    margin: 0 0 0 15px;
    top: -2px;
    cursor: pointer;
    background: #000;
    padding: 6px 13px;
    border-radius: 5px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    line-height: 1;
}

#our-signature-dish span:hover {
    background: #000000;
}

#our-signature-dish span img {
    height: 17px;
    vertical-align: middle;
}

.upkg_tabsec {
    padding: 26px 0 8px;
}

.order_details .panel-group .panel+.panel {
    margin-top: 0px;
}

.order_details .panel-group {
    padding: 30px 0px 0px;
    margin-bottom: 0px;
}

.order_details .panel-default {
    border-color: transparent;
}

.order_details .panel {
    background-color: #f6f6f6;
    border-radius: 0px
}

.order_details .panel-default>.panel-heading {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
    padding: 12px 15px 12px 15px;
    border-radius: 0px;
}

.order_details .panel-title>a {
    display: block;
    font-size: 18px;
    text-transform: uppercase;
}

.order_details .panel-title>a .clck {
    text-align: right;
    font-weight: 300;
    font-style: italic;
    float: right;
    font-size: 14px;
    margin-right: 30px;
    margin-top: 2px;
}

.order_details .panel-title>a:hover {
    color: #fff;
}

.order_details .panel-title {
    position: relative;
}

.order_details .panel-title:after {
    content: '';
    position: absolute;
    right: -9px;
    background: #fff;
    height: 2px;
    top: 0;
    width: 18px;
    -webkit-transform: rotate(90deg) translate(10px, 10px);
    transform: rotate(90deg) translate(10px, 10px);
}

.order_details .panel-title:before {
    content: '';
    position: absolute;
    right: 7px;
    background: #fff;
    height: 2px;
    top: 0;
    width: 18px;
    -webkit-transform: rotate(0deg) translate(6px, 10px);
    transform: rotate(0deg) translate(6px, 10px);
}

.order_details .act .panel-title:after {
    opacity: 0;
}

.order_details .panel-collapse {
    height: 670px;
}

.checkout_time {
    padding: 0px 0px 20px;
}

.checkout_time label {
    color: #000000;
    line-height: 40px;
    padding: 4px 0px;
    font-size: 17px;
    text-transform: uppercase;
    font-family: 'poppinsbold';
}

.order_details .edit {
    background: #809b66;
    border-radius: 3px;
    margin-right: 5px;
    display: inline-block;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    position: absolute;
}

.order_details .edit .fa-pencil-square-o {
    font-size: 18px;
    color: #fff
}

.mobile-order-nowbtn{ display: none;}

.react-datepicker__time-list-item--disabled {
    display: none;
}

.loader-main-cls {
    position: relative;
    pointer-events: none;
}

.loader-sub-div {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 6;
}

.loader-sub-div:after {
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 0.3s linear infinite;
    animation: loader-rotate 0.3s linear infinite;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.htico_search > a {
    position: relative;
    display: block;
}

.home-banner-full {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.header-top {
    background: #000;
    text-align: center;
    clear: both;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-top p {
    color: #fff;
    font-size: 15px;
    font-family: 'poppinsregular';
    margin-bottom: 0px;
}

.logo {
    max-width: 234px;
    height: 62px;
}

.slick-slide {
    position: relative;
}

.home-ordernow-main {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.featured-products-section {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.featured-products-main h2 {
    font-size: 50px;
    color: #000;
    margin-bottom: 0px;
}

.featured-products-main p {
    font-size: 21px;
    color: #000;
    margin-bottom: 20px;
    font-style: italic;
}

.price-tag h6 {
    font-family: 'poppinsbold';
    font-size: 17px;
    color: #fff;
    background-color: #4f9a97;
    width: 150px;
    height: 35px;
    line-height: 35px;
    text-transform: uppercase;
    float: left;
}

.featured-products-single img {
    position: relative;
}

.price-tag {
    position: absolute;
    top: 0px;
}

.related-tags ul, .product-tags-list ul {
    list-style: none;
    padding: 0 0 0 5px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.related-tags ul li, .product-tags-list ul li {
    font-family: 'poppinsbold';
    font-size: 13px;
    padding: 3px 10px;
    background-color: #000;
    border: 1px solid #fff;
    text-transform: uppercase;
    border-radius: 30px;
    color: #fff;
}

.product-tags-list ul li {
    margin-right: 5px;
}

.related-tags {
    float: left;
    position: absolute;
    bottom: 15px;
}

.product-tags-list {
    float: left;
    position: absolute;
    bottom: 15px;
}

.review-tags {
    float: right;
    position: absolute;
    bottom: 4px;
    right: 0px;
}

.related-tags ul li,
.product-tags-list ul li {
    display: inline-block;
    margin-top: 5px;
    margin-right: 5px
}

.related-review-tag {
    position: relative;
}

.fa-star-o {
    color: #000;
}

.wppc-checked {
    color: red;
}

.wppc-rating-full {
    background-image: url("../images/star-bg.png");
    background-repeat: no-repeat;
    padding: 5px 10px;
    margin-top: 0px;
    margin-left: 44px;
}

footer {
    position: relative;
    padding: 50px 0 0 0;
    margin: 50px 0 0 0;
    background: #f4f4f4;
}

.footer-bottom {
    padding-top: 30px;
    padding-bottom: 20px;
    background: #000000;
}

.footer-bottom .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.footer-bottom-left {
    width: 20%;
    display: inline-block;
}

.footer-bottom-right {
    width: 15%;
    display: inline-block;
}

.footer-bottom-middle {
    width: 58%;
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.footer-top {
    text-align: center;
    padding: 0 0 20px 0;
}

.footer-contact a, .footer-bottom-right h3 {
    font-family: 'poppinsregular';
    font-size: 15px;
    text-decoration: none;
    color: #fff;
}

.footer-menu h3 {
    font-family: 'poppinsregular';
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 10px 0;
}

.footer-menu ul, .footer-bottom-right ul {
    padding-left: 0px;
    list-style: none;
}

.footer-menu ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.footer-bottom-right ul li {
    display: inline-block;
}

.footer-bottom-right ul li a {
    color: #fff;
    font-size: 22px;
}

.footer-menu ul li a:hover {
    color: #000
}

.copyright-section p {
    font-size: 16px;
    color: #060801;
    text-align: center;
    margin-bottom: 0px;
}

.home-banner {
    position: relative;
    z-index: 2;
}

.home-banner .slick-slider .slick-prev {
    background: url(../images/previous-icon.png) 0 0px no-repeat !important;
    left: 10px;
}

.home-banner .slick-slider .slick-next {
    right: 10px;
    background: url(../images/next-icon.png) 0px 0px no-repeat !important;
}

.featured-products-main .slick-slider .slick-prev {
    left: -80px !important;
    background: url(../images/black-previous.png) 0 0px no-repeat !important;
}

.featured-products-main .slick-slider .slick-next {
    right: -80px !important;
    background: url(../images/black-next.png) 0px 0px no-repeat !important;
}

.home-banner .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    outline: 0;
    width: 38px;
    height: 30px;
    text-indent: -9999px;
    z-index: 9;
    border: none;
    padding: 0;
    margin-bottom: 0
}

.featured-products-main .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    outline: 0;
    width: 38px !important;
    height: 30px !important;
    text-indent: -9999px;
    z-index: 9;
    border: none;
}

.bx-wrapper {
    border: none !important;
    margin-bottom: 0px !important;
    background-color: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.bx-wrapper .bx-pager {
    display: none !important;
}

.bxslider {
    position: relative;
}

.featured-products-main .bx-wrapper {
    margin-left: auto;
    margin-right: auto;
}

.product-view-more {
    margin-top: 40px;
}

.footer-bottom-right ul, .footer-bottom-right h3 {
    display: inline-block;
}

.footer-bottom-right {
    text-align: right;
}

.footer-bottom-right ul li {
    padding-left: 3px;
    vertical-align: middle;
}

.slick-slide img {
    width: 100%;
}

.slick-track {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.slick-track:before, .slick-track:after {
    display: none !important
}

.slick-track .slick-slide {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: inherit;
}

.slick-track .slick-slide > div {
    height: 100%;
}

.slick-track .slick-slide .featured-products-single {
    height: 100%;
    background-color: #fff;
}

.bakery_slide {
     text-align: center;
    border: none;
    border-left: 0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    width: 49%;
    margin: 10px 0.5%;
}
.bakery_slide span {
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    margin: 0 auto;
    z-index: 99;
    font-size: 32px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 30px;
    height: 30px;
}
.bakery_slider .slick-track .slick-slide > div {
    height: 100%;
    line-height: 1px;
}
.bakery_slide p a {
        padding: 0;
    display: block;
    font-size: 26px;
    text-transform: capitalize;
    font-weight: 600;
    color: #fff;position: relative;
}

.bakery_row .slick-next {
    font-size: 0;
    border: 0;
    outline: 0;
    position: absolute;
    background: #464646;
    right: 0px;
    top: 0;
    width: 48px;
    height: 44px;
    border-left: none;
    cursor: pointer;
    margin: 0
}

.bakery_row .slick-prev {
    font-size: 0;
    border: 0;
    outline: 0;
    position: absolute;
    background: #464646;
    left: 0;
    top: 0;
    width: 48px;
    height: 44px;
}

.bakery_row .slick-prev::after {
    content: "";
    background: url(../images/previous-icon.png) no-repeat;
    width: 38px;
    height: 30px;
    background-position: 2px 3px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 11px;
    right: 0;
    margin: auto;
    background-size: 75%;
}

.bakery_row .slick-next::after {
    content: "";
    background: url(../images/next-icon.png) no-repeat;
    width: 38px;
    height: 30px;
    background-position: 4px 3px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background-size: 75%;
}

.bakery_row {
    position: relative; margin: 0px 0 10px;
}

.innerproduct-col {
    position: relative;
    width: 23%;
    margin-right: 0.6%;
    margin-bottom: 32px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    margin: 0 0.7% 18px;
    box-shadow: 0 0 4px #cacaca;
    padding: 15px;
}

.innerproduct-item::after {
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    position: absolute;
    z-index: 1;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after, .innerproduct-col.open::after {
    opacity: 1;
}

.innerproduct-item-text h4 {
    font-size: 21px;
    color: #0a0b10;
    padding: 10px 0 8px 0;
    margin-bottom: 0;
}

.bakery-review .fa-star {
    color: #000;
}

.bakery-review .wppc-checked {
    color: #ed8a19;
}

.innerproduct-item-text p {
    line-height: 20px;
    font-size: 14px;
    color: #949292;
    padding-top: 5px;
    padding-bottom: 10px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.innerproduct-item-text h5 {
    font-size: 20px;
    font-family: 'poppinsregular';
    color: #000;
    margin: 0 0 12px;
}

.innerproduct-item-text {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%; padding: 0;
}

.singup-fb-icon i {
    font-size: 22px;
    vertical-align: middle;
    padding-right: 7px !important;
}

.signup-popup .popup-body h4{
    text-transform: uppercase;
    margin: 0 0 12px;
    font-size: 16px;
    color: #000;
    font-family: 'poppinsbold';
} 

.signup-popup .popup-footer .btn {
    font-family: 'poppinsbold';
    font-size: 14px;
    max-width: 225px;
    height: 45px;
    line-height: 45px;
    padding: 0px;
    border-radius: 0px;
}
.inside-popup-rhs .signup-popup-head .guest_btn{    
    display: block;
    max-width: 271px;
    margin: 0 auto;
    border-radius: 7px;
    padding: 16px 22px;
    height: 51px;
    font-size: 18px;
    font-family: 'poppinsregular';
    line-height: 1;    
}

.signup-popup .focus-out label, .signup-popup input {
    font-size: 14px;
    color: #595656;
}

.custom_checkbox input {
    margin: 0;
    position: absolute;
    cursor: pointer;
}

.sign_reg a {
    color: #000;
    text-decoration: underline;
}

.sign_reg a:hover{ color: #e2b014;}

.signup-popup .sign_reg p {
    font-size: 13px;
    color: #0e0e0e;
    line-height: 1.5;
    text-align: left;
}

.address-list-main .address_linfo .custom_radio input.address_chk{
    margin-right: 8px;
}

.check-left{
    text-align: left;
}

.signup-popup .custom_checkbox {
    display: flex;
}

.popup-body input[type=checkbox] {
    margin: 3px 5px 0 0;
    display: inline-block;
}

.custom_checkbox_content span {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 5px;
    position: relative;
    display: inline-block;
    padding: 0 0 0 20px;
    pointer-events: none;
    color: #3d3d3d;
}

.popup-body .sign_reg {
    margin: 0;
    display: inline-block;
}

.signup-popup .custom_checkbox span:before {
    width: 18px;
    height: 18px;
    border: 2px solid #B1B1B1;
    border-radius: 2px;
}

.signup-popup .custom_checkbox input[type=checkbox]:checked + span:before {
    background: url(../images/tick_black.png) no-repeat;
    border: 0;
}

.productlist-main-div {
    min-height: 170px;
}

.hmenu_list li a.active {
    color: #fff;
}

.hmenu_list > li > a {
    font-family: 'poppinsmedium'; 
    font-size: 15px;
    color: #000000;
    padding: 14px 0; 
    margin: 0 5px;
    display: block;
    text-transform: uppercase;
    position: relative;
}
.hmenu_list > li > a:after{
    display:block;
    background: #e2b014;
    width: 0;
    height: 2px;
    position: absolute;
    content: '';
    right: 0;
    left: 0;
    bottom: 5px;
    margin: auto;
    transition: width .3s ease 0s,left .3s ease 0s;
}
.hsign_sec > span {
    position: relative;
    top: 4px;
    font-size: 15px;
    color: #000;
    vertical-align: text-bottom;
    margin: 0 5px;
}

.hsign_sec a {
    font-family: 'poppinsmedium';
    text-transform: uppercase;
    font-size: 15px;
    color: #000; 
    padding: 14px 5px;
    display: inline-block;
    position: relative;
}

.hsign_sec a:hover {
    color: #e2b014;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
}

.htico_search> a > i {
    background: url(../images/search.svg) no-repeat center;
    width: 23px;
    height: 28px;
    display: block;
}
.htico_wishlist> a > i {
    background: url(../images/wishlist.svg) no-repeat center;
    width: 27px;
    height: 28px;
    display: block;
}
.htico_cart> a > i {
    background: url(../images/bag_block.svg) no-repeat center;
    width: 22px;
    height: 28px;
    display: block;
}

header.small_sticky:before {
    height: 100%;
}

.hcart_round {
    background: #de5036;
    min-width: 14px;
    min-height: 14px;
    font-size: 7px;
    border-radius: 100%;
    position: absolute;
    top: -2px;
    right: -8px;
    text-align: center;
    color: #fff;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 12px;
}

.home-ordernow-single h3 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 0px;
}

.home-ordernow-single {
    text-align: center;
    position: relative;
    width: 25%;
    cursor: pointer;
}

img.blue-round {
    display: block;
    -webkit-transition: 0.5s linear all;
    transition: 0.5s linear all;
}

img.green-round {
    display: none;
    -webkit-transition: 0.5s linear all;
    transition: 0.5s linear all;
}

.home-ordernow-single:hover img.green-round,.home-ordernow-single.active img.green-round,
.order_delivery_item li a:hover img.green-round, .ordericon_link.active img.green-round {
    display: block;
    -webkit-transition-delay: 5s;
    transition-delay: 5s;
    -webkit-transform: rotate(380deg);
    transform: rotate(380deg);
}

.home-ordernow-single:hover img.blue-round, .home-ordernow-single.active img.blue-round,
.order_delivery_item li a:hover img.blue-round, .ordericon_link.active img.blue-round {
    display: none;
}

.home-ordernow-single-img .button {
    margin: 5px 0 0 0;
    font-size: 16px;
    padding: 12px 24px 12px;
}

.home-ordernow-single-img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.home-ordernow-single-img img {
    max-height: 83px;
}

.home-banner .slick-track .slick-slide > div {
    line-height: 0;
    width: 100%;
}

.home-badge-icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: -131px;
}

.home-ordernow-main {
    max-width: 98%;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-top: 60px;
}

.serving-passionately-right h3 {
    font-size: 52px;
    color: #000;
    line-height: 1;
    margin: 0 0 10px 0;
}

.featured-products-full .slick-dots {
    display: none !important
}

.checkout-terms-and-condition {
    height: 136px;
    overflow-y: auto;
    margin: 0 0 15px 0;
    font-size: 16px;
    border: 1px solid #d0cfcb;
    padding: 15px 10px
}

a.change-edt {
    color: #666;
    border-bottom: 1px solid #999;
    margin: 5px 0 0 0;
    display: inline-block;
    font-size: 16px;
}

a.change-edt:hover {
    color: #000000;
    border-color: #000000;
}

.page404-main-div {
    text-align: center;
}

.page404-main-div .gohome {
    display: inline-block;
    line-height: 1;
    padding: 15px 50px;
    margin: 10px;
    border-radius: 6px;
    text-align: center;
    font-weight: bold;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.copyright-section {
    border-top: 1px solid #bbb7b7;
    padding: 25px 10px;
}

.innerproduct-button .disabled {
    background: #d5d1d2 !important;
    color: #000000;
}

.bakery_row .slick-slide {
    background-color: transparent;
    display: block !important;
    width: 49% !important;
    margin: 10px 0.5%;
}

.bakery_row .slick-slide .bakery_slide.active a {
    color: #fff
}
.bakery_row .slick-slide .bakery_slide p a:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgb(0 0 0 / 30%);
    overflow: hidden;
}
.innerproducts-list-maindiv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; justify-content: center;
}

.prodetal-main-div {
    min-height: 105px;
    padding: 60px 0;
}

.product-detail-empty {
    padding: 52px 0px 35px 0px;
    text-align: center;
    color: #000000;
    font-family: 'poppinssemibold';
    font-size: 19px;
}

.custom-select-bxcls {
    position: relative
}

.custom-select-bxcls select {
    margin: 0 !important;
    width: 100%;
    height: 50px;
    font-size: 17px;
    padding-left: 10px;
}

.disable-addtocart {
    pointer-events: none;
    cursor: default;
}

.disable-addtocart:after {
    background: hsla(0, 0%, 100%, 0.8) none repeat scroll 0 0;
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    content: '';
}

.modfr-alert-danger {
    display: none;
    background: #ffdfdf;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    padding: 10px;
    font-size: 13px;
    color: #c10606;
}

.modfir_addcart_cls .qty_bx input {
    color: #000;
}

.mdf_common_rows {
    position: relative;
}

.cart_row.grant-total-cls sup {
    font-size: 70%;
    position: relative;
    top: -6px;
}

.cart-header-second h5 {
    font-family: 'poppinsbold';
    color: #1c1b1b;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 5px 0
}

.cart-header-second p {
    font-family: 'poppinsbold';
    color: #1c1b1b;
    font-size: 28px;
}

.cart_footer .cart_remove {
    top: 2px;
    right: 5px;
}

.cart_footer .promo_add .cart_remove, .cart_footer .promo-cart-row .cart_remove {
    top: 5px;
    right: -14px;
    width: 12px;
    height: 12px;
}

.cart_footer .promo_add .cart_remove:after, .cart_footer .promo_add .cart_remove:before,
.cart_footer .promo-cart-row .cart_remove:after, .cart_footer .promo-cart-row .cart_remove:before{
    top: 1.5px;
    right: 6px;
    content: "";
    height: 9px;
    width: 1.2px;
}

.cart_footer .cart_remove:hover {
    background-color: #000000;
}

.product-details {
    padding: 15px 40px 15px 15px;
    position: relative;
}

.cart-header-second .row-replace, .hcart_tt  .row-replace, .product-details .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; align-items: center;
}

.hcart_tt_items  .row-replace, .product-details .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; align-items: center;
    justify-content: space-between;
}

.cart-header-second .row-replace .col-sm-cls {
    width: 50%
}

.cart_body .hcart_tt .row-replace {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.cart_body .hcart_tt {
    padding: 10px 10px 10px 15px;
}

.cart_body .hcart_tt h3 {
    font-family: 'poppinsbold';
    text-transform: uppercase
}

.product-details .row-replace .cart_right {
    width: 220px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.product-details .cart_info h4:empty {display: none;}

.product-details .row-replace .cart_left {
    width: calc(100% - 220px);
    width: -webkit-calc(100% - 220px); display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.compo_minmax_maindiv .combo-input-label {
    display: block;
}

.compo_minmax_maindiv .max-min-bar {
    padding-bottom: 10px;
    font-size: 18px
}

.compo_minmax_maindiv .error_combo_div {
    color: red;
    padding-bottom: 10px;
    display: none;
}

.compo_minmax_maindiv .combo_pro_price {
    color: #e2b014;
}

.alert-success {
    background-color: #dff0d8;
    color: #3c763d;
    border-color: #d6e9c6;
}

.custom_close span {
    color: red;
}

.alert-success:before, .single-danger:before {
    top: 10px;
    left: 8px
}

.jquery-success-msg {
    margin-left: 20px;
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.location-indiv{
    margin: 80px 0 40px;
}

.location-indiv {
    margin: 80px 0px 40px;
}

.ourrest_row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%; background: #eaeaea;
    overflow: hidden;
}

ul.outletul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.outletul > li {
    width: 100%;
    margin: 0 0 30px 0;
    position: relative;
}

.outletul > li:last-child{
    margin: 0;
}

.outletul > li:nth-child(even) {
    margin-right: 0
}

.ourrest_img {
    width: 100%;
}

.ourrest_img img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.ourrest_info {
    width: 50%;
    padding: 20px;
    color: #000;
}
.ourrest_info p i {
    position: absolute;
    left: 0;
    top: 4px;
}
.ourrest_map {
    width: 50%;
    min-height: 250px;
}
.ourrest_info h4 {
    margin: 0 0 15px;
    color: #000;
    font-size: 22px
}
.ourrest_map > div img {
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.ourrest_info p {
    margin: 0 0 8px; position: relative; padding: 0 0 0 26px;
}
.ourrest_info p i.fa.fa-clock-o {
    left: 3px;
}
.ourrest_infolinks {
    list-style: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.ourrest_infolinks li {
    display: block;
    width: 50%;
}

.ourrest_infolinks a {
    padding: 14px 5px;
    display: block;
    color: #fff;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    font-family: 'poppinsbold';
    height: 100%
}

.media-links-a a {
    background: #869f6e
}

.media-links-b a {
    background: #666
}

.ourrest_infolinks a:hover {
    background: #e2b014;
}

.ourrest_infolinks a i {
    display: inline-block;
    margin: 0 10px 0 0;
}

.mfp-bg {
    background: rgba(0, 0, 0, 0.8);
}

#scrollbutton {
    position: fixed;
    bottom: 45px;
    right: 30px;
    width: auto;
    height: auto;
    z-index: 999;
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton.show {
    display: inline-block;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton a {
    background: #353535;
    display: inline-block;
    position: relative;
    color: #fff;
    text-decoration: none;
    line-height: 0;
    padding: 10px 14px;
    overflow: hidden;
    border-radius: 50px;
    font-size: 20px;
}

#scrollbutton a:hover {
    background: #e2b014;
}

#scrollbutton a i.ars {
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
    color: #e2b014;
}

#scrollbutton a:hover i.ars {
    -webkit-transform: translateY(-25px) rotateY(360deg);
    transform: translateY(-25px) rotateY(360deg);
    opacity: 0;
    -webkit-transition: all 0.3s linear 0.2s;
    transition: all 0.3s linear 0.2s;
}

#scrollbutton a i.ars1, #scrollbutton a i.ars2 {
    position: absolute;
    bottom: 0%;
    left: 50%;
    width: auto;
    height: auto;
    -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
    transform: translate(-50%, -50%) rotateZ(360deg);
    opacity: 0;
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a i.ars2 {
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
}

#scrollbutton a:hover i.ars1,
#scrollbutton a:hover i.ars2 {
    bottom: 18%;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars2 {
    bottom: 9%;
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0.4s;
    transition: all 0.2s linear 0.4s;
}

.tarms-error {
    display: none;
    color: #f03011;
}

.custom-tarms-div .sign_reg {
    padding-left: 30px
}

.custom-tarms-div span:before {
    width: 20px;
    height: 20px;
    border: 2px solid #000000;
    margin-top: 2px;
    border-radius: 0;
}

.custom-tarms-div .custom_checkbox input[type=checkbox]:checked + span:before {
    background: none;
    content: "\F00C";
    font-family: FontAwesome;
    text-rendering: auto;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    text-align: center;
    background: #000000;
    border: 2px solid #000000;
}

.error_div_cls {
    color: red;
    display: none
}

a.go-bck-icon {
    position: absolute;
    left: 0;
    top: 0;
}

a.go-bck-icon:before {
    content: "\f0a8";
    font-family: Fontawesome;
    font-size: 30px;
}

.guest_btn {
    text-align: center;
}

.controls.single-link {
    text-align: center;
}

.controls.single-link a {
    font-size: 15px;
    display: inline-block;
    color: #000000;
    font-family: 'poppinssemibold';
}

.controls.single-link a:hover{
    color: #e2b014;
}

.apply-red {
    color: red!important;
}

.delivery_outletpoup .order-body { border: 2px solid #000; }
.delivery_outletpoup .outlet-scooter-img { width: 86px; }

.custom_center_alertcls {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    max-width: 450px;
    margin: auto !important;
    padding: 30px;
    font-size: 22px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
}
.custom_center_alertcls.alert-danger{     
    color: #ca322f;
    background-color: #fdd1d1;
}
.mfp-custom-close {
    position: absolute;
}

.chk-payment .form-group.custom-tarms-div {
    padding: 0 20px 20px;
    text-align: left;
}
.text-lbl {
    display: block;
    font-family: 'poppinsbold';
    text-transform: uppercase;
    margin: 0 0 5px 0;
}

.timeslot_info {
    font-size: 13px;
    color: #f20000;
    margin-bottom: 15px;
}
.timeslot_info span {
    display: block;
}
.ordrdatetime_error .error { width: 100%; }
.syd_merge {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.syd_date, .syd_time {  width: 48%;}
.syd_merge .form-group {margin: 0}

.checkout-control-group-middle .react-datepicker-wrapper {
    display: block;
}
.checkout-control-group-middle .react-datepicker__input-container {
    position: relative;
    display: block;
}
.datetime_selt_sec .react-datepicker-wrapper, .datetime_selt_sec .react-datepicker__input-container {
    display: block;
}
.datetime_selt_sec .react-datepicker-wrapper, .datetime_selt_sec .react-datepicker__input-container .form-control {cursor: pointer;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}

/* omise */
.omise-form-error input {
    -webkit-box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0,0%,100%,0), 0 0 2px 0 rgba(255,0,0,.5);
    box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0,0%,100%,0), 0 0 2px 0 rgba(255,0,0,.5);
    border-color: #ff7076;
    border-top-color: #ff5c61;
}

.pay-omiseconf-popup { max-width: 340px; border-radius: 10px;background: #f5f5f7}
span.omise-pay-img {
    display: block;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    margin: 0 auto;
    border: 4px solid #fff;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    position: absolute;
    top: -39px;
    left: 0;
    right: 0;
}

img.makisan-logo-img {
    width: 100%;
    height: 100%;
    -o-object-fit: none;
    object-fit: none;
}

.omise_pay_header {
    position: relative;
    padding: 50px 30px 25px;
    text-align: center;
    border-bottom: 1px solid #d2d2d3
}
.omise_pay_body {
    padding: 30px;
    position: relative;
        border-top: 2px solid #fff;
}
.omisepop_in .form-control{ padding: 10px; height: 40px; font-size: 14px;border-radius: 4px; padding-left: 36px}
.expire_row_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.expire_row_inner > div {
    width: 32%;
    position: relative;
}
.omisepop_in .alt_btns .button {margin-bottom: 0;
    width: 100%;
    padding: 13px 10px 11px;
    border-radius: 4px;}
.omisepop_in .form_sec i{ position: absolute; left: 10px; color: #888;top:12px;  }
.omisepop_in .form_sec > div{ position: relative }
.omisepop_in .form_sec .uname-box  i{ left: 13px;}
.omisepop_in .form_sec .card-numbr-div i, .omisepop_in .form_sec .expire_left i {font-size: 16px;top:13px;}
.omisepop_in .form_sec .expire_right  i{ font-size: 19px; top: 12px;}
.expire_mdl .form-control{ padding-left: 15px }
.card {
    position: absolute;
    display: block;
    right: 10px;
    width: 28px;
    height: 19px;
    background: url(../images/cards/spritecard.png) no-repeat 0;
    top: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    background-position: 0 0;
    overflow: hidden;
}
.visa + .card {
    background-position: 0 -19px;
}
.master+ .card {
    background-position: 0 -38px;
}
.discover + .card {
    background-position: 0 -57px;
}
.american + .card {
    background-position: 0 -76px;
}
.pay-omiseconf-popup button.mfp-close {
    padding: 0;
    width: 21px;
    height: 21px;
    display: block;
    color: #fff !important;
    border-radius: 50%;
    line-height: 21px;
    background: #a5a5a5;
    border: 1px solid #929292;
    top: 10px;
    font-size: 20px;
    right: 10px;
    text-align: center;
}
.pay-omiseconf-popup button.mfp-close:hover{ background: #000; border-color: #000; }
.omisepay-mode-option {
    position: fixed;
    top: 20px;
    right: 20px;
    background: #ffd025;
    line-height: 1;
    padding:9px 11px 8px;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;
}
.omise-error-info-div {
    color: #ffffff;
    background-color: #b12f2f;
    border-color: #ebccd1;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    max-width: 100%;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
}
.omise-error-icon { font-size: 14px; }
.omise_alert_close {
    position: absolute;
    right: 0px;
    color: #FFF;
    top: 0;
}
.omise-error-msg {
    margin: 0 0 0 11px;
    font-size: 14px;
}
.omise-error-info-div .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
}

.membership-type-innerdiv {
    background: #fff;
    color: #202011;
    padding: 25px 15px;
    border: 3px solid #020101;
    border-radius: 5px;
    margin: 30px 0 0 0;
    text-align: left;
    box-shadow: 0px 3px 10px 0px rgba(1, 1, 1, 0.45);
}

.membership-type-innerdiv h4 {
    text-align: center;
    color: #020101;
    font-size: 25px;
}

.membership-type-title {
    text-align: center;
    position: relative;
    color: #686766;
    font-family: 'poppinsbold';
    text-transform: uppercase;
    margin-bottom: 20px;
}

.membership-type-title:before {content: "";position: absolute;left: 0;right: 0;height: 2px;width: 100%;background: #686766;top: 50%;transform: translateY(-50%);}

.membership-type-title span {
    background: #fff;
    display: inline-block;
    padding: 0 10px;
    z-index: 1;
    position: relative;
}

.gldmember-main .membership-type-title:before {
    background: #d18029;
}
.gldmember-main .membership-type-title{ color:#d18029; }
.text-box-incheckout em {
    text-transform: uppercase;
    font-size: 14px;
    color: #181818;
    text-align: right;
    float: right;
    margin-top: -5px;
}
.checkout-terms-topdiv {
    float: left;
    margin-top: 15px
}

.top-bar{ background: #e3e6f6; width: 100%; position: relative; margin: 0 0 10px; overflow: hidden; }
.top-bar p{ margin: 0; padding: 15px; color:#282828; font-size: 16px; text-align: center; }
.top-bar span{ position: absolute;
    left: 0;
    top: 0;
    background: #b5c9dd;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 17px;
    padding: 15px 30px; }
.top-bar span:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 90px solid #b5c9dd;
    border-right: 50px solid transparent;
    position: absolute;
    left: 140px;
    top: 0;
}

.home-banner .slick-initialized .slick-slide {
    display: flex!important;
    flex-wrap: wrap;
    height: inherit;
}

.home-banner .slick-list, .home-banner .slick-slider, .home-banner .slick-track, .home-banner .slick-track .slick-slide div{
    height: 100%;
}

.home-banner .slick-track .slick-slide .banner-info{
    height: auto;
}

.banner-info a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16px 60px 14px 22px;
    margin: 0;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    color: #000;
    font-size: 15px;
    line-height: 1;
    white-space: nowrap;
    letter-spacing: normal;
    text-transform: uppercase;
    position: relative;
    font-family: 'poppinsmedium';
    border-radius: 6px;
    text-align: center;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
}

.banner-info a:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 18px;
    background: url(../images/black-right-arrow.svg) no-repeat;
    content: "";
    width: 29px;
    height: 14px;
}

.join-us-section {
    background: url(../images/join-bg.png) no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    text-align: center; 
    position: relative;
    min-height: 226px;
    overflow: hidden;
}

.lft-sec-prt { 
    background: url(../images/join-lhs.png) no-repeat;
    background-size: cover; 
    border-radius: 30px 0 0 28px; 
    position: absolute;
    top: 0;
    left: 0;
    width: 310px;
    height: 100%;
 }

 .lft-sec-prt-main{
    width: calc(100% - 310px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
 }

.mid-sec-prt h4{
    font-family: 'poppinsbold';
    color: #fff;
    text-transform: uppercase;
    margin: 12px 0 5px; 
}

.mid-sec-prt h2{
    font-family: 'billy_ohioregular';
    margin: 0 0 10px;
    color: #fff;
    font-size: 56px;
}

.mid-sec-prt {
    width: 80%; 
}

.lft-sec-prt img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px 0 0 28px;
}

.rgt-sec-prt {
    width: 20%; 
    color: #fff;
}
.rgt-sec-prt p {
    margin: 0 0 8px;
}
.rgt-sec-prt a, .mid-sec-prt a {
    display: inline-block;
    padding: 10px 20px;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'poppinsmedium';
    border-radius: 5px;
    position: relative;
}

.close-icon-but {background: #000;}

.w-425{
    width:425px;
}

.order-btn {
    display: inline-block;
    padding: 10px 20px;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.5px;
    font-size: 16px;
    margin: 0;
    font-family: 'poppinsregular';
    font-weight: 700;
    border-radius: 5px;
}

span.birthday-text.phone-show-dskp{display: -webkit-inline-box;}
.birthday-text a {color: #e2b014;}
.phone-show-mbl a{color: #000;}
.birthday-text{font-size: 14px; padding-left: 17px;}

.our-promotions { text-align: center; padding: 0 0 40px; }
.our-promotions h1 { font-family: 'billy_ohioregular'; color: #e2b014;}
.our-promotions > p, .feature-inner > p{ margin: 0 0 25px; text-align: center; }
.our-promotions .slick-dots, .feature-inner .slick-dots{
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none; padding: 20px 0 0;
}
.our-promotions .slick-dots li, .feature-inner .slick-dots li{ margin: 0 5px; }
.our-promotions .slick-slide img { 
    height: 100%; 
    border-radius: 20px; 
    box-shadow: -1px 0 20px 1px rgb(0 0 0 / 10%);
}

.feature-inner .products-single-li{
    height: 100%;
}

.feature-inner .slick-slide img{ height: 100%;  }
.our-promotions .slick-dots li button, .feature-inner .slick-dots li button {
    border: 1px solid #000;
    background: #fff;
    width: 11px;
    height: 11px;
    text-indent: -9999px;
    padding: 0;
    list-style: none;
    border-radius: 50%;
    cursor: pointer; margin: 0;
}
.our-promotions .slick-track .slick-slide,
.feature-inner .slick-track .slick-slide{ justify-content: center; }

.feature-inner .slick-track .slick-slide > div, .our-promotions .slick-track .slick-slide > div { 
    height: 100%; padding: 15px 10px;
}

.our-promotions .products-image-div{ height: 100%;}
.our-promotions .products-single-li:hover{border: none;}
.our-promotions .slick-dots li.slick-active button,
.feature-inner .slick-dots li.slick-active button { background: #e2b014; border: 1px solid #e2b014; }

.ft-ct-top{ max-width: 675px; margin: 0 auto; display: flex; flex-wrap: wrap; justify-content: center; }
.ft-tp-col1, .ft-tp-col2, .ft-tp-col3{ width: 31.33%; margin: 15px 1%; text-align: center; }
.ft-tp-col1 img, .ft-tp-col2 img, .ft-tp-col3 img{ margin: 0 auto 10px; display: block; }
.ft-tp-col1 b, .ft-tp-col2 b, .ft-tp-col3 b{ font-family: 'poppinsbold'; text-transform: uppercase; }
.ft-tp-col2 p strong, .ft-tp-col3 p strong{ font-family: 'poppinsbold'; text-transform: uppercase;}
.ft-tp-col1 p, .ft-tp-col2 p, .ft-tp-col3 p{ margin: 0 0 5px; }
.ft-ct-top a{ color: #000; }
.ft-ct-top a:hover{ color: #e2b014; }
.ft-tp-col2 p:nth-child(3n), .ft-tp-col3 p:nth-child(3n){margin-bottom: 15px;}
.ft-ct-bottom{ width: 100%; display: flex; flex-wrap: wrap; justify-content: center; padding: 40px 0 70px; }
.ft-bt-col1{ width: 28%; padding: 0 20px; }
.ft-bt-col1 p{ margin: 0; font-size: 15px; line-height: 22px; }
/* .ft-bt-col2, .ft-bt-col3, .ft-bt-col5{ width: 17%; padding: 0 20px; } */
.ft-bt-col1,.ft-bt-col2, .ft-bt-col3, .ft-bt-col4,.ft-bt-col5 { width: 21%; padding: 0 20px;} 
.ft-bt-col4{ width: 20%; padding: 0 20px; }
.ft-ct-bottom h4 { font-weight: 700; text-transform: uppercase; font-size: 17px; margin: 0 0 15px; }
.ft-list { padding: 0; margin: 0; list-style: none; }
.ft-list li { margin: 0 0 10px; font-size: 16px; }
.ft-list li a{ color: #000; position: relative;}
.ft-list li a:before {
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 1px;
    width: 100%;
    content: "";
    transform: scale(1);
    background-color: #ffa800;
    transition: all 0.3s linear;
    opacity: 0;
}
.ft-list li a:hover::before {
    transform: scale(-1);
    opacity: 1;
}
.ft-list li a:hover{ color: #e2b014; }

.social-list img:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    transition: all .2s cubic-bezier(.63,.05,.43,1.7);
}

.social-list { padding: 0; margin: 0; list-style: none; display: flex; flex-wrap: wrap; align-items: center; }
.social-list li { margin: 0; float: left; margin: 0 10px 0 0; font-size: 16px; }
.social-list li a{ color: #000; }
.social-list li a:hover{ color: #e2b014; }

.whishlist-img{ width: 27px; height: 27px; position: absolute; right: 10px; top: 10px;
    background: url(../images/whishlist-img.png) no-repeat;
}

.product-price .price{
    color: #c4c3c2;
    text-decoration: line-through;
    font-size: 18px;
    display: block;
    line-height: 1;
    font-family: 'poppinsregular';
    margin: 0 0 5px;
}

.price-list h3, .special-price{
    font-size: 20px;
    font-family: 'poppinssemibold';
    line-height: 34px;
}

.blank-hgt{ height: 37px; display: block; }

.testimonial-section{ width: 100%;  box-shadow: 0 0 15px #e8e8e8; -webkit-box-shadow: 0 0 15px #e8e8e8;
background: #fff; position: relative; padding: 50px 40px 40px; text-align: center; margin: 0 0 40px; }
.testimonial-section h2{ margin: 0 0 45px; }
.testimonial-section > div{ text-align: left; }
.testimonial-section .slick-dots { display: flex !important; flex-wrap: wrap; justify-content: center;
list-style: none; padding: 20px 0 0; }
.testimonial-section .slick-dots li { margin: 0 5px; }
.testimonial-section .slick-slide img { padding: 10px; }
.testimonial-section button { background: #000; width: 11px; height: 11px; text-indent: -9999px; padding: 0;
 list-style: none; border-radius: 50%; cursor: pointer; margin: 0; }
.testimonial-section .slick-track .slick-slide{ justify-content: center; }
.testimonial-section .slick-dots li.slick-active button { background: #e2b014; }
.testimonial-section .slick-track .slick-slide.slick-cloned:before {
    content: '';
    background: rgb(255 255 255 / 70%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.testimonial-section .slick-track .slick-slide.slick-cloned:before, 
.testimonial-section .slick-track .slick-slide.slick-active:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 80%);
}
.testimonial-section .slick-slide.slick-active.slick-center.slick-current:before { 
    content: ''; 
    background: transparent;
}
.testimonial-section .slick-track .slick-slide > div{ padding: 0 20px; }

.testi-author {
    padding: 60px 0 0;
    display: flex;
    justify-content: space-between;
}
.testi-author-img {
    width: 58px;
    height: 58px;
    line-height: 58px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.testi-author-img img {
    height: 58px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}
.testi-author-name {
    width: calc(100% - 65px);
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
}
.testi-author-name h4 {
    margin: 0 0 3px;
    text-transform: inherit;
    font-size: 17px;
    font-family: 'poppinsregular';
}
.testi-author-name span {
    color: #8d8d8d;
    font-size: 15px;
}

.home-testimonial .owl-item.active.center .inner-testimonials {
    opacity: 1;
}

.home-blog-slider .owl-item.active{
    opacity: 1;
}

.home-testimonial .owl-dots .owl-dot, .procuct-category-inner .owl-dots .owl-dot,
.category-products-main .owl-dots .owl-dot{
    margin: 0 10px 0 0;
}

.home-testimonial .owl-dots, .procuct-category-inner .owl-dots, .category-products-main .owl-dots{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0 0;
}

.procuct-category-inner .owl-dots, .category-products-main .owl-dots{
    margin: 20px 0 0;
}

.home-testimonial .owl-dots span,  .procuct-category-inner .owl-dots span,
.category-products-main .owl-dots span{
    width: 11px;
    height: 11px;
    background: #fff;
    display: block;
    border-radius: 50%;
    border: 1px solid #8b8787;
}

.home-testimonial .owl-dot.active span, .procuct-category-inner .owl-dot.active span,
.category-products-main .owl-dot.active span{
    background: #dba313;
    border: 1px solid #dba313;
}

.home-testimonial .owl-nav{
    position: relative;
    bottom: 25px;
}

.home-testimonial .owl-next, .home-testimonial .owl-prev {
    width: 26px;
    height: 14px;
    overflow: hidden;
    text-indent: -9999px;
    border-radius: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    background: none;
    box-shadow: none;
}

.home-testimonial .owl-prev {
    left: -5px;
}

.home-testimonial .owl-next {
    right: -5px;
}

.home-testimonial .owl-prev:before{ background: url(../images/download.png) no-repeat; width: 24px; height: 11px;content: "";position: absolute; left: 0; top: 0; }
.home-testimonial .owl-next:before{  background: url(../images/download.png) no-repeat; width: 24px; height: 11px;content: "";position: absolute; left: 0; top: 0;transform: rotate(180deg); }

.social-list { padding: 0; margin: 0; list-style: none; display: flex; flex-wrap: wrap; align-items: center; }
.social-list li { margin: 0; float: left; margin: 0 10px 0 0; font-size: 16px; }
.social-list li a{ color: #000; }
.social-list li a:hover{ color: #e2b014; }

/**************** STICKY MENU *************************/

.sticky-menu-nav {
    background: #f6f6f6;
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
    position: fixed;
    top: 82px;
    left: 0;
    right: 0;
    z-index: 91;
    -webkit-transform: translate3d(0,-150px,0);
    transform: translate3d(0,-150px,0);
    background: #fff;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    transition: -webkit-transform .42s cubic-bezier(.165,.84,.44,1);
    transition: transform .42s cubic-bezier(.165,.84,.44,1);
    transition: transform .42s cubic-bezier(.165,.84,.44,1),-webkit-transform .42s cubic-bezier(.165,.84,.44,1);
}

.sticky-menu-product .sticky-menu-nav {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.menu-section-left { display: flex; flex-wrap: wrap; position: relative; z-index: 3;}
.menu-section-left-inner { width: 80%; display: flex;}
.more-menu { width: 20%;}
.menu-section-left-inner .menu-list-intvl { text-align: center;}

.menu-section-left-inner .menu-list-intvl a {
    padding: 10px 2px;
    position: relative;
    cursor: pointer;
    font-family: 'poppinssemibold';
    font-size: 15px;
    line-height: normal;
    display: block;
    text-align: center;
    height: 100%;
    color: #606060;
    text-transform: capitalize;
    padding: 14px 20px;
}

.menu-section-left-inner .menu-list-intvl a:before, .more-menu-parent:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #e2b014;
    height: 2px;
    transition-property: left,right;
    transition-duration: .3s;
    transition-timing-function: ease-out;
}

.menu-section-left-inner .menu-list-intvl a:before{
    bottom: -1px;
}

.sticky-menu-nav .menu-list-intvl a:before, .sticky-menu-nav .more-menu-parent:before{
    background: #e2b014;
}

.sticky-menu-nav .menu-list-intvl.active .menu-title-link, .sticky-menu-nav .menu-list-intvl:hover .menu-title-link {
    color: #fdc855;
}

.menu-section-left-inner .menu-list-intvl.active a:before, .menu-section-left-inner .menu-list-intvl a:hover:before, .more-menu-parent.active:before, .more-menu-parent:hover:before {
    left: 0;
    right: 0;
}

.more-menu-parent {
    cursor: pointer;
    padding: 10px 27px 10px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #606060;
    font-family: 'poppinssemibold';
    text-transform: capitalize;
    font-size: 15px;
    line-height: normal;
    height: 100%;
}

.sticky-menu-nav .menu-list-intvl a {
    color: #606060;
    font-family: 'poppinssemibold';
    text-transform: capitalize;
    padding: 14px 20px;
    display: flex;
    text-align: center;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: 1.1;
    position: relative;
}

label.open_more_category {
    width: 25px;
    text-align: right;
    cursor: pointer;
    font-size: 25px;
    color: #606060;
    position: absolute;
    right: 10px;
}

ul.more_categor_info {
    padding: 0;
    list-style: none;
    min-width: 200px;
    max-width: 280px;
    background: #fff;
    position: absolute;
    right: 0;
    max-height: 60vh;
    overflow-y: auto;
    border-radius: 3px;
    top: calc(100% + 5px);
    border: 1px solid rgba(0,0,0,.1);
}

.sticky-menu-nav .menu-section-left-inner .menu-title-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
    height: 100%;
    padding: 10px 5px;
    line-height: 1.1;
    position: relative;
    border-bottom: 1px solid #f3f3f3;
    color: #606060;
    font-weight: 600;
    text-transform: capitalize;
    padding: 14px 20px;
}

span.special {
    background: #e2b014;
    color: #fff;
    height: 28px;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px;
    display: block;
    max-width: 80px;
    margin: 0 auto 9px;
    line-height: 9px;
    border-radius: 50px;
}
.price_nor {
    font-size: 28px;
    font-weight: 500; font-family: 'poppinsregular'; display: block;
}
.price_nor sup {
    font-size: 16px; font-family: 'poppinsregular';
    top: -12px;
    font-weight: 500;
}
.old-price {
    font-size: 21px; font-family: 'poppinsregular'; display: block;
    font-weight: 500; color: #b6b6b6; text-decoration: line-through; margin: 0 0 7px;
}
.old-price sup {
    font-size: 16px; font-family: 'poppinsregular';
    top: -12px;
    font-weight: 500;
}

.heart {position: absolute;top: 13px;right: 8px;cursor: pointer;z-index: 2;}
.heart .like {display: none;}
.heart .unlike {display: block;}
.heart:hover .unlike,
.heart.active .unlike {display: none;}
.heart:hover .like,
.heart.active .like {display: block;}
.notification-wrap {padding: 0px 0px 0px;}
.notify-row .slick-slide > div { width: 100%; padding: 12px 6px 16px;}
.notify-row { padding: 20px 0; display: flex; flex-wrap: wrap; justify-content: center; }
.notify-box {
    padding: 28px 20px;
    width: 32.33%;
    margin: 10px 0.5%;
    background: #fff;
    position: relative;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 0px #01010138;
    border: 1px solid #fff;
}
.notify-box.active, .notify-box:hover { border: 1px solid #e2b014; }
.notify-box .left{width: 50px;}
.notify-box .right{width: calc(100% - 50px); padding-left: 20px;}
.notify-icon .icon{
    background: url(../images/speaker.png) no-repeat; background-size: contain; height: 50px; width: 50px;
    display: inline-block;
}
.notify-box .notify-cnt h5 {
    font-size: 16px; font-family: 'poppinsregular'; font-weight: 700; margin: 0px 0px 5px 0px; 
}
.notify-box .notify-cnt p {font-family: 'poppinsregular'; font-weight: 400; margin: 0px 0px 0px 0px; height: 20px;
    overflow: hidden; }
.notify-box:after{
    content: ''; height: 15px; width: 15px; background: #fff; border: 2px solid #e0e0e0;
    border-radius: 100px; position: absolute; top: 10px; right: 10px;
}
.notify-box.active:after, .notify-box:hover:after {background: #e2b014; border: 2px solid #e2b014;}
.home-banner .slick-arrow, .notify-row .slick-arrow {top: 50%; width: 40px; height: 32px;}
.notify-row .slick-slider .slick-prev {left: -30px;}
.notify-row .slick-slider .slick-next {right: -30px; }

.tag_list{ width: 100%; display: block; clear: both; }
.tag_list ul{ padding: 0; margin: 0; }
.tag_list li{ list-style: none; margin: 0 5px 0 0; display: inline-block; }
.tag_list li a{ background: #fff; border: 1px solid #cfcece;
    color: #000;
    height: 28px;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px;
    display: block;
    margin: 0 auto 9px;
    line-height: 9px;
    border-radius: 50px; display: inline-block;}
.tag_list li a:hover, .tag_list li.active a  {
    background: #e2b014; border: 1px solid #e2b014;
    color: #fff;
    height: 28px;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px;
    display: block;
}

.sprite_takeaway{ width: 30px}
.sprite_adv_order{ width: 30px}
.sprite_reserve{ width: 26px}
.sprite_cater{ width: 30px}
.sprite_cater{ width: 30px}
.sprite_madbar{ width: 30px}
.order_now_popup_list{padding: 0;}
.order_now_popup_list li{margin-bottom: 6px; list-style: none}
.order_now_popup_list li a{padding: 10px 15px; background: #fff; text-align: center; display: block; font-size: 32px; font-weight: 600; color: #1e1d1d; text-transform: uppercase; transition: all 0.3s ease-in;}
.order_now_popup_list li .sprite_deliver{background: url(../images/new-delivery.png) no-repeat;}
.order_now_popup_list li .sprite_takeaway{background: url(../images/new-takeaway.png) no-repeat;}
.order_now_popup_list li .sprite_adv_order{background-position: -859px -2px}
.order_now_popup_list li .sprite_reserve{background-position: -908px -2px}
.order_now_popup_list li .sprite_cater{background-position: -959px -2px}
.order_now_popup_list li .sprite_madbar{background-position: -758px -50px}
.order_now_popup_list li a:hover , .order_now_popup_list li a.ordernow-active{ background: #e2b014; }

.menu-section-mobile-inner .slick-track, .mobile-menu-section-left .slick-track{
    border-bottom: 4px solid #e7e6e6;
}
.menu-section-mobile-inner .slick-track .slick-slide > div, .mobile-menu-section-left .slick-track .slick-slide > div{display: block; width: 100%;}

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link{
    padding: 10px 2px;
    position: relative;
    cursor: pointer;
    color: #1f1f1f;
    text-transform: capitalize;
    font-size: 17px;
    line-height: normal; font-weight: 600;
    display: block;
    height: 100%;text-align: center}

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link:before, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -4px;
    background: #e2b014;
    height: 4px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link:before, .mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link:before{    left: 0; right: 0;}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link{color: #181818}
.menu-section-mobile-inner, .mobile-menu-section-left{display: none}
.menu-section-mobile-inner button.slick-arrow, .mobile-menu-section-left button.slick-arrow, .products_sidebar_inner button.slick-arrow {
    position: absolute;
    overflow: hidden;
    text-indent: -9999px;
    padding: 0;
    width: 26px;
    height: 26px;
    border-radius: 3px;
    margin: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
}
.menu-section-mobile-inner button.slick-arrow:before, .mobile-menu-section-left button.slick-arrow:before, .products_sidebar_inner button.slick-arrow:before {
    position: absolute;
    left: 0;
    right: 0;
    width: 14px;
    height: 14px;
    top: 50%;
    margin-top: -8px;
    background: url(../images/left-arrow.png) no-repeat;
    content: "";
    margin-left: 6px;
}
.menu-section-mobile-inner button.slick-arrow.slick-next:before, .mobile-menu-section-left button.slick-arrow.slick-next:before, .products_sidebar_inner button.slick-arrow.slick-next:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-top: -6px;
}
.menu-section-mobile-inner .slick-prev, .mobile-menu-section-left .slick-prev, .products_sidebar_inner .slick-prev {
    left: 0;
}
.menu-section-mobile-inner .slick-next, .mobile-menu-section-left .slick-next, .products_sidebar_inner .slick-next {
    right: 0;
}
.menu-section-mobile-inner button.slick-arrow {
    color: #000;
    border-radius: 8px;
    cursor: pointer;
    background: #e2b014;
    border: 0;
    font-size: 16px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    z-index: 2;
    font-family: "poppinsbold"; border-radius: 3px;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link {
    color: #e2b014;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link:hover:before, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link:hover:before {
    left: 0;
    right: 0;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link:hover:before, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link:hover:before {
    background: #e2b014;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link:hover {
    color: #e2b014;
}

/*********************** MYACCOUNT START ***********************************/

.common-inner-banner {
    position: relative;
    padding: 20px 45px;
    color: #000;
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    background-size: cover;
    min-height: 130px;
    text-align: center;
}

.common-inner-banner h3 {
    font-family: 'poppinssemibold';
    font-size: 32px;
    margin: 35px 0 10px;
    text-transform: uppercase;
}

.common-inner-banner p{
    margin: 0;
    font-size: 14px;
    color: #000;
    font-family: 'poppinsmedium';
}

.common-inner-banner .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;
}
.common-inner-banner img {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    top: 0;
    bottom: 0;
    z-index: -1;
}

/* -=-=-=-=-=-=-=-= My Account Section Start -=-=-=-=-=-=-=-= */

.myaccount-banner {
    padding: 60px 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    background: #000;
}
.myaccount-banner:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 40%);
    position: absolute;
    left: 0;
    top: 0;
}
.myaccount-banner img {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    bottom: 0;
    z-index: -1;
}
.myaccount-banner .inner-banner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    height: 100%; position: relative;
    z-index: 9;
}
.myaccount-banner h2 {
    font-size: 36px;
    color: #fff;
    line-height: 1;
    margin: 0 0 10px; font-weight: 600; text-transform: uppercase;
}
.myaccount-banner p {
    font-size: 15px;
    color: #fff;
}

.page-banner {
    padding: 60px 20px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    background: #ffffff;
    color: #000000;
}
/* .page-banner:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 60%);
    position: absolute;
    left: 0;
    top: 0;
} */
.page-banner img {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    bottom: 0;
    z-index: -1;
}
.page-banner .inner-banner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    height: 100%; position: relative;
    z-index: 9;
}
.page-banner h2 {
    font-size: 36px;
    color: #ffffff;
    line-height: 1;
    margin: 0 0 10px; 
    font-family: 'poppinsbold';
    text-transform: uppercase;
}
.page-banner p {
    font-size: 15px;
    color: #fff;
}
.cms-page {
    margin: 0px 0 30px;
    padding: 30px 0 0;
}

.cms-page .container {
    background: #fff;
    padding: 40px 20px 30px;
}

.cms-page h3 {
    font-size: 27px;
    color: #040507;
}

.page-banner .inner-banner-content p:last-child {
    margin: 0;
}
.hobby_list h5 {
    margin: 8px 0 12px;
}
.hobby_list ul {
    padding: 0; margin: 0;
}
.hobby_list ul li {
    list-style: none;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}
.hobby_list ul li a {
    display: inline-block;
    color: #a09e9e;
    font-weight: 600;
    font-size: 15px;
    background: #efedec;
    padding: 8px 30px;
}
.hobby_list ul li a:hover, .hobby_list ul li a.active {
    background: #e2b014;
    color: #fff;
}

.myacc_dropmenu h3 {
    color: #000;
    text-align: center;
}
.myacc_dropmenu .view_member {
    padding: 0 !important;
}
.myacc_dropmenu .view_member ol {padding: 0 20px 10px 30px;}
.myacc_dropmenu li a:hover {
    background: none !important;
    color: #e2b014 !important;
}

.sprite_setting.gift {
    width: 17px;
    height: 17px;
    background-position: -364px -284px;
}
.setting_menu_list li a:hover .setting_menu_list_icon.setting_icon {
    background-position: -392px -259px;
}
.drop_div_nfc .list_nfcn > div {
    width: 100% !important;
}
.setting_menu_list ul.myacc_dropmenu li a {
    color: #fff;
}
.setting_menu_list ul.myacc_dropmenu li a:hover {
    color: #e2b014 !important;
}

.setting_menu_list ul.myacc_dropmenu li .tg-list a{ color: #fff !important; }
.setting_menu_list ul.myacc_dropmenu li .tg-list a:hover{ color: #e2b014 !important; }

.tnk-chk-order {
    padding: 20px 0 0;
}

ul.mainacc_menulist.mob_menu{ display: none; }

/* -=-=-=-=-=-=-=-= My Account Section End -=-=-=-=-=-=-=-= */

/* -=-=-=-=-=-=-=-= Product Details Start -=-=-=-=-=-=-=-= */

.product_chosen_inner .tab-content {
    width: 100%;
}
.tab-content .compo_mdfselect_maindiv .custom-radio-btncls .components_selct .custom_radio {
    padding: 10px 30px 10px 0;
    display: block;
}
.tab-content .compo_mdfselect_maindiv .custom-radio-btncls .components_selct .custom_radio input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}
.tab-content .compo_mdfselect_maindiv .custom-radio-btncls .components_selct .custom_radio span {
    padding: 0;
}
.tab-content .compo_mdfselect_maindiv .custom-radio-btncls .components_selct .custom_radio span {
    position: relative;
    padding: 0;
    pointer-events: none;
    color: #353535;
    display: block;
}
.tab-content .compo_mdfselect_maindiv .custom-radio-btncls .components_selct .custom_radio span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #d0cfcb;
    left: 0;
    top: 1px;
    pointer-events: none;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}
.tab-content .compo_mdfselect_maindiv .custom-radio-btncls .components_selct .custom_radio span:before {
    right: 0;
    left: 100%;
}
.tab-content .compo_mdfselect_maindiv .custom-radio-btncls .components_selct .custom_radio input[type=radio]:checked+span:before {
    background: url(../images/rado-select.png) no-repeat;
    z-index: 1;
    border: 0;
}
.tab-content .compo_mdfselect_maindiv .custom-radio-btncls .components_selct .custom_radio span:after{ display: none; }
.tab-content .compo_acc_innerdiv {
    padding: 10px 0px 0px 12px;
}

.prodet_bansec .slick-slider .slick-dots {
    display: flex!important;
    flex-flow: wrap;
    justify-content: flex-start;
    flex-direction: row;
    padding: inherit;
}

.prodet_bansec .slick-slider .slick-dots li:first-child {
    padding: 10px 0;
}

.prodet_bansec .slick-slider .slick-dots li {
    position: relative;
    padding: 10px 0 10px 10px;
    cursor: pointer;
    max-width: 143px;
    list-style: none;
}

.prodet_bansec .slick-slider .slick-dots li img {
    display: block;
    max-width: 100%;
    height: 145px;
    border: 3px solid #49190d;
}
.prodet_bansec .slick-slider .slick-dots li.slick-active img {
    border-color: #e2b014;
}

.related-tags ul li, .product-tags-list ul li{ background-color: #e2b014; padding: 3px 15px; font-family: 'poppinsregular'; }

.special-sec span.special {
    max-width: inherit;
    margin: 0 5px 9px;padding: 10px 15px;
    display: inline-block;
    width: auto;
}

/* -=-=-=-=-=-=-=-= Product Details End -=-=-=-=-=-=-=-= */

/* -=-=-=-=-=-=-=-= Mobile Menu Start -=-=-=-=-=-=-=-= */

.mobile-menu {
    position: fixed;
    padding: 0;
    overflow: hidden;
    background: #ffffff;
    height: 100vh;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    width: 100vw;
    z-index: 99999;
    max-width: 520px
}

.mobile-menu .menu {
    list-style: none;
    padding: 0;
    margin: 0 0 30px
}

.mobile-menu .menu li {
    list-style: none;
    position: relative
}

.mobile-menu .mobile-menu-body {
    overflow: hidden;
    overflow-y: auto;
    padding: 50px 40px 40px;
    height: 100%
}

.mobile-menu.open {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.mobile-menu.open .menu li.active a, .mobile-menu.open .menu li:hover a{
    color: #e2b014;
}

.mobile-menu.open .menu a {
    -webkit-animation: a .4s .2s backwards;
    animation: a .4s .2s backwards
}

.mobile-menu.open .menu li:nth-of-type(2) a {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.mobile-menu.open .menu li:nth-of-type(3) a {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.mobile-menu.open .menu li:nth-of-type(4) a {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.mobile-menu.open .menu li:nth-of-type(5) a {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}
.mobile-menu.open .menu li:nth-of-type(6) a {
    -webkit-animation-delay: .7s;
    animation-delay: .7s
}
.mobile-menu.open .menu li:nth-of-type(7) a {
    -webkit-animation-delay: .8s;
    animation-delay: .8s
}

.mobile-menu.open .menu li:nth-of-type(8) a {
    -webkit-animation-delay: .9s;
    animation-delay: .9s
}

.mobile-menu.open .menu li:nth-of-type(9) a {
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

.mobile-menu .toggle-submenu-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    display: block;
    height: 60px;
    cursor: pointer
}

.mobile-menu .toggle-submenu-button:before {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg)
}

.mobile-menu .toggle-submenu-button:after,
.mobile-menu .toggle-submenu-button:before {
    background: #fff;
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 14px;
    z-index: 1;
    content: ""
}

.mobile-menu .toggle-submenu-button:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:after {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn)
}

.mobile-menu .mobile-menu-close span {
    cursor: pointer;
    display: block;
    width: 22px;
    height: 22px;
    position: relative
}

.mobile-menu .mobile-menu-close span:before {
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg)
}

.mobile-menu .mobile-menu-close span:after,
.mobile-menu .mobile-menu-close span:before {
    background: #000000;
    display: block;
    height: 2px;
    margin-top: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 24px;
    z-index: 2;
    content: ""
}

.mobile-menu .mobile-menu-close span:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.mobile-menu .mobile-menu-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 0;
    position: fixed;
    top: 0;
    right: 40px;
    left: 40px;
    height: 50px;
    background: #ffffff;
    z-index: 2;
    text-align: right;
    border-bottom: .0625rem solid rgba(255,255,255,0.22);
}

.mobile-menu .menu a {
    display: block;
    font-size: 17px;
    color: #000000 ;
    -webkit-transform: translateZ(0);
    transform: translateZ(0); font-weight: 600;
    padding: 15px 40px 15px 50px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.mobile-menu .menu li img {
    position: absolute;
    left: 10px;
    top: 10px;
}
.mobile-menu .menu a:hover {
    color: #000; background: #fff;
}

.mobile-menu .menu .sub-menu {
    display: none;
    padding: 0
}

.mobile-menu .menu .sub-menu li {
    padding-right: 0
}

.mobile-menu .menu .sub-menu li:last-child {
    border-bottom: 0
}

.mobile-menu .menu .sub-menu a {
    font-family: 'poppinsregular';
}

.mobile-menu .menu li {
    border-bottom: .0625rem solid rgba(255,255,255,0.22);
    padding-right: 0
}

@-webkit-keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

/* -=-=-=-=-=-=-=-= Mobile Menu End -=-=-=-=-=-=-=-= */

.setting_menu_list.mobile_sec {
    display: none;
}

/* -=-=-=-=-=-=-=-= Notifications Page End -=-=-=-=-=-=-=-= */
.nfcn-list-items {
    width: 100%;
    padding: 40px 0;
}
.nfcn-list-items ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.nfc-bx {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    margin: 18px 0;
    padding: 20px;
    border: 1px solid #e0e0e0;
}
.nfc-bx.done-notify{ background: #f3f3f3; }
.nfc_left {
    width: 80%;
    position: relative;
    padding: 0 20px 0 50px;
}
.nfc_right {
    width: 20%;
    text-align: right;
}
.nfc_left img {
    position: absolute;
    left: 0;
    top: 4px;
}
.nfc_left h4 {
    margin: 0 0 15px;
}
.nfc_left h4 span {
    color: #e87b1e;
}
.nfc-bx h6 {
    margin: 0 0 10px; text-transform: lowercase; font-weight: 600;
}
.nfc-bx .button {
    margin: 10px 0px 10px 0px;
    padding: 12px 18px 12px;
    font-size: 16px;
}

/* -=-=-=-=-=-=-=-= Notifications Page End -=-=-=-=-=-=-=-= */

/* -=-=-=-=-=-=-=-= Contact Us Page Start -=-=-=-=-=-=-=-= */

.contact_section{
    position: relative;
    max-width: 1200px;
    margin: 100px auto;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 35px -5px rgb(0 0 0 / 20%);
    -webkit-transition: all .4s ease-in;
    -o-transition: all .4s ease-in;
    transition: all .4s ease-in;
    overflow: hidden !important;
    z-index: 11;
}

.contact_section:before {
    content: "";
    position: absolute;
    bottom: -300px;
    left: -200px;
    width: 700px;
    height: 700px;
    background: radial-gradient(rgb(206 139 18 / 20%), transparent 65%);
    border-radius: 50%;
    z-index: -1;
}

.contact_section:after {
    content: "";
    position: absolute;
    top: -300px;
    right: -200px;
    width: 700px;
    height: 700px;
    background: radial-gradient(rgb(228 180 20 / 20%), transparent 65%);
    border-radius: 50%;
    z-index: -1;
}

.contact_section_inner{
    padding: 120px 0;
    position: relative;
    z-index: 2;
}

.contact_section_inner:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../images/contact-bg.png) center center;
    background-size: 50%;
    z-index: -1;
}

.contact_top h2 {
    font-family: 'poppinssemibold';
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 70px;
    position: relative;
    text-align: center;
    font-size: 34px;
}

.contact_bottom{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.contact_bottom_lhs{
    width: 65%;
}

.contact_bottom_rhs{
    width: 35%;
}

.contact_btm_lhsinner{
    background: #fff;
    border-radius: 15px 0 0 15px;
    border: 1px solid rgba(96, 96, 96, 0.1);
    border-right-width: 0;
    padding: 30px;
}

.contact_btm_rhsinner {
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    border-radius: 0 15px 15px 0;
    height: 100%;
    padding: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
}

.contact_btm_rhsinner:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../images/contactlhs-img.png) center center no-repeat;
    background-size: 50%;
    opacity: 0.1;
    z-index: -1;
}

.single-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
}

.part-icon {
    text-align: center;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #CB26B6;
    border-radius: 5px;
}

.part-icon img{
    width: 35px;
    height: 35px;
    margin-top: 8px;
}

.part-txt {
    width: calc(100% - 70px);
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    margin-top: -6px;
    margin-bottom: -8px;
}

.single-box .part-txt > * {
    display: block;
    font-size: 16px;
    line-height: 26px;
    color: #fff;
    margin: 0;
}

.part-txt a:hover{
    color: #000000;
}

.contact-btn .button{
    min-width: 200px;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    font-family: 'poppinsmedium';
}

.contact_btm_lhsinner input, .contact_btm_lhsinner textarea{
    margin: 0 0 5px;
}

ul.outlets-list-row {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.outlets-list-column {
    padding: 15px 15px 0;
    width: 48%;
    float: left; margin: 0 1% 20px; background: #f2f0eb;
}
.outlets-list-column .outlet-icon {
    max-height: 180px;
    object-fit: cover;
    width: 100%;
}
.outlet-item {
    padding: 25px 30px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    border: 1px solid #f2f0eb;
    border-radius: 5px;
}
.outlets-list-row h3 {
    font-size: 16px;
}
.outlet-item-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.outlet-item-list ul li {
    position: relative;
    padding: 0 0 0 28px;
}
.outlet-item-list ul li img {
    position: absolute;
    left: 0;
    top: 3px;
}
.outlet-item-list ul li p{ margin: 0 0 5px; font-size: 14px; }
.outlet-item-list ul li p img {
    position: inherit;
}
.controls .cont-reselect-box__control {margin: 0 0 10px;}
.controls .cont-reselect-box__control input[type="text"] {height: 24px;}
.controls .cont-reselect-box__control .cont-reselect-box__menu {text-align: left;}
.form-feedback {
    background: #f2f0eb;
    padding: 25px 30px;
}
.rating-fback {
    font-size: 14px;
    padding: 12px 0 0;
}
.rating-fback p {
    margin: 0;
}
.fback-item {
    position: relative;
    padding: 0 140px 0 0;
    text-align: left;
}
.fback-item > div {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 20px;
}
.form-feedback .form-group {
    text-align: center;
    margin: 20px 0;
}
.form-feedback .form-group .btn {
    padding: 12px 40px;
    font-size: 18px;
    margin: 0 auto;
    font-weight: 600;
}

/* -=-=-=-=-=-=-=-= Contact Us Page End -=-=-=-=-=-=-=-= */

.home-banner .banner-info h4 span{ font-family: 'poppinsbold'; }

.re_select .custom-react-selector-box__menu {
    z-index: 10;
}

input[type=file] {max-width: 100%;}

.react-tabs__tab-list {
    border-bottom: 0px solid transparent !important;
    margin: 0 0 30px !important; 
    padding: 0 !important;
    width: 100%;
    display: flex;
    justify-content: center;
}

.react-tabs__tab {
    width: 50%;
    text-align: center;
    margin-bottom: 0;
    text-transform: capitalize;
    border: 0px solid transparent !important;
    border-bottom: 5px solid #e4e4e4 !important;
    cursor: pointer;
    padding: 10px 20px !important;
    display: inline-block !important;
    color: #181818 !important;
    font-size: 22px !important;
    font-weight: 800 !important;
}
.react-tabs__tab--selected {
    background: transparent !important;
    border-bottom: 5px solid #181818 !important;
    color: #181818 !important;
    border-radius: 0 !important;
}

.qty_bx span.qty_minus {
    left: 0px;
}

.qty_bx span.qty_plus {
    right: 0px;
    background: #000;
}

.qty_bx span.qty_minus:before, .qty_bx span.qty_plus:after, .qty_bx span.qty_plus:before {
    position: absolute;
    content: "";
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
}

.qty_bx span.qty_minus:before {
    background: #000;
    width: 12px;
    height: 2px;
}

/************** 12-09-22 START *******************/
header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
    margin: auto;
    background: #fff;
}

.header-top-cls {
    padding: 10px 0;
    position: relative;
}

.logo-main-section {
    position: relative;
    display: flex;
    justify-content: space-between;
    z-index: 9;
    align-items: center;
}

.hmenu_list{
    padding: 0;
}

.hmenu_actions {
    padding: 0;
    display: flex;
    align-items: center;
}

.htico_wishlist img {
    width: 29px;
    height: 25px;
}

.htico_search img {
    width: 25px;
    height: 25px;
    display: block;
}

.htico_cart>a img {
    width: 26px;
    height: 28px;
    display: block;
}

.htico_wishlist, .htico_cart{
    position: relative;
    margin-left: 15px;
}

.banner-info{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 680px;
    width: 100%;
    margin: 0 auto;
}

.banner-info h2{
    font-family: 'billy_ohioregular';
    font-size: 82px;
    color: #fff;
    margin: 0 0 15px;
}

.banner-info p{
    color: #fff;
    line-height: 1.5;
}

.home-category-inner{
    background: url('../images/category-bg.png')no-repeat;
    background-size: cover;
    padding: 40px 40px;
    border-radius: 0 0 40px 40px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
}

.home-feature-heading {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.pd-top-50{
    padding-top: 50px;
}

.home-feature-heading a{
    font-weight: 500;
    text-transform: uppercase;
    font-size: 16px;
    padding-right: 35px;
    position: relative;
}

.home-feature-heading a:hover{
    color: #e2b014;
}

.home-feature-heading a:hover:before{
    opacity: 0.6;
}

.home-feature-heading h4{
    margin: 0 0 15px;
}

.home-feature-heading a:before {
    content: "";
    position: absolute;
    top: 5px;
    right: 0;
    background: url(../images/black-right-arrow.svg) no-repeat;
    width: 24px;
    height: 11px;
}

.feature-products-div{
    position: relative;
    z-index: 2;
    text-align: center;
    background: #fff;
    transition: all .4s linear;
    display: flex;
    flex-direction: column;
    border: 2px solid #f3f3f3;
    height: 100%;
}

.feature-products-image{
    height: 204px;
}

.feature-products-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.feature-products-contant {
    padding: 10px 20px 110px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: calc(100% - 204px);
    background: #fff;
    transition: all .3s linear;
}

.product-title-maindiv {
    position: relative;
    margin: 0 0 10px;
    padding: 0;
}

.product-title h3 {
    font-size: 18px;
    margin-bottom: 10px;
    font-family: 'poppinssemibold';
    text-transform: inherit;
}

.product-short-description {
    color: #535353;
    font-size: 14px;
    margin: 0;
    line-height: 1.3;
    /* flex: 1 1 auto; */
}

.product-short-description p{
    margin: 0;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    display: box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-word;
}

.product-item-tag ul{
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.product-item-tag ul li {
    margin: 2px 5px;
    display: inline-block;
    text-align: center;
    background: #fdc855;
    color: #fff;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: 'poppinsregular';
}

.product-item-tag{
    margin: 0 0 10px;
}

.products-ordernow-action {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.products-ordernow-action .product-price {
    margin-bottom: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.price_disc{
    color: #c4c3c2;
    text-decoration: line-through;
    font-size: 18px;
    display: block;
    line-height: 2;
    font-family: 'poppinsregular';
}

.product-price h3, .product-price h4{
    font-family: 'poppinssemibold';
    font-size: 18px;
    margin: 0;
}

.home-feature-inner .owl-dots {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 20px 0 0;
}

.home-feature-inner .owl-dot {
    margin: 0 3px;
}

.home-feature-inner .owl-dot span {
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid #000;
    background: transparent;
    border-radius: 50%;
}

.home-feature-inner .owl-dot.active span {
    background: #fdc855;
    border: 1px solid #fdc855;
}

.about-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.about-left-container {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.about-right-container{
    width: 50%;
}

.about_more{
    position: relative;
    display: inline-block;
    padding: 14px 28px;
    margin: 25px 0 10px;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    color: #000000;
    text-transform: uppercase;
    font-size: 15px;
    font-family: 'poppinsmedium';
    border-radius: 5px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
    min-width: 170px;
}

.about_more:hover{
    background: #000;
    color: #fff;
}

/* .about_more:before {
    position: absolute;
    content: '';
    width: 0%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: -1;
    transition: all 500ms ease;
    background: -webkit-linear-gradient(0deg, #cc8912, #ffdf61 100%);
}

.about_more:hover:before {
    width: 100%;
} */

.about-row .item:first-child {
    width: 70%;
    z-index: 3;
}

.about-row .item figure img {
    box-shadow: -1px 0 20px 1px rgb(0 0 0 / 10%);
    border-radius: 20px;
}

.about-row .item:nth-child(2) {
    width: 70%;
    margin-top: -55%;
    margin-left: 30%;
    z-index: 4;
}

.about-left-one span{
    letter-spacing: 4px;
    font-size: 14px;
    font-family: 'poppinsmedium';
    text-transform: uppercase;
}

.about-left-one .about-left-span{
    color: #e2b014;
}

.about-left-one p{
    margin: 0;
}

.about-left-one h1{
    font-family: 'billy_ohioregular';
    font-size: 64px;
    color: #e2b014;
}

.home-testimonial h1{
    font-family: 'billy_ohioregular';
    text-align: center;
    margin: 0 0 40px;
    color: #e2b014;
}

.home-blog-slider .owl-stage {
    display: flex;
}

.home-blog-slider .owl-item {
    opacity: .3;
}

.home-blog-slider .owl-item>div {
    height: 100%;
}

.inner-testimonials {
    position: relative;
    font-size: 15px;
    opacity: .26;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    padding: 20px 25px 0;
}

.testi-content {
    text-align: center;
}

.home-category-inner .menu-list-intvl, .procuct-category-inner .menu-list-intvl{
    text-align: center;
    line-height: 1.1;
}

.home-category-inner .menu-title-link, .procuct-category-inner .menu-title-link{
    font-family: 'poppinsmedium';
    text-transform: capitalize;
}

.home-category-inner .menu-title-link div, .procuct-category-inner .menu-title-link div{
    border-radius: 50%;
    border: 4px solid transparent;
    overflow: hidden;
    margin: 0 0 10px;
}

.home-category-inner .menu-list-intvl:hover a div, .home-category-inner .menu-list-intvl.active div,
.procuct-category-inner .menu-list-intvl:hover a div, .procuct-category-inner .menu-list-intvl.active div{
    border: 4px solid #d39412;
}

.home-category-inner .menu-list-intvl:hover a, .home-category-inner .menu-list-intvl.active a,
.procuct-category-inner .menu-list-intvl:hover a, .procuct-category-inner .menu-list-intvl.active a{
    font-family: 'poppinsbold';
    color: #d39412;
}

.home-category-inner img, .procuct-category-inner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-align: center;
}

/************************ PRODUCT LIST PAGE ********************************/

.procuct-category-inner{
    background: url('../images/category-bg.png')no-repeat;
    background-size: cover;
    background-position: center;
    padding: 40px 40px;
    margin: 30px 0;
    border-radius: 20px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
}

#responsive_view{
    display: none;
}

.brand-title-link div{
    border: 2px solid #f2f2f0;
    border-radius: 15px;
    width: 121px;
    height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.procuct-brand-main .owl-item .brand-title-link img{
    width: auto;
    height: auto;
}

.mobile-search{
    display: none;
}

.product-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 30px;
    margin: 0;
}

.filters-lhs, .filters-rhs {
    position: relative;
}

.filters-sort{
    display: block;
    color: #000000;
    text-transform: uppercase;
}

.filter-act {
    position: relative;
    padding-right: 40px;
    text-transform: capitalize;
    letter-spacing: .5px;
    margin-top: 10px;
    cursor: pointer;
    font-family: 'poppinsmedium';
}

.product-top-main{
    padding-bottom: 50px;
}

.filters-lhs .filter-act:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    right: 0;
    top: -5px;
    font-size: 28px;
    line-height: 1;
    font-weight: 400;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.filters-rhs .filter-act:after{
    position: absolute;
    content: "";
    right: 0;
    top: -7px;
    line-height: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    background: url(../images/filter.svg) no-repeat;
    width: 23px;
    height: 28px;
}

.filters-rhs .filters-sort{
   opacity: 0;
}

.filters-lhs ul, .filters-rhs ul {
    display: none;
    padding: 0;
    margin: 0;
    background: #fff;
    border: 1px solid #e4e4e4;
    position: absolute;
    top: 100%;
    left: 0;
    list-style: none;
    width: 160px;
    z-index: 9;
}

.hsearch_sec_prolist.open .search_prodresult{
    width: 480px;
}

.filters-lhs ul li a, .filters-rhs ul li a {
    display: block;
    padding: 8px 10px;
    line-height: 1.2;
    border-bottom: 1px solid #f4f4f4;
    font-size: 13px;
    font-weight: 500;
}

.filters-lhs ul.active, .filters-rhs ul.active {
    display: block;
}

.filters-lhs ul li:hover a, .filters-rhs ul li:hover a{
    color: #e2b014;
}

.filters-rhs{
    display: flex;
    justify-content: space-between;
}

.filters-rhs ul {
    left: inherit;
	right: 0;
}

.products-ordernow-action .button{
    position: relative;
    padding: 14px 14px;
    margin: 0 auto;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    text-transform: uppercase; 
    font-size: 15px;
    font-family: 'poppinsmedium'; 
    border-radius: 5px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
    min-width: 150px;
}

/************** CHECKOUT START *********************/

.checkout-birthday-main{
    border-top: 2px solid #f4f4f4;
    height: 100px;
    padding: 25px 5px 0;
    text-align: center;
}

.checkout-birthday-main h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0 20px;
    padding-top: 10px;
    font-size: 26px;
    line-height: 1;
    font-family: 'poppinsmedium';
}

.checkout-birthday-main h3>div {
    text-align: center;
    font-family: 'poppinssemibold';
}

.checkout-birthday-main h3>div span {
    color: #000000;
    font-family: 'poppinsmedium';
    font-size: 16px;
    text-transform: capitalize;
}

.checkout-birthday-main h3 img {
    max-height: 42px;
    margin: 0 0 0 15px;
    position: relative;
    top: 5px;
}

.check_out_fourcommon {
    width: 100%;
    margin: 0 auto 50px;
}

.checkoutpage_form_outer {
    width: 100%;
    margin: 0 auto;
}

.checkout-total {
    width: 100%;
    margin: 25px auto 0;
}

.cdd-details {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: space-between;
}

.cdd-details-lhs, .cdd-details-rhs {
    width: 49.6%;
    position: relative;
    padding: 0;
    background: #fff;
    box-shadow: 0 1px 21px 0 rgb(0 0 0 / 18%);
}

.checkout-heading {
    background: #2f2f2e;
    color: #fff;
    font-size: 17px;
    padding: 10px;
    position: relative;
    font-family: 'poppinssemibold';
}

.checkout-body-section {
    padding: 20px;
}

.checkout-delivery-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.checkout-delivery-lhs {
    width: 49%;
}

.checkout-delivery-lhs .chk_hea, .checkout-delivery-rhs .chk_hea {
    display: block;
}

.chk_heading {
    position: relative;
    font-size: 15px;
    text-transform: uppercase;
    line-height: 1;
    padding: 0;
    margin: 0 0 10px;
    color: #000000;
    font-family: 'poppinssemibold';
}

.checkout-delivery-details p{
    margin: 0;
    font-size: 15px;
}

.checkout-delivery-rhs {
    width: 49%;
    text-align: right;
}

.controls-three-small {
    margin: 10px 0;
}

.controls-three-small, .cthree-small-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.controls-three-small span {
    width: 20px;
    text-align: left;
}

.cthree-small-inner {
    width: calc(100% - 20px);
}

.controls-three-small .focus-out {
    width: 48.5%;
}

.controls-three-small input[type=text] {
    height: 40px;
    font-family: 'poppinssemibold';
    text-align: center;
    margin: 0;
    padding: 10px 5px;
}

.checkout-control-group-top a {
    float: right;
    font-size: 14px;
    color: #000;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 5px 0 0;
    display: inline-block;
    vertical-align: top;
    transition: all .3s linear;
}

.checkout-control-group-top a:hover{
    color: #e2b014;
}

.checkout-control-group-middle {
    margin: 0;
    border: 0;
    padding-top: 25px;
}

.checkout-billing-address {
    padding: 25px 0 20px;
    width: 100%;
    border-bottom: 1px solid #eeeded;
}

.checkout-billing-address .chk_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    background: #f5f5f5;
    color: #181818;
}

.chk_heading span {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: 'poppinsmedium';
    text-transform: capitalize;
    padding-right: 30px;
    color: #000000;
}

.checkout-total .chk_heading .custom_checkbox, .checkout-total .send-as-gift .custom_checkbox {
    position: absolute;
    top: 10px;
    right: -5px;
}

.checkout-total .custom_checkbox .addon_list_single {
    border-radius: 50%;
}

.check-billing {
    padding: 20px 0 0;
}

.controls-three-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0;
}

.controls-three-top>div {
    width: 31.3333%;
}

.controls-three-top .form-group {
    margin-bottom: 0;
}

.controls-three-top>div input{
    margin-bottom: 0;
}

.controls-three-top span {
    width: 10px;
    text-align: center;
    vertical-align: middle;
    line-height: 48px;
}

.controls-single {
    margin-top: 15px;
}

.checkout_section .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.checkout-billing-address {
    padding: 25px 0 20px;
    width: 100%;
    border-bottom: 1px solid #eeeded;
}

.controls-twoin{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.controls-twoin .form-group {
    width: 47%;
    margin-bottom: 0;
}

.controls-twoin .focus-out {
    width: 100%;
}

.controls-twoin .form-control {
    margin-bottom: 0;
}

.checkout-control-group-bottom {
    padding: 25px 0 0;
}

.checkout-control-group-bottom .chk_heading{
    display: block;
}

.checkout-control-group-bottom textarea {
    padding: 10px 15px;
    height: 120px;
    font-size: 15px;
    width: 100%;
    outline: 0;
}

.cart-header {
    padding: 0;
    background: inherit;
    border: 0;
    margin: 0;
}

.cout_body{
    padding: 20px;
}

.order-title-clear h5{
    color: #fff;
    margin: 0;
    font-size: 15px;
    letter-spacing: .3px;
    font-family: 'poppinssemibold';
}

.checkout_modify_cart .hclear_cart{
    color: #fff;
    margin: 0;
    font-size: 13px;
    letter-spacing: .3px;
    font-family: 'poppinssemibold';
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
}

.mobile-cart {
    display: none;
}

.ckout-details-main .product-details{
    border-bottom: 2px solid #e6e6e6;
    padding: 15px 0;
}

.ckout-details-main .product-details:last-child{
    border: none;
}

.ckout-container-inner{
    display: flex;
    justify-content: space-between;
}

.ckout-container-inner .cart_left {
    width: calc(100% - 180px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.ckout-container-inner .cart_info {
    width: calc(100% - 115px);
}

.cart_extrainfo p, .notes-block,  .cart_extrainfo, .cart_info.text-left p{
    margin: 0;
    color: #959595;
    font-size: 13px;
    padding-left: 10px;
    font-family: 'poppinsmedium';
}

.cart_extrainfo p b {
    color: #959595;
    font-family: 'poppinssemibold';
    font-size: 13px;
    padding-left: 10px;
}

.ckout-container-inner .cart_right {
    width: 175px;
    padding: 0;
}

.chekout_cart_bar .cart_footer {
    background: #f6f6f6;
    padding: 30px 20px 10px;
    margin: 0;
}

.checkout-redeem {
    position: relative;
    margin-bottom: 20px;
}

.redeem-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.redeem-col {
    width: 49.6%;
    background: #fff;
    padding: 25px 35px 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    border: 2px solid #e9e9e9;
}

.redeem-item-hea {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.redeem-item-hea-inner {
    width: calc(100% - 260px);
}

.redeem-item-hea .points {
    width: 260px;
    background: #1c1c1c;
    color: #fff;
    padding: 8px 2px;
    max-width: 100%;
    display: block;
    text-align: center;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    font-size: 13px;
}

.redeem-item-hea h4 {
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-family: 'poppinsbold';
}

.redeem-item-hea span {
    font-size: 13px;
}

.redeem-item-hea .points b {
    color: #fbd008;
    font-family: 'poppinsbold';
    font-size: 14px;
}

.redeem_apply {
    position: relative;
    margin-top: 5px;
}

.redeem_apply .focus-out {
    padding-right: 140px;
}

.redeem_apply .button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    z-index: 11;
    margin: 0;
    padding: 17px 22px;
    min-width: 150px;
    line-height: 14px;
    font-family: 'poppinsmedium';
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
}

.chk-payment {
    padding: 40px 30px 30px;
    text-align: center;
    border: 2px solid #e9e9e9;
    border-radius: 10px;
}

.chk-addonpro-title h3, .chk-payment-title h3 {
    font-size: 18px;
    text-transform: inherit;
    text-align: center;
    font-family: 'poppinssemibold';
    margin-bottom: 20px;
}

.chk-payment-col-radio {
    padding: 0;
    list-style: none;
    max-width: 1000px;
    margin: 0 auto;
}

.chk-payment-col-radio, .chk-payment-col-radio li {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.chk-payment-col-radio, .chk-payment-col-radio li {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.chk-payment-col-radio li {
    padding: 10px 15px 10px 45px;
    border: 1px solid #d0cfcb;
    border-radius: 7px;
    width: 22%;
    -webkit-box-align: center;
    align-items: center;
    margin: 0 1% 10px;
}

.chk-payment-col-radio li input[type=radio] {
    position: absolute;
    left: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.chk-payment-col-radio .radio_con {
    display: flex;
    font-size: 15px;
    text-transform: uppercase;
    font-family: 'poppinsmedium';
}

.chk-payment-btn-row {
    margin: 30px auto 0;
    max-width: 470px;
}

.chk-payment-btn-row .button {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 17px;
    padding: 15px 10px;
    border-radius: 0;
    letter-spacing: 1px;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
    font-family: 'poppinsmedium';
}

/********************** THANKYOU PAGE *****************************/

/* .thankyou-main-div {
    background: #f4f4f4;
} */

.tnkyou-pagediv{
    max-width: 620px;
    margin: 0 auto;
    background: #FFF;
    width: auto;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
}

.tnkyou-header {
    text-align: center;
    background: #f5f5f5;
    padding: 30px 15px;
}

.tnkyou-header img{
    max-width: 85px;
}

.tnkyou-header h2 {
    margin: 25px 0 0px 0;
    font-size: 36px;
}

.tnkyou-header p {
    font-size: 20px;
    margin: 0;
}

.thank-order-top{
    padding: 30px 15px;
}

.thank-order-top h3{
    text-transform: uppercase;
    margin: 0 0 15px;
    font-size: 30px;
}

.tnk-order h3{
    font-size: 24px;
    font-family: 'poppinssemibold';
    margin: 0 0 10px;
}

.thank-details-middle{
	border-bottom: 0;
    margin: 20px 0 15px;
}

.tnk-common-box{
    padding: 0 15px;
}

.tnkyou-product-div{
    padding: 30px 15px 0;
}

.tnkyou-productdiv-in:last-child{
    border-bottom: 0;
}

.tnkyou-productdiv-in {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
}

.tnkyou-productdivin-left {
    width: calc(100% - 180px);
    display: flex;
    align-items: flex-start;
}

.tnkyou-leftimg {
    width: 90px;
    margin: 0 15px 5px 0;
}

.tnkyou-leftimg img{
    width: 100%;
    height: 100%;
}

.tnkyou-leftcontnt {
    max-width: calc(100% - 105px);
}

.tnkyou-leftcontnt h4 {
    font-size: 15px;
    margin: 0 0 6px;
}

.tnkyou-roductdivin-right{
    width: 175px;
    text-align: right;
}

.tnkyou-roductdivin-right p {
    margin: 0;
    font-size: 16px;
    font-family: 'poppinssemibold';
}

.tnk_cart_footer{
    padding: 20px 15px;
}

.tnk-btnp-div .button{
    display: block;
    width: 100%;
    border-radius: 0;
    margin: 0;
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
}

.btn-medium {
    min-width: 190px;
}

.cart_row.cart-header-second {
    margin: 15px 0 0 0;
}

.cart_row.cart-header-second h3 {
    font-weight: 600;
}

.cart-direction {
    margin: 0 0 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}

.cart-direction:after {
    background-image: -webkit-gradient( linear, left top, right top, color-stop(50%, #949292), color-stop(0%, transparent));
    background-image: linear-gradient( to right, #949292 50%, transparent 0%);
    background-size: 8px 4px;
    background-repeat: repeat-x;
    background-position: 0% bottom;
    -webkit-animation-name: border-dance;
    animation-name: border-dance;
    -webkit-animation-duration: 25s;
    animation-duration: 25s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    content: "";
    position: absolute;
    left: 50px;
    display: block;
    height: 2px;
    right: 30px;
    margin: auto;
}

@-webkit-keyframes border-dance {
    from {
        background-position: 0% bottom;
    }
    to {
        background-position: 100% bottom;
    }
}

@keyframes border-dance {
    from {
        background-position: 0% bottom;
    }
    to {
        background-position: 100% bottom;
    }
}

/* .copyright-top {
    position: relative;
}

.running-cycle {
    overflow: hidden;
    width: 100%;
    height: 120px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.running-cycle div {
    background-image: url(../images/delivery-boy.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 150px;
    width: 150px;
    height: 150px;
    position: absolute;
    left: 0;
    bottom: -5px;
    animation: running-anim 20s linear infinite;
    z-index: 1;
}

@-moz-keyframes running-anim {
    0% {
        left: -30%
    }
    100% {
        left: 100%
    }
}

@-webkit-keyframes running-anim {
    0% {
        left: -30%
    }
    100% {
        left: 100%
    }
}

@keyframes running-anim {
    0% {
        left: -30%
    }
    100% {
        left: 100%
    }
} */

.product_list_sec{
    height: 100%;
}

.no-product .no-recrds-found{
    min-height: 150px;
    flex-wrap: wrap;
}

.no-product .no-recrds-found h2{
    font-family: 'poppinsmedium';
    text-transform: uppercase;
    font-size: 28px;
}

.no-product .no-recrds-found p{
    font-size: 17px;
}

.promotion-inmain{
    height: 100%;
}

.trigger_menu {
    display: none;
}

.trigger_menu .icon-bar {
    display: block;
    background: #000000;
    height: 2px;
    transition: all .4s;
}

.trigger_menu .icon-bar+.icon-bar {
    margin-top: 6px;
}

.home-category .owl-dots {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 15px 0 0;
}

.home-category .owl-dots .owl-dot {
    margin: 0 3px;
}

.home-category .owl-dots .owl-dot span {
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid #000;
    background: transparent;
    border-radius: 50%;
}

.home-category .owl-dot.active span {
    background: #e2b014;
    border: 1px solid #e2b014;
}

.hcategory_sec{
    display: none;
}

.mbacc_mslidersec {
    display: none;
}

.myacc_catg-main {
    position: relative;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #e4e4e4;
}

.myacc_cat_name {
    padding: 12px 50px 12px 12px;
    color: #000;
    font-size: 16px;
    text-transform: capitalize;
    display: block;
    position: relative;
    font-family: 'poppinsmedium';
}

.myacc_cat_nspan {
    position: absolute;
    right: 15px;
    top: 12px;
    transition: all .4s;
    font-size: 13px;
    padding-right: 25px;
    z-index: 21;
}

.myacc_cat_nspan:after {
    position: absolute;
    background: url(../images/aroow_myaccountb.png) no-repeat;
    background-size: contain;
    width: 22px;
    height: 10px;
    content: "";
    right: 0;
    top: 8px;
}

.myacc_category_menu {
    background: #f4f4f4;
    position: absolute;
    left: 0;
    top: 50px;
    right: 0;
    max-height: 400px;
    opacity: 0;
    visibility: hidden;
    transition: all .4s;
    overflow: auto;
}

.myacc_category_menulist {
    list-style: none;
    padding: 0;
    margin: 0;
}

.myacc_category_menulist li {
    border-bottom: 0.5px solid rgba(13,11,6,.17);
}

.myacc_category_menulist li a {
    background: #f8f8f8;
    color: #000;
    font-size: 16px;
    padding: 12px 45px 12px 15px;
    display: block;
    text-transform: capitalize;
    font-family: 'poppinsmedium';
}

.myacc_category_menulist li:hover a{
    color: #e2b014;
}

.myacc_category_menulist li:hover .count_num_t{
    background: #e2b014;
}

.myacc_category_menu.open {
    opacity: 1;
    visibility: visible;
    z-index: 91;
}

.load_more_div {
    display: block;
    clear: both;
    width: 100%;
    text-align: center;
    padding: 30px 0 0;
}

.load_more_div .load_more_data {
    margin: 0 auto;
    min-width: 160px;
    font-family: 'poppinsmedium';
    color: #000000;
    background-image: linear-gradient(90deg,#cc8912,#e7b914,#d59812,#ffdf61);
    background-size: 300% 100%;
}

.promo-bot-left.fl p{
    margin: 0;
    font-size: 22px;
    font-family: 'poppinsbold';
    text-transform: capitalize;
}

.promo-bot-left.fl p span{
    font-size: 14px;
    font-family: 'poppinsbold';
    display: block;
}

.myacc_filter .nav > li > a:last-child{
    margin-right: 0;
}

.common-inner-product{
    margin: 50px 0 0;
}

.mbile_account_row{
    display: flex;
}

.mobile_account_lhs{
    width: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 10px;
    position: relative;
    justify-content: center;
    background: #ffffff;
}

.mobile_account_rhs {
    width: 50%;
    height: 100%;
}

.mobile_account_lhs a {
    color: #000;
    font-family: 'poppinsbold';
    padding: 0 25px 0 0;
    position: relative;
    text-transform: uppercase;
    font-size: 16px;
}

.mobie-icon {
    position: absolute;
    top: 50%;
    left: -40px;
    transform: translateY(-50%);
}

.mobie-icon img{
    width: 20px;
    height: 24px;
}

.mobile_account_lhs a:last-child {
    padding-right: 0;
}

.mobile_account_lhs a:last-child:after {
    height: 13px;
    content: "";
    width: 2px;
    display: inline-flex;
    background: #000;
    position: absolute;
    left: -16px;
    top: 4px;
}

.mobile_account_rhs a {
    padding: 10px;
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
    text-align: center;
    background: #e2b014;
    color: #000000;
    font-family: 'poppinsbold';
}

.mobile_account_menu{
    display: none;
}

.hcategory_sec {
    position: -webkit-sticky;
    position: sticky;
    top: 78px;
    z-index: 0;
}

.hcategory_selected_text {
    padding: 15px 140px 12px 15px;
    color: #000;
    text-transform: uppercase;
    display: block;
    position: relative;
    font-size: 15px;
    font-family: 'poppinsbold';
}

.hcategory_trigger {
    position: absolute;
    right: 15px;
    top: 17px;
    -webkit-transition: all .4s;
    transition: all .4s;
    line-height: 1.2;
    padding-right: 30px;
    z-index: 11;
}

.hcategory_trigger:after {
    position: absolute;
    background: url(../images/mobile_click.png) no-repeat;
    background-size: contain;
    width: 22px;
    height: 12px;
    content: "";
    right: 0;
    top: 2px;
}

.hcategory_menu {
    background: #f4f4f4;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    max-height: 400px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .4s;
    transition: all .4s;
    overflow: auto;
}

.hcategory_menu.open {
    opacity: 1;
    visibility: visible;
    z-index: 9;
    top: 43px;
}

.hcategory_menulist li a {
    background: #f8f8f8;
    color: #000;
    padding: 12px 45px 12px 15px;
    display: block;
    text-transform: uppercase;
    font-family: 'poppinsmedium';
    border-bottom: 1px solid #fcfcfc;
}

/* .hcategory_menulist li:hover a{
    color: #e2b014;
} */

.hcategory_submenu li a{
    background: #ebebeb;
}

.hcategory_submenu li a {
    color: #000;
    padding: 12px 15px 12px 35px;
    display: block;
    font-family: 'poppinsmedium';
}

.hcategory_trigger.active:after {
    background: url(../images/cross-close.png) no-repeat;
    background-size: contain;
    width: 13px;
    height: 13px;
    top: 1px;
    right: 5px;
}

.birthday-info-move{
    margin: 40px 0;
    background: #fff;
    padding: 25px 35px 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    border: 2px solid #e9e9e9;
}

.birthday-info-middle p{
    text-align: center;
}

.birthday-inpt-act{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 780px;
    margin: 0 auto;
}

.left-input-div, .right-input-div{
    width: 49%;
}

.right-input-div {
    position: relative;
}

.delevery_popup_datetime .awesome_del .form-control{
    margin: 0 0 20px;
}

.membenifits-body{
    padding: 50px 40px;
}

.view_member h3{
    text-align: center;
    color: #000000;
}

.accmenu_list>ul>li.active>a:after, .accmenu_list>ul>li>a:hover:after, 
.accmenu_list>ul li:hover .myacc_dropmenu {
    visibility: visible;
    opacity: 1;
}

.accmenu_list .myacc_dropmenu {
    position: absolute;
    left: 105%;
    top: -20px;
    list-style: none;
    background: #000;
    padding: 20px 30px 20px 20px;
    z-index: 91;
    width: 284px;
    border-radius: 7px;
    box-shadow: 0 0 35px 0 rgb(0 0 0 / 35%);
    -webkit-box-shadow: 0 0 35px 0 rgb(0 0 0 / 35%);
    opacity: 0;
    visibility: hidden;
}

.myacc_dropmenu:before {
    content: "";
    position: absolute;
    left: -13px;
    top: 0;
    background: #ccc;
    width: 20px;
    height: 100%;
    opacity: 0;
}

.myacc_dropmenu:after {
    position: absolute;
    content: "";
    border-right: 14px solid #000;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    top: 32px;
    left: -7px;
}

.accmenu_list ul.myacc_dropmenu li a {
    color: #fff;
    background: transparent;
    padding: 10px 0;
}

.myacc_dropmenu .tg-list {
    position: absolute;
    top: 9px;
    right: -12px;
    padding-top: 0;
}

.tg-list-item .tgl {
    display: none;
}

.refer_popup{
    padding: 40px 30px;
}

.member-poup-content h3{
    text-align: center;
}