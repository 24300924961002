/**** Responsive ****/

@media (min-width: 1200px){        
    .mobile_hmenu_list{ display: none;}/* Mobile cms menu */
    .hmenu_list > li:hover > a > .hdd_arrow{ opacity: 1; visibility: visible;}
}
@media(min-width: 991px){
    .ft-bt-col1, .ft-bt-col3, .ft-bt-col4,.ft-bt-col5 { width: 20%; padding: 0 20px; } 
    .ft-bt-col2{ width: 22%; padding: 0 20px; }
}
@media (min-width: 679px){
    .cur-ord-rec-mobile{ display: none; }
}   
@media (min-width: 576px){    
    .modal-dialog { margin: 1.75rem auto; }
    .modal-dialog-centered { min-height: calc(100% - (1.75rem * 2)); 
    min-height: -webkit-calc(100% - (1.75rem * 2));}
}
/**** MINWIDTH RESPONSIVE END ****/

@media (max-width: 1350px){ .container{padding-left: 15px; padding-right: 15px;} }

@media (max-width: 1280px){
    .hmenu_list>li>a{ margin: 0 2px; font-size: 13px; }
    .hsign_sec a{ font-size: 13px; }
    .hordertype_sec .hordertype_btn { margin: 0 15px 0 5px; font-size: 13px; }
    .promation_btn .button{ font-size: 12px;}
}

@media(max-width: 1199px){
    .promo-earned-col-item{ flex-flow: column; height: 100%;}
    .promo-earned-info:before{ display: none; }
    .promation_btn .button { padding: 12px 4px; font-size: 14px; width: 45%;}
    .hcategory_sec{ display: block; background: #ebebeb; }/* Mobile Header menu */ 
    .sticky-menu-nav{ display: none;}
    .prodetailinner-main-div { padding: 30px 0 0; }

    .promo-earned-col-image, .myvoacher_alldetails .promo-earned-col-image{ height: 300px; width: 100%; }
    .promo-earned-info{ width: 100%;  height: calc(100% - 300px); flex: inherit; padding: 30px 10px; }
    .promo-only-info{ padding: 30px 10px 60px 10px; }
    .promo-valid{ -webkit-flex: 1 1 auto; flex: 1 1 auto; }
    .promation_btn{ position: absolute; bottom: 20px; left: 0; right: 0; margin: 0 auto;
         justify-content: space-evenly;
    }
}

@media (max-width: 1080px){
    .hsign_sec, .hordertype_sec { display: none; }
    .logo-main-section { align-items: center; }
    .htico_wishlist, .htico_cart {  margin-left: 5px; }
    .cdd-details-lhs, .cdd-details-rhs{ width: 100%; }
    .cdd-details-lhs{ margin-bottom: 20px; }
    .redeem-col { width: 100%; margin: 0 0 10px; padding: 20px 10px; }
    .mobile-order-nowbtn{ display: block; box-shadow: 0 0 13px 0 rgb(0 0 0 / 11%); text-align: center; }

    .myacc_right_h-lhs {
        width: 70%;
    }
}

@media (max-width: 1024px){
    .copyright-section { border-top: 1px solid #e4e4e4; padding: 10px 10px; }
    .ft-ct-bottom{ padding-top: 0; }
    .ft-bt-col1 {  width: 98%; margin: 0 1% 20px; text-align: center; padding: 15px; border: 1px solid #e4e4e4; }
    .ft-bt-col2, .ft-bt-col3, .ft-bt-col4, .ft-bt-col5 { width: 23%; padding: 15px; border: 1px solid #e4e4e4;
        margin: 0 1%;
    }
    .ft-list li:last-child { margin: 0; }
    .products-list-ulmain{ justify-content: space-between; }
    .products-list-ulmain>li { width: 49%; margin: 0 0 20px; }
    .lft-sec-prt{  background-position: right;  width: 210px; }
    .lft-sec-prt-main { width: calc(100% - 210px);}
    .mid-sec-prt{ width: 70%; }
    .rgt-sec-prt {  width: 25%; }
}

@media(max-width: 991px){
    .accmenu_sidebar, .accmenu_sidebar{ display: none; }
    .myaccsec_right, .myordersec_right, .rewardsec_right, .promotionsec_right, .vouchersec_right, .refersec_right,
    .myaccsec_right { width: 100%; }
    .mbacc_mslidersec { display: block; margin: 0 0 25px; position: relative; }
    .acc-detail-inner, .myorder_mainsec, .reward_mainsec_div, .promotion_mainsec_div, .refer_mainsec_div,
    .voucher_mainsec_div {  flex-flow: column; }
    .other-add-row li { width: 100%; margin-right: 0; }
    .myorder_sec_inner_right, .reward_sec_inner_right, .promotion_boxfull, .voucher_boxfull, .refer_boxfull{
        padding: 15px;
    }
    .load_more_div{ padding: 20px 0; }
    .hcart_scrollarea { height: calc(100% - 175px); }

    /* .cartaction_bottom{ padding-bottom: 58px; } */
    /* .cartaction_bottom .btn{ margin: 0px 0px 64px 0px;} */
    /* .cartaction_bottom .btn{ margin: 0; } */

    .cartaction_bottom{ padding-bottom: 90px;}
    .cartaction_bottom .btn{ margin: 0;}
    /* CSS specific to iOS devices only */ 
	@supports (-webkit-overflow-scrolling: touch) {
		.hcart_scrollarea { max-height: 100%; padding-bottom: 200px;}
		.cartaction_bottom{ padding: 10px 0px 120px 0px; background: #fff;}
	} 
}

@media(max-width: 880px){
    .desktop_menusec { display: none; }
    .trigger_menu { display: block; width: 30px; height: 19px; margin: 3px 0; padding: 0; cursor: pointer; }
    .logo { max-width: 170px; height: 45px; position: absolute; left: 0; right: 0; margin: auto; }
    .logo-main-section { height: 52px; }
    .product-details-lhs { width: 100%; padding-right: 0; }
    .product-detail-page .product-details-rhs { width: 100%; padding: 30px 0 0; }
}

@media(max-width: 780px){
    .home-banner .slick-slide img { position: absolute; left: 0; top: 0; width: 100%; object-fit: cover;
        height: 100%;
    }
    .banner-info { position: relative; -webkit-transform: none; transform: none; right: inherit; left: 0; top: 0;}
    .home-banner{ height: 250px; }
    .home-banner .slick-slide img{ height: 100%; }
    .banner-info h2 { font-size: 42px; margin: 0 0 10px; }
    .banner-info p{  line-height: 1;  font-size: 14px; }
    .home-banner .slick-slide { padding: 50px 20px 50px 20px; }
    .banner-info a{ padding: 12px 35px 10px 18px; font-size: 13px; }
    .banner-info a:after{ right: 8px; width: 20px; height: 10px; }
    .about-row{ flex-direction: column-reverse; }
    .about-left-container, .about-right-container{ width: 100%; }
    .about-left-container{ margin: 40px 0 0; }
    .our-promotions h1{ margin: 0 0 10px; }
    .home-testimonial h1{ margin: 0 0 15px; }
    .ft-ct-bottom { padding-top: 0; padding-bottom: 0px; }
    .ft-bt-col2, .ft-bt-col3, .ft-bt-col4, .ft-bt-col5 { width: 48%; padding: 15px; border: 1px solid #e4e4e4;
        margin: 0 1% 20px;
    }
    .testi-author { padding: 10px 0 0; justify-content: center; }
    .testi-author-name { width: auto;  margin-left: 20px; }
    .hsearch_sec{ left: 15px; right: 15px; width: auto; top: 50px; }
    .hcart_dropdown { width: 100%; }
    .welcome-title {  width: 100%; margin: 0 0 10px; }
    .myorder-crnt_div{ width: 100%; }
    .rewards-title{ width: 100%; }
    .refer-friend-reward-section { margin: 25px 0px 0;}
    .refer-friend-reward-section h2{ font-size: 24px; }
    .refer-friend-reward-section h4{ font-size: 20px; }
    .promo-form, .congrats-inner{ margin: 0 0 20px; }
    .contact_bottom{ flex-flow: column-reverse; }
    .contact_bottom_lhs, .contact_bottom_rhs{ width: 100%; }
    .contact_btm_rhsinner{ border-radius: 15px 15px 0 0; }
    .contact_btm_lhsinner { border-radius: 0 0 15px 15px; border-right: 1px solid rgba(96, 96, 96, 0.1);
        border-top-width: 0;
    }
    .ourrest_row{ flex-flow: column-reverse; }
    .ourrest_info, .ourrest_map{ width: 100%; }
    /* .lft-sec-prt-main{ width: 100%; flex-flow: column; } */
    .rgt-sec-prt { width: 20%;}
    .lft-sec-prt-main{justify-content: space-between;}
    .mid-sec-prt h2{font-size: 38px;}
    .mid-sec-prt h4{ font-size: 18px; margin: 0px 0 5px;}
    .mid-sec-prt a{font-size: 14px;}
    .join-us-section { padding: 30px 20px; min-height: 130px; }
    .lft-sec-prt { background: url(../images/join-lhs-mobile.png) no-repeat; background-size: cover;
        background-position: -22px -83px;
    }
    .product-top-main { padding-bottom: 0; }
    .contact_section{ margin: 50px auto; }
    .location-indiv { margin: 40px 0px 0px; }

    .myacc_right_header, .membership_rewards{ flex-flow: column; }
    .myacc_right_h-lhs { width: 100%; }
    .myacc_rt_header-rhs{ width: 100%; }
    .maccont_memberimagediv { margin: 0 auto 20px; }
}

@media(max-width: 680px){
    .ft-bt-col1 { width: 100%; margin: 0 0 20px; }
    .ft-tp-col1, .ft-tp-col2, .ft-tp-col3 { width: 100%;  margin: 10px 0; }
    .ft-bt-col2, .ft-bt-col3, .ft-bt-col4, .ft-bt-col5 { width: 100%; margin: 0 0 20px; text-align: center; }
    .social-list{ justify-content: center; }
    .social-list li{ margin: 0 5px; }
    .copyright-section p span{ display: block; }
    .order_delivery_item li{ width: 48%; margin: 0 0 10px; }
    .ordernow-single-img{ height: 65px; }
    .ordernow-single-img img { max-height: 65px; }
    .order_delivery_item h3 { font-size: 13px; }
    .chk-payment-col-radio li { margin: 0 auto 10px; width: 100%; max-width: 62%; }
    .myacc_right_header, .membership_rewards{ justify-content: center; background-position: initial; }
    .acc-inform .col-md-6 { width: 100%; margin: 0; }
    .acc-inform .mbl_namefied, .acc-inform .mbl_mailfied, .acc-inform .mbl_datefid, .acc-inform .mbl_pcod,
    .acc-inform .mbl-uno{ margin: 0 0 8px; }
    .acc-inform .form-group { margin-bottom: 8px; }

    .join-us-section {
        min-height: 160px;
    }
    
    .lft-sec-prt-main {
        -webkit-justify-content: space-between;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 420px;
    }
}

@media(max-width: 580px){
    .home-category-inner, .procuct-category-inner{ padding: 30px 20px; }
    .home-category .owl-dots { display: flex!important; margin: 10px 0 0; padding: 0; background: #cfcecb;
        height: 6px; width: 100%; border-radius: 10px; list-style: none; position: relative; justify-content: stretch;
    }
    .home-category .owl-dot.active { width: 50%; }
    .home-category .owl-dot span { padding: 0; margin: 0; font-size: 0; background: #cfcecb; height: 6px;
        border-radius: 10px; width: 100%; display: block;
    }
    .home-category .owl-dots .owl-dot.active span { background: #d39411; border: 0; width: 100%; height: 100%; }
    .home-category .owl-dots .owl-dot{ margin: 0; width: 100%; }
    .home-category .owl-dots .owl-dot span { width: auto; height: auto; border-radius: 10px; border: 0; }
    .home-category-inner .menu-title-link, .procuct-category-inner .menu-title-link{ font-size: 14px; }
    .our-promotions .slick-dots, .feature-inner .slick-dots{ padding: 10px 0 0; }
    .full-login-new-body, .delevery_popup_datetime .full-login-new-body{ padding: 30px 10px; }
    .delevery_popup .con_first, .delevery_popup_datetime .syd_date{ width: 100%; }
    .delevery_popup .go_second{ width: 100%; margin-bottom: 10px; }
    .delevery_popup_datetime .syd_time, .delevery_popup_datetime .button { width: 100%; }
    .two-button-row, .delevery_popup_datetime .btn_sec { flex-wrap: wrap; flex-direction: column-reverse; }
    .delevery_popup_datetime .button:first-child{ margin: 10px 0 0; }
    .inn_product_hea_left h3 { font-size: 22px; }
    .inn_product_hea>h3{ margin-bottom: 10px; }
    .cart_body { padding: 10px; }
    .hcart_dropdown .cart_footer { margin: 0 10px; }
    .common-inner-banner h3{ font-size: 24px; }
    .common-inner-banner span{ font-size: 14px; line-height: 1.2; }
    .innersection_wrap { padding: 20px 0; }
    .welcome-title h1>img { max-height: 30px; }
    .welcome-title h1{ font-size: 22px; }
    .accdetails-info { padding: 20px 10px 40px; }
    .promo-form h3 span, .congrats-inner h3 { font-size: 20px; }
    .promo-form h3, .congrats-inner h2{ font-size: 26px; }
    .promotion-top-form input[type=text] { height: 40px; padding: 5px 15px; }
    .promotion-top-form .applyBtn { position: inherit; margin: 10px 0 0; height: 38px; min-width: 115px; padding: 10px 20px;      font-size: 15px;
    }
    .contact_top h2 { margin-bottom: 30px; font-size: 26px; }
    .no-product .no-recrds-found h2{ font-size: 22px; margin: 0 0 10px; }
    .no-product .no-recrds-found p { font-size: 15px; text-align: center; }
    .mid-sec-prt h2{ font-size: 38px; }
    .mid-sec-prt h4{ font-size: 18px; }
    .left-input-div, .right-input-div{ width: 100%; }
    .left-input-div input{ margin: 0 0 15px; }
    .hsearch_sec_prolist.open, .hsearch_sec_prolist.open .search_prodresult{ width: 300px; }
    .membenifits_popup .full-login-new-header h2{ font-size: 26px;}
    .view_member h3{font-size: 24px;}
    .membenifits-body { padding: 35px 25px; }
    .promo-earned-col-image, .myvoacher_alldetails .promo-earned-col-image{ height: 185px; }
    .promo-earned-info{ height: calc(100% - 185px); padding: 15px 10px; }
    .promo-only-info{ padding: 15px 10px 60px; }
    .promation_btn { bottom: 15px; }
    .myacc_dropmenu h3{ font-size: 24px; margin: 0 0 10px; }
    .myacc_dropmenu li{ font-size: 15px; }
}

@media(max-width: 480px){
    .container, .container-full { padding-left: 10px; padding-right: 10px; }
    .pd-top-50 { padding-top: 30px; }
    .ordernow-single-img img { max-height: 50px; }
    .full-login-new-header h2, .delevery_popup_datetime .full-login-new-body>h2, .title1{ font-size: 28px; }
    .full-login-new-header p { font-size: 14px; }
    img.pop-scooter-img{ max-width: 75px; }
    .products-image-div { height: 235px; }
    .product-info-div { height: calc(100% - 235px); }
    .prd_chosen_sub_row { padding: 0 0 20px; flex-wrap: wrap; margin: 15px 0 0; }
    .prd_chosen_sub_col { width: 100%; }
    .total_price.product_details_price{ width: 100%; margin: 20px 0 10px; text-align: center; font-size: 28px; }
    .delivery-cart-div .col-sm-cls { width: 100%; text-align: center; }
    .delivery-cart-div .text-right { margin-top: 15px; }
    .hcart_add_innr_loca { justify-content: center; }
    .hcart_scrollarea .syd_merge { flex-flow: column; }
    .hcart_scrollarea .syd_date, .hcart_scrollarea .syd_time { width: 100%; text-align: left; }
    .hcart_scrollarea .react-datepicker-wrapper, .hcart_scrollarea .syd_time .custom_select{ width: 100%; }
    .hcart_dropdown .cart_img,.ckout-container-inner .cart_img,.tnkyou-leftimg { width: 50px; margin: 0 5px 5px 0;}
    .cartsidebar_row .cart_info, .ckout-container-inner .cart_info, .tnkyou-leftcontnt { max-width: calc(100% - 70px);}
    .cartsidebar_row .cart_lhs, .tnkyou-productdivin-left{ display: flex; width: 100%; }
    .checkout-delivery-lhs, .checkout-delivery-rhs { width: 100%; text-align: center; }
    .checkout-delivery-rhs{ margin-top: 10px; }
    .checkout-control-group-middle .delivery_submit_cls, .checkout-control-group-middle .syd_date{ width: 100%; }
    .controls-three-top>div { width: 47.5%; margin: 0 0 10px; }
    .controls-twoin .form-group{ width: 100%; margin: 0 0 10px; }
    .controls-single textarea{ height: 120px; }
    .ckout-container-inner .cart_left{ justify-content: flex-start; width: 100%; display: flex; }
    .redeem-item-hea-inner { width: 100%; }
    .redeem-item-hea h4 { font-size: 20px; }
    .redeem-item-hea .points { font-size: 15px; position: relative; width: 100%; max-width: inherit; top: inherit; margin: 7px 0;}
    .redeem_apply .focus-out { padding-right: 0; }
    .redeem_apply .btn_minwid { left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%);
        min-width: 110px; font-size: 13px; padding: 12px 18px; position: inherit;
    }
    .redeem_apply input{ padding: 10px 10px; height: 46px; font-size: 15px; }
    .cout_body{ padding: 10px; }
    .changepw_popup .pouup_in { padding: 50px 30px 50px; }
    .myorder_delivery_total:before, .order-dely-top-div:before{ display: none; }
    .myorder_delivery_left, .order-dely-top-left, .thank-detailin-top-left{ margin: 0 0 10px; }
    .myorder_delivery_left, .myorder_delivery_right, .order-dely-top-left, .order-dely-top-right,
    .thank-detailin-top-left, .thank-detailin-top-right { width: 100%; text-align: center; }
    .myorder_delivery_total, .order-dely-top-div, .thank-detailsinner-top{ flex-flow: column;
        justify-content: center; align-items: center;
    }
    .myorder_delivery_right img, .order-dely-top-right img { margin: 0 0 5px; }
    .myorder_delivery_date_left h2, .myorder_delivery_date_right h2, .order-dely-middle-left h2, 
    .order-dely-middle-right h2, .thank-details-middle-left h2{ font-size: 13px; }
    .myorder_delivery_date_left h4, .myorder_delivery_date_right h4, .order-dely-middle-left h4,
    .order-dely-middle-right h4, .thank-details-middle-right h2{ font-size: 13px; }
    .myorder_amt-left h3, .myorder_amt_right h3{ font-size: 16px; }
    .myorder_sec_inner_right, .reward_sec_inner_right, .promotion_boxfull, .voucher_boxfull, .refer_boxfull{ padding: 15px 10px; }
    .order_no_deatails span { font-size: 15px; }
    .myacc_filter .nav > li > a { padding: 8px 6px; font-size: 15px; }
    .reward_amt_tdiv h3, .reward_expiry_date h4, .reward_checkoutDate, .reward_checkoutTime{ font-size: 15px; }
    .rewards-title h4{ font-size: 16px; }
    .rewards-title h3{ font-size: 24px; }
    .promo-earned-col{ width: 100%; }
    .receipt_header_box h3, .reward_header_box h3{ font-size: 22px; margin: 0 0 5px; }
    .tnkyou-header h2{ font-size: 22px; }
    .tnkyou-header p { font-size: 15px; }
    .thank-order-top h3{ font-size: 24px; }
    .tnk-order h3 { font-size: 18px; }
    .tnk-order p{ font-size: 14px; }
    .receipt_header_box p, .reward_header_box p{ font-size: 14px; }
    .order-dely-footer .cart_row p, .tnk_cart_footer .cart_row p,
    .order-dely-footer .cart_row span, .tnk_cart_footer .cart_row span{ font-size: 14px; }
    .order-dely-footer .grant-total-div p, .order-dely-footer .grant-total-div span{ font-size: 16px; }
    .order-dely-bottom-product h4{ font-size: 13px; }
    .contact_btm_rhsinner{ padding: 30px 15px 5px; }
    .contact_section_inner { padding: 80px 0; }
    .contact_top h2{ font-size: 22px; }
    .contact_btm_lhsinner{ padding: 30px 15px; }
    .myvouchar-forgift-div h2{ font-size: 20px; }
    .lft-sec-prt { background-position: -52px -98px; }
    .join-us-section { padding: 20px 15px; }
    .rgt-sec-prt p{ font-size: 14px; }
    .mobile_account_lhs{ padding: 8px; }
    .mobile_account_lhs a{ padding: 5px 16px 0px 0; font-size: 14px; line-height: 10px; }
    .mobile_account_rhs a{ font-size: 14px; padding: 12px 12px 8px; }
    .mobile_account_lhs a:last-child:after{ top: 2px; left: -10px; height: 12px; }
    .hcategory_trigger{ font-size: 12px; padding-right: 20px; line-height: 1.3; }
    .hcategory_trigger:after{ width: 12px; height: 11px; }
    .hcategory_trigger.active:after{ width: 11px; height: 11px; right: 0; }
    .hcategory_selected_text{ font-size: 12px; }
    .hcategory_selected_text { padding: 15px 108px 12px 15px; }
    .hcategory_menulist li a{ font-size: 14px; }
    .about-left-one h1, .our-promotions h1, .home-testimonial h1{ font-size: 42px; }
    .copyright-section p{ font-size: 13px; }
    .remark_notesec h4{ font-size: 18px; margin: 0 0 5px; }
    .remark_notesec p{ font-size: 14px; margin: 0 0 5px; }
    .tnkyou-leftcontnt h4{ font-size: 14px; }
    .birthday-info-move { margin: 20px 0; padding: 20px 10px 20px; }
    .cart_info h4 { font-size: 13px; }
    .header-top-cls { padding: 5px 0; }
    .mobile-menu .menu a { font-size: 15px; }
    .page-banner h2 { font-size: 28px; }
    .membenifits-body { padding: 20px 15px; }
    .myacc_dropmenu li { font-size: 14px; }
    .common-inner-banner{ padding: 20px;}
    .innersection_wrap{ padding: 0;}
    .myacc_catg-main{ height: 42px; }
    .myacc_cat_name { padding: 10px 50px 10px 12px; font-size: 14px; }
    .myacc_category_menu{ top: 42px; }
    .myacc_category_menulist li a { font-size: 14px; padding: 10px 45px 10px 15px; }
    .myacc_cat_nspan{ top: 6px; }
    .myacc_right_header, .membership_rewards{ padding: 15px 15px;}
    .myacc-points h3 { font-size: 18px; padding: 4px 20px 2px; line-height: 1; }
    .myacc-points { padding: 0 5px; }
    .myacc-points .points-expre, .myacc-points p{ font-size: 11px;}
    .member-progress-2 { margin: 8px auto 8px;}
    .refer_popup { padding: 30px 20px; }

    .lft-sec-prt-main{ max-width: 320px; }
    .mid-sec-prt h2 { font-size: 28px; }
    .mid-sec-prt h4 { font-size: 14px; }
    .mid-sec-prt a { font-size: 12px; padding: 8px 18px; }
    .join-us-section { min-height: 130px; }
}

@media(max-width: 420px){
    .home-banner .slick-slide { padding: 35px 15px 35px 15px; }
    .banner-info h2 { font-size: 34px; margin: 0 0 5px; }
    .banner-info p { line-height: 1;  font-size: 13px; margin: 0 0 10px; }
    .banner-info{ top: 50%; transform: translateY(-50%); }
    .home-feature-heading a { font-size: 14px; padding-right: 30px; }
    .home-feature-heading a:before { top: 3px; width: 20px; height: 10px; }
    .home-feature-heading h4{ font-size: 20px; margin: 0; }
    .mobile-menu .mobile-menu-body { padding: 50px 40px 40px 20px; }
    .mobile_account_lhs a{ font-size: 13px; padding: 3px 16px 0px 0; line-height: 12px;}
    .mobile_account_rhs a{ font-size: 13px; padding: 10px 12px 8px; }
    .htico_cart>a img { width: 24px; height: 26px; }
    .htico_wishlist img { width: 27px; height: 23px; }
    .htico_search> a > i{ width: 21px; height: 26px; }
    .mobile-menu .mobile-menu-header{ left: 20px;}
    .mobie-icon{ left: -28px; }
}

@media(max-width: 380px){
    .products-list-ulmain>li { width: 100%; }
    .myorder_btns .button { font-size: 14px; padding: 13px 6px 9px; border-radius: 6px; }
    .vouchergift-popup h2{ font-size: 22px; }
    .myvouchar-forgift-div h2{ font-size: 18px; margin: 0 0 15px; }
    .myacc_filter .nav > li > a, .head_left h4, .head_right h4 { font-size: 12px; }
    .head_right:before{ width: 45%; }
    .checkout-billing-address .chk_heading span{ text-align: right; }
    .checkout-total .chk_heading .custom_checkbox{ right: 30px; }
    .home-category-inner .menu-title-link, .procuct-category-inner .menu-title-link{ font-size: 12px; }
    .feature-inner .slick-track .slick-slide > div, .our-promotions .slick-track .slick-slide > div{ padding: 15px 0px; }
    .home-feature-heading{ padding: 0; }
    .mobile_account_lhs a, .mobile_account_rhs a { font-size: 12px; }
    .mobile_account_lhs { padding: 5px;}
    .logo { max-width: 130px; height: 34px;}

    .lft-sec-prt-main { max-width: 280px; }
}